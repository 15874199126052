import moment from 'moment';

export const INTERVAL_TIME = 1000 * 1;

export const systemCheckS3Url = () => {
  const currentDate = moment(new Date()).format('yyyyMMDD');
  const { origin } = window.location;

  if (origin.includes('local')) {
    return `http://local.fanc.io:8080/storage/public/maintenance/${currentDate}.json`;
  }
  if (origin.includes('dev')) {
    return `https://fanc-wallet-dev.s3.ap-northeast-2.amazonaws.com/maintenance/${currentDate}.json`;
  }

  return `https://fanc-wallet.s3.ap-northeast-2.amazonaws.com/maintenance/${currentDate}.json`;
};
