import { createSlice } from '@reduxjs/toolkit';

// 초기값
const initialState = {
  activeCategory: 'date-category',
  activeDateCategory: '6',
  activeUsageCategory: 'swap',
};

export const swapHistorySlice = createSlice({
  name: 'swapHistory',
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
    setDateCategory: (state, action) => {
      state.activeDateCategory = action.payload;
    },
    setUsageCategory: (state, action) => {
      state.activeUsageCategory = action.payload;
    },

    resetSwapHistorySlice: (state) => initialState,
  },
});

export const { setCategory, setDateCategory, setUsageCategory, resetSwapHistorySlice } =
  swapHistorySlice.actions;
export default swapHistorySlice;
