import moment from 'moment';
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import {
  getAccessToken,
  setAccessToken,
  setExpiredAt,
  getExpiredAt,
  getRefreshToken,
  removeTokenInfos,
} from './HandleToken';
import httpLink from './httpLink';
import { REFRESH_TOKEN } from '@/graphql/Users';
import { addSeconds } from '@/utils/time';
import store from '@/redux/store';
import { resetUser } from '@/redux/reducer/userSlice';
import { resetSwapSlice } from '@/redux/reducer/swapSlice';
import { resetTrancSlice } from '@/redux/reducer/trancSlice';
import { resetWalletSlice } from '@/redux/reducer/walletSlice';

const renewTokenApiClient = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache(),
  credentials: 'include',
});

let isRefreshing = false; // 리프레시 중인지 여부를 추적하는 상태 변수
let requestQueue = []; // 대기열에 저장된 요청들

export const getIsRefreshing = () => isRefreshing;

const processRequestQueue = (newAccessToken) => {
  requestQueue.forEach(({ resolve, headers }) => {
    resolve({ headers: { ...headers, authorization: `Bearer ${newAccessToken}` } });
  });
  requestQueue = [];
};

function initUserInfo() {
  removeTokenInfos();

  store.dispatch(resetUser());
  store.dispatch(resetSwapSlice());
  store.dispatch(resetTrancSlice());
  store.dispatch(resetWalletSlice());

  requestQueue = [];
  isRefreshing = false;
  window.location.reload();
}

const refresh = async (_refreshToken) => {
  const token = getAccessToken();

  const {
    data: {
      refreshToken: { access_token: accessToken, expires_in: expiresIn },
    },
  } = await renewTokenApiClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: {
      refresh_token: _refreshToken,
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  return { accessToken, expiresIn };
};

const authLink = setContext(async (_, { headers }) => {
  const accessToken = getAccessToken();
  const expiredDate = getExpiredAt();

  /*
  만료시간 ~ 재로그인시간 사이에는 리프레시를 통한 토큰연장 방법을 사용
  재로그인시간 ~ 리프레시만료시간 사이에는 재로그인을 통한 토큰갱신 방법을 사용한다
  */
  const isExpired = moment().diff(expiredDate) > 0;

  // 토큰없는경우
  if (!accessToken) {
    return {
      headers: {
        ...headers,
        authorization: '',
      },
    };
  }

  // 토큰만료시기 이전
  if (!isExpired) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  }

  // 토큰만료됬고 아직 리프레싱 중이 아닐때
  if (isExpired && !isRefreshing) {
    isRefreshing = true;

    try {
      const refreshToken = getRefreshToken();
      const response = await refresh(refreshToken);
      const { accessToken: newAccessToken, expiresIn } = response;

      setAccessToken(newAccessToken);
      setExpiredAt(addSeconds(expiresIn));

      isRefreshing = false; // 리프레시 완료 후, 리프레시 중 상태 변수 초기화
      processRequestQueue(newAccessToken); // 대기열에 있는 요청들을 처리

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${newAccessToken}`,
        },
      };
    } catch (error) {
      initUserInfo();
    }
  } else if (isRefreshing) {
    // 리프레시 해야하지만 이미 리프레시중인상태에서의 요청은 대기열로 이동(동시적인 요청에대한 처리는 대기열로 이동)
    return new Promise((resolve) => {
      requestQueue.push({ resolve, headers });
    });
  }
});

export default authLink;
