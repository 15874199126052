import styled from 'styled-components';
import { useState, useMemo, useLayoutEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import PasswordInput from '@views/shared/forms/PasswordInput';
import { modals } from '@views/shared/modal/Modals';
import { useDispatch } from 'react-redux';
import Spinner from '@/views/shared/spinner/Spinner';
import { CHECK_EMAIL_FOR_CREATE_USER, CREATE_USER } from '@/graphql/Users';
import { DEFAULT_PAGE, pagePaths } from '@/navigation/Pages';
import useModals from '@/hooks/useModal';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, G_COLOR, LIGHT_COLOR, RED_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';
import { Icons, Images } from '@/assets/images';
import { getLocale } from '@/utils/locale';
import CustomInput from '@/views/shared/input/CustomInput';
import { getAccessToken } from '@/services/HandleToken';
import { SITE_GUIDE_LATEST_BY_CATEGORY } from '@/graphql/SiteGuide';

export default function Signup() {
  const { openModal } = useModals();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const localLocale = getLocale();

  const dispatch = useDispatch();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' });
  const [userId, setUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isEmail, setIsEmail] = useState(true);
  const [isInputEmail, setIsInputEmail] = useState(false);
  const [consents, setConsents] = useState({ term: false, privacy: false });

  useQuery(SITE_GUIDE_LATEST_BY_CATEGORY, {
    variables: {
      category: 'TERMS',
      locale: localStorage.getItem('locale'),
    },
  });
  useQuery(SITE_GUIDE_LATEST_BY_CATEGORY, {
    variables: {
      category: 'AGREEMENT',
      locale: localStorage.getItem('locale'),
    },
  });

  const isAllChecked = useMemo(() => {
    if (consents.term === true && consents.privacy === true) return true;
    return false;
  }, [consents]);

  const watchedPwd = watch(['password', 'passwordConfirm']);
  const isEqualPwd = useMemo(() => {
    if (watchedPwd[1]?.length === 0) {
      return true;
    }
    if (watchedPwd[0] === watchedPwd[1]) {
      return true;
    }
    return false;
  }, [watchedPwd]);

  const [checkEmailForCreateUser, { data }] = useMutation(CHECK_EMAIL_FOR_CREATE_USER);

  const [createUser, { loading }] = useMutation(CREATE_USER, {
    onCompleted: (data) => {
      navigate(pagePaths.EMAIL_CERTIFICATION, {
        state: {
          userId,
          link: pagePaths.LOGIN,
        },
      });
      // dispatch(userSlice.actions.setPrivateKey(data.EthereumPrivateKey));
      // navigate(pagePaths.WALLET_BACKUP, {
      //   state: {
      //     userId,
      //     backupCode: data.createUser.data.privateKeyDecryptionSyntax,
      //   },
      // });
    },
    onError: (error) => {
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      openModal(modals.dialog, {
        title: t('auth.signup.alertPopupLoginFailed'),
        message: errorMsg.join(', '),
      });
    },
  });

  const onSubmit = ({ email, password, passwordConfirm }) => {
    setUserId(email);

    createUser({
      variables: {
        email,
        password,
        password_confirmation: passwordConfirm,
        locale: localLocale,
      },
    });
    // .then((data) => {
    //   if (data.data.createUser.data.EthereumPrivateKey) {
    //     window.setDataSave(data.data.createUser.data.EthereumPrivateKey);
    //     dispatch(userSlice.actions.setPrivateKey(data.data.createUser.data.EthereumPrivateKey));
    //   }
    // });
  };

  const checkEmail = () => {
    setIsInputEmail(true);
  };

  const checkDuplicateEmail = () => {
    setIsInputEmail(false);
    if (userEmail && /\S+@\S+\.\S+/.test(userEmail)) {
      setIsEmail(true);
      checkEmailForCreateUser({
        variables: {
          email: userEmail,
          locale: localStorage.getItem('locale'),
        },
      });
    } else {
      setIsEmail(false);
    }
  };

  useLayoutEffect(() => {
    if (getAccessToken()) {
      navigate(DEFAULT_PAGE, { replace: false });
    }
  }, [navigate]);

  if (loading) return <Spinner isLoading={loading} />;

  return (
    <Container>
      <Header>
        <Link to={pagePaths.WELCOME}>
          <img src={Images.tmoWalletLogo} alt="" />
        </Link>
        <span className="title">{t('auth.signup.title')}</span>
      </Header>

      <FormWrapper onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormBox>
          <label htmlFor="userEmail">{t('auth.signup.email')}</label>
          <CustomInput
            id="userEmail"
            type="email"
            autoComplete="off"
            placeholder={t('auth.login.email.ph')}
            register={register}
            name="email"
            options={{
              onChange: (e) => {
                setUserEmail(e.target.value);
                checkEmail();
              },
              onBlur: checkDuplicateEmail,
              required: t('auth.signup.alertEssential'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('auth.signup.alertEmailType'),
              },
            }}
          />
          {!isInputEmail && (
            <>
              {isEmail && (
                <small role={data?.checkEmailForCreateUser.status === 'fail' ? 'alert' : ''}>
                  {data?.checkEmailForCreateUser.message}
                </small>
              )}
              {!isEmail && !errors.email && (
                <small role="alert">{t('auth.signup.alertEmailType')}</small>
              )}
            </>
          )}
          {errors.email && <small role="alert">{errors.email.message}</small>}
        </FormBox>

        <FormBox>
          <PasswordInput
            id="userPw"
            name="password"
            placeholder={t('auth.login.password.ph')}
            label={t('auth.signup.password')}
            register={register}
            maxlength="16"
            useToggle
            options={{
              required: t('auth.signup.alertEssential'),
              pattern: {
                value: /^(?=.*[a-zA-Z])((?=.*\d)(?=.*\W)).{8,16}$/,
                message: t('auth.signup.alertInvalidPassword'),
              },
              minLength: {
                value: 8,
                message: t('auth.signup.alertMinPassword'),
              },
            }}
          />
          {errors.password && <small role="alert">{errors.password.message}</small>}
        </FormBox>

        <FormBox>
          <PasswordInput
            id="userPwCf"
            name="passwordConfirm"
            placeholder={t('auth.signup.repeatPassword')}
            label={t('auth.signup.passwordConfirm')}
            register={register}
            maxlength="16"
            useToggle
            options={{
              required: t('auth.signup.alertEssential'),
              validate: (val) => {
                if (watch('password') !== val) {
                  return t('auth.signup.alertMatchPassword');
                }
              },
            }}
          />
          {errors.passwordConfirm && <small role="alert">{errors.passwordConfirm.message}</small>}
          {/* {!isEqualPwd && <small role="alert">{t('auth.signup.alertMatchPassword')}</small>} */}
        </FormBox>

        <TermWrapper>
          <div className="all-check">
            <img
              src={isAllChecked ? Icons.circleCheckBoxOn : Icons.circleCheckBoxOff}
              alt=""
              onClick={() => setConsents({ term: !isAllChecked, privacy: !isAllChecked })}
            />
            <span>{t('common.all.agree')}</span>
          </div>
          <TermBox>
            <img
              src={consents.term ? Icons.checkBoxOn : Icons.checkBoxOff}
              alt=""
              onClick={() => setConsents((prev) => ({ ...prev, term: !prev.term }))}
            />
            <span className="text">{t('auth.signup.terms')}</span>
            <img
              src={Icons.rightDropdown}
              alt=""
              onClick={() =>
                openModal(modals.term, {
                  callback: () => setConsents((prev) => ({ ...prev, term: true })),
                })
              }
            />
          </TermBox>
          <span className="hr" />
          <TermBox>
            <img
              src={consents.privacy ? Icons.checkBoxOn : Icons.checkBoxOff}
              alt=""
              onClick={() => setConsents((prev) => ({ ...prev, privacy: !prev.privacy }))}
            />
            <span className="text">{t('auth.signup.privacy')}</span>
            <img
              src={Icons.rightDropdown}
              alt=""
              onClick={() =>
                openModal(modals.privacy, {
                  callback: () => setConsents((prev) => ({ ...prev, privacy: true })),
                })
              }
            />
          </TermBox>
        </TermWrapper>

        <Button disabled={!(isValid && isAllChecked)} type="submit">
          {t('auth.signup.title')}
        </Button>
      </FormWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 52px 39px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 129px;
    height: auto;

    margin-bottom: 23px;
  }

  .title {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 0.88;
  }

  .subtitle {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;

    margin-top: 19px;
  }
`;

const FormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 72px;
`;

const FormBox = styled('div')`
  label {
    margin-left: 19px;
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }

  input {
    border-radius: 30px;
    border: 1px solid ${LIGHT_COLOR};
    background: transparent;
    box-shadow: none;
    height: 55px;

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;

    &:focus {
      border: 1px solid ${B_COLOR};
    }
  }

  small {
    position: relative;
    left: 8px;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px; /* 153.846% */

    &[role='alert'] {
      color: ${RED_COLOR};
    }
  }
`;

const TermWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  overflow: hidden;
  margin-bottom: 66px;

  & .all-check {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
  }

  & > *:nth-child(2) {
    border-top: 1px solid ${LIGHT_COLOR};
  }

  & > *:last-child {
    border-bottom: 1px solid ${LIGHT_COLOR};
  }

  & .hr {
    margin: 0;
    margin-left: 35px;
    width: 100%;
    height: 1px;
    background-color: ${LIGHT_COLOR};
  }
`;

const TermBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .text {
    flex: 1;
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
  }
`;

const Button = styled('button')`
  border-radius: 15px;
  margin: 0 13px;
  height: 60px;

  background-color: ${B_COLOR};
  color: ${W_COLOR};

  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 17px;
  font-weight: 400;

  &:disabled {
    background: ${G_COLOR};
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 17px;
    font-weight: 400;
  }
`;
