import styled from 'styled-components';
import { useEffect, useState } from 'react';

import SearchInput from '@views/shared/forms/SearchInput';
import { TabContents } from '@views/shared/tabs';
import { useDispatch } from 'react-redux';
import PointStatus from '@/views/pages/swap/components/point/components/PointStatus';
import { useTranslate } from '@/hooks/useTranslate';
import TabButtons from '@/views/shared/tabs/TabButtons';
import { setFilterKeyword } from '@/redux/reducer/swapSlice';

export default function PointFilter() {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleSearch = (value) => {
    dispatch(setFilterKeyword(value.keyword));
  };

  const handleClickTabButton = (key) => {
    if (activeTabKey !== key) {
      setActiveTabKey(key);
    }
  };

  /** Tmop 가 주체
   *  PointStatus import - 가져오기 목록 컴포넌트
   *  PointStatus export - 내보내기 목록 컴포넌트
   * */
  const tabs = [
    {
      key: '1',
      title: t('swap.tab.import'),
      component: <PointStatus type="import" />,
    },
    {
      key: '2',
      title: t('swap.tab.export'),
      component: <PointStatus type="export" />,
    },
  ];

  // 필터키워드 초기화
  useEffect(() => {
    dispatch(setFilterKeyword(''));
  }, [activeTabKey, dispatch]);

  return (
    <Container>
      <TabButtons
        tabs={tabs}
        active={activeTabKey}
        onClickTabButton={handleClickTabButton}
        className="point-filter-tab"
      />
      <SearchInput onSubmit={handleSearch} placeholder={t('common.search.placeholder')} />
      <TabContents className="" tabs={tabs} active={activeTabKey} />
    </Container>
  );
}

const Container = styled.div`
  & .point-filter-tab {
    padding: 7.5px;
    margin: 12px 0 40px;
  }
`;
