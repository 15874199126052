import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, LIGHT_COLOR } from '@/constants';
import { Icons } from '@/assets/images';
import { ListItem } from '../personal';

export default function TermsAndPrivacy() {
  const { t } = useTranslate();

  return (
    <Container>
      <ul>
        <ListItem>
          <Link to={pagePaths.SETTINGS_CONDITION}>
            <span>{t('settings.privacy')}</span>
            <img src={Icons.detail} alt="" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to={pagePaths.SETTINGS_TERMS}>
            <span>{t('settings.terms')}</span>
            <img src={Icons.detail} alt="" />
          </Link>
        </ListItem>
      </ul>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 44px;

  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 18px;
  font-weight: 400;
`;
