import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  keyword: '%%',
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setFaqKeyword: (state, action) => {
      state.keyword = action.payload;
    },
  },
});

export const { setFaqKeyword } = settingSlice.actions;
export default settingSlice;
