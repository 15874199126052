import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import SearchInput from '@views/shared/forms/SearchInput';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client';
import { Icons } from '@/assets/images';
import { LIGHT_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';
import PartialLoading from '@/views/shared/spinner/PartialLoading';
import { DELETE_COIN_WALLET, MY_PARTNER_WALLETS, MY_WALLETS } from '@/graphql/Coins';
import { useTranslate } from '@/hooks/useTranslate';
import { isInclude } from '@/utils';

export default function ConnectList() {
  const { locale } = useSelector((state) => state.user);
  const { t } = useTranslate();
  const [walletList, setWalletList] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const filteredList = useMemo(() => {
    if (searchValue.length === 0) return walletList;

    return walletList.filter((el) => isInclude(el.name, searchValue));
  }, [searchValue, walletList]);

  const { loading } = useQuery(MY_PARTNER_WALLETS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ myCoinWalletsByOnlyPartner }) => {
      const list = myCoinWalletsByOnlyPartner.map((el) => ({
        id: el.id,
        imgSrc: el.coin.image?.url || Icons.defaultLogo,
        name: locale === 'ko' ? el.coin.name : el.coin.name_en,
        date: el.created_at,
        coinId: el.coin_id,
        isUnlink: el.coin.is_unlink,
      }));

      setWalletList(list);
    },
  });

  const [deleteWallet] = useMutation(DELETE_COIN_WALLET, {
    onCompleted: ({ deleteUserCoinWallet }) => {
      console.log(deleteUserCoinWallet.status);
      console.log(deleteUserCoinWallet.message);
    },
    onError: () => {
      alert('해제안되는거 해야됨');
    },
  });

  const handleSearch = (value) => {
    setSearchValue(value.keyword);
  };

  return (
    <Container>
      <div>
        <SearchInput onSubmit={handleSearch} placeholder={t('common.search.placeholder')} />
      </div>
      {loading ? (
        <PartialLoading />
      ) : (
        <ServiceWrapper>
          {filteredList.map((el) => (
            <Box key={el.id}>
              <img src={el.imgSrc} alt="" />
              <div className="content">
                <span className="title">{el.name}</span>
                <span className="date">{el.date}</span>
              </div>
              {el.isUnlink ? (
                <button
                  type="button"
                  onClick={() => deleteWallet({ variables: { id: el.coinId } })}
                >
                  {t('settings.deleteWallet')}
                </button>
              ) : (
                ''
              )}
            </Box>
          ))}
        </ServiceWrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 24px 15px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  border-radius: 15px;
  background: ${W_COLOR};
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

  padding: 24px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 45px;
    height: 45px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;

    margin-left: 10px;
    margin-right: 10px;

    .title {
      color: #000;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 16px;
      font-weight: 400;
    }

    .date {
      color: ${TEXT_GRAY};
      font-family: 'Spoqa Han Sans Neo';
      font-size: 12px;
      font-weight: 400;
    }
  }

  button {
    border-radius: 5px;
    background: ${LIGHT_COLOR};
    padding: 5px 10px;
  }
`;

const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-top: 20px;
`;
