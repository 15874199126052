import React from 'react';
import styled from 'styled-components';
import PasswordInput from '@views/shared/forms/PasswordInput';
import FormButton from '@views/shared/forms/FormButton';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { modals } from '@views/shared/modal/Modals';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '@/hooks/useTranslate';
import { CHECK_PASSWORD, DELETE_USER } from '@/graphql/Users';
import useModals from '@/hooks/useModal';
import { B_COLOR } from '@/constants';
import { parseGqlErrorMsg } from '@/utils';
import { pagePaths } from '@/navigation/Pages';

const Step2 = ({ onChangeStep }) => {
  const { openModal } = useModals();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const user = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [deleteUser, { loading: deleteLoading }] = useMutation(DELETE_USER, {
    onCompleted: ({ deleteUser }) => {
      if (deleteUser.status === 'success') {
        localStorage.removeItem('privateKey');
        onChangeStep(3);
      } else if (deleteUser.status === 'fail') {
        openModal(modals.dialog, {
          title: t('settings.personalData.deleteAccount'),
          message: deleteUser.message,
        });
      }
    },
    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: parseGqlErrorMsg(error), link: -1 } });
    },
  });

  const [checkPassword] = useMutation(CHECK_PASSWORD, {
    onCompleted: ({ checkPassword }) => {
      if (checkPassword.status === 'success') {
        openModal(modals.confirm, {
          title: t('settings.personalData.deleteAccount'),
          message: t('settings.personalData.deleteAccount.confirm'),

          callback: () => deleteUser({ variables: { id: user.id } }),
        });
      } else if (checkPassword.status === 'fail') {
        openModal(modals.dialog, {
          title: t('settings.personalData.deleteAccount'),
          message: checkPassword.message,
        });
      }
    },
    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: parseGqlErrorMsg(error), link: -1 } });
    },
  });

  const onSubmit = ({ password }) => {
    checkPassword({
      variables: { password },
    });
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="title">{t('settings.personalData.deleteAccount.password.title')}</div>
      <div className="subtitle">{t('settings.personalData.deleteAccount.password.desc')}</div>
      <div className="input-btn">
        <PasswordInput
          id="password"
          name="password"
          placeholder={t('settings.personalData.currentPasswordPlaceholder')}
          register={register}
          maxlength="20"
          options={{
            required: t('auth.signup.alertEssential'),
          }}
        />
        {errors.exPassword && <small role="alert">{errors.exPassword.message}</small>}

        <FormButton type="submit" text={t('common.confirm')} color="black" />
      </div>
    </Container>
  );
};

export default Step2;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;

  .title {
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 400;
    line-height: 30px; /* 125% */

    margin-bottom: 20px;
  }

  .subtitle {
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    white-space: pre-wrap;

    margin-bottom: 84px;
  }

  .input-btn {
    width: 80%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 21px;
  }
`;
