import { gql } from '@apollo/client';

// 전송 이력 목록을 가져온다.
// $coin_id: Mixed
// $perPage: Int!
// $page: Int!

const RETRIEVE_LIST = gql`
  query ($coin_id: Int!, $perPage: Int!, $page: Int!) {
    myCoinTransactions(
      coin_id: $coin_id
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: $perPage
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
      }
      data {
        id
        coin_id
        amount
        txhash
        from_wallet_address
        to_wallet_address
        ip
        created_at
        updated_at
        coin {
          id
          name
          name_en
          symbol
          acronym
          created_at
          image {
            url
          }
        }
      }
    }
  }
`;

// 상세 정보를 가져오는 쿼리
// $id - 정보 이력 id
const RETRIEVE_DETAIL = gql`
  query ($id: ID) {
    userCoinTransaction(id: $id) {
      id
      user_id
      coin_id
      from_wallet_address
      to_wallet_address
      amount
      created_at
      updated_at
      coin {
        id
        name
        name_en
        symbol
        acronym
        image {
          url
          thumb_medium_url
          thumb_large_url
          thumb_medium_url
        }
      }
    }
  }
`;

// 모든 코인(토큰) 목록을 가져온다
const COIN_LIST = gql`
  query {
    coins(orderBy: [{ column: CREATED_AT, order: DESC }], first: 20, page: 1) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
      }
      data {
        id
        name
        name_en
        symbol
        acronym
        order_by_no
        created_at
        image {
          url
        }
      }
    }
  }
`;

export { RETRIEVE_LIST, RETRIEVE_DETAIL, COIN_LIST };
