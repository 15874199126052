import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, LIGHT_COLOR } from '@/constants';
import { Icons } from '@/assets/images';
import useModals from '@/hooks/useModal';
import { modals } from '@/views/shared/modal/Modals';
import { setCategory } from '@/redux/reducer/swapHistorySlice';
import SwapItemList from './SwapItemList';
import RewardItemList from './RewardItemList';

const swapHistoryCategoryText = {
  6: 'common.month.6',
  3: 'common.month.3',
  2: 'common.month.2',
  1: 'common.month.1',
  swap: 'common.usage.swap',
  reward: 'common.usage.reward',
  all: 'common.usage.all',
  export: 'common.usage.export',
  import: 'common.usage.import',
  cancel: 'common.usage.cancel',
};

export default function SwapHistoy() {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { openModal } = useModals();
  const { activeDateCategory, activeUsageCategory } = useSelector((state) => state.swapHistory);

  return (
    <Container>
      <BtnsWrapper>
        <Button
          onClick={() => {
            dispatch(setCategory('date-category'));
            openModal(modals.pointSwapSelectModal, {});
          }}
        >
          <span>{t(swapHistoryCategoryText[activeDateCategory])}</span>
          <img src={Icons.downPolygon} alt="" />
        </Button>
        <Button
          onClick={() => {
            dispatch(setCategory('usage-category'));
            openModal(modals.pointSwapSelectModal, {});
          }}
        >
          <span>{t(swapHistoryCategoryText[activeUsageCategory])}</span>
          <img src={Icons.downPolygon} alt="" />
        </Button>
      </BtnsWrapper>
      {activeUsageCategory === 'swap' && <SwapItemList />}
      {activeUsageCategory === 'reward' && <RewardItemList />}
    </Container>
  );
}

const HEADER_H = 55;
const NAV_H = 84;
const Container = styled.div`
  padding: 16px 15px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: calc(100% - ${HEADER_H + NAV_H}px);
  overflow: hidden;
`;

const BtnsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5%;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  justify-content: center;
  height: 50px;

  border-radius: 15px;
  border: 1px solid ${LIGHT_COLOR};

  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;
`;
