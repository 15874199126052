import historyMenu from './ico/menu/history.svg';
import moreMenu from './ico/menu/more.svg';
import newsMenu from './ico/menu/news.svg';
import pointMenu from './ico/menu/point.svg';
import walletMenu from './ico/menu/wallet.svg';
import historyOnMenu from './ico/menu/historyOn.svg';
import moreOnMenu from './ico/menu/moreOn.svg';
import newsOnMenu from './ico/menu/newsOn.svg';
import pointOnMenu from './ico/menu/pointOn.svg';
import walletOnMenu from './ico/menu/walletOn.svg';
import earnMenu from './ico/menu/earn.svg';
import earnMenuOn from './ico/menu/earnOn.svg';
import defaultLogo2 from './ico/defaultLogo2.svg';
import detailInfo from './ico/detailInfo.svg';
import filterBtn from './ico/button/filterBtn.svg';
import defaultLogo from './ico/defaultLogo.svg';
import setAlertBtn from './ico/button/alertBtn.svg';
import setCommonBtn from './ico/button/commonBtn.svg';
import setDocBtn from './ico/button/docBtn.svg';
import setLogoutBtn from './ico/button/logoutBtn.svg';
import setMyBtn from './ico/button/myBtn.svg';
import setPhoneBtn from './ico/button/phoneBtn.svg';
import detail from './ico/button/detail.svg';
import downPolygon from './ico/downPolygon.svg';
import checkBoxOn from './ico/checkBoxOn.svg';
import checkBoxOff from './ico/checkBoxOff.svg';
import circleCheckBoxOff from './ico/circleCheckBoxOff.svg';
import circleCheckBoxOn from './ico/circleCheckBoxOn.svg';
import rightDropdown from './ico/right.svg';
import welcome from './img/welcome.svg';
import reset from './ico/reset.svg';
import bksp from './ico/button/bksp.svg';
import successCheckBox from './ico/successCheckBox.svg';
import language from './ico/language.svg';
import noResult from './img/noResult.svg';
import sendComplete from './img/sendComplete.svg';
import detailRight from './ico/detailRight.svg';
import emailSend from './ico/email_send.svg';
import sendBtn from './ico/button/send.svg';
import receiveBtn from './ico/button/receive.svg';
import swapBtn from './ico/button/swap.svg';
import saveBtn from './ico/button/save.svg';
import qrscanBtn from './ico/button/qrscan.svg';
import share from './ico/button/share.svg';
import wallet from './img/wallet.svg';
import copyBtn from './ico/button/copy.svg';
import partialLoading from './img/partialLoading.gif';
import pwShow from './ico/pw_show.svg';
import pwUnshow from './ico/pw_unshow.svg';
import receive from './ico/receive.svg';
import send from './ico/send.svg';
import tutorial1 from './img/tutorial-1.png';
import tutorial2 from './img/tutorial-2.png';
import tutorial3 from './img/tutorial-3.png';
import tutorial4 from './img/tutorial-4.png';
import tutorial5 from './img/tutorial-5.png';
import error from './img/error.svg';
import emailFail from './img/emailSend_fail.svg';
import emailSuccess from './img/emailSend_success.svg';
import noWallet from './img/noWallet.png';
import loading from './img/loading.gif';
import versionBtn from './ico/button/versionBtn.svg';
import privacyBtn from './ico/button/privacyBtn.svg';
import faqBtn from './ico/button/faqBtn.svg';
import langBtn from './ico/button/languageBtn.svg';
import customerService from './ico/customerService.svg';
import faq from './img/faq.svg';
import search from './ico/button/search.svg';
import warning from './ico/warning.svg';
import sampleBanner1 from './img/sampleBanner-1.png';
import sampleBanner2 from './img/sampleBanner-2.png';
import sampleBanner3 from './img/sampleBanner-3.png';
import cash from './ico/cash.svg';
import cashTrans from './ico/cash_trans.svg';
import checkinBg from './img/checkinBg.svg';
import systemCheck from './img/systemCheck.svg';
import calendar from './ico/calendar.svg';
import defaultTmopLogo from './ico/defaultTmopLogo.svg';

import serviceTmonet from './ico/ico_service_tmonet.svg';
import assetLoading from './ico/ico_asset_loading.svg';
import swapArrow from './ico/ico_swap_arrow.svg';
import swapHistory from './ico/ico_swap_history.svg';
import rewardHistory from './ico/ico_reward_history.svg';
import arrowRight from './ico/arrowRight.svg';
import arrowRightW from './ico/arrowRight_white.svg';

import tmoWalletLogo from './img/tmoWalllet_logo.svg';
import faqTop from './img/faq_top.png';

import damdamPopupKo from './img/popupDamdamKo.png';
import damdamPopupEn from './img/popupDamdamEn.png';

export const Icons = {
  // new
  earnMenu,
  earnMenuOn,
  historyMenu,
  moreMenu,
  newsMenu,
  pointMenu,
  walletMenu,
  historyOnMenu,
  moreOnMenu,
  newsOnMenu,
  pointOnMenu,
  walletOnMenu,
  defaultTmopLogo,
  defaultLogo,
  defaultLogo2,
  detailInfo,
  filterBtn,
  versionBtn,
  privacyBtn,
  faqBtn,
  langBtn,
  arrowRight,
  arrowRightW,
  setAlertBtn,
  setCommonBtn,
  setDocBtn,
  setLogoutBtn,
  setMyBtn,
  setPhoneBtn,
  downPolygon,
  checkBoxOn,
  checkBoxOff,
  circleCheckBoxOff,
  circleCheckBoxOn,
  rightDropdown,
  reset,
  detail,
  bksp,
  successCheckBox,
  language,
  detailRight,
  emailSend,
  sendBtn,
  receiveBtn,
  swapBtn,
  saveBtn,
  qrscanBtn,
  copyBtn,
  pwShow,
  pwUnshow,
  receive,
  send,
  customerService,
  share,
  search,
  warning,
  cash,
  cashTrans,
  calendar,

  // old

  sendComplete,
  serviceTmonet,
  assetLoading,
  swapArrow,
  swapHistory,
  rewardHistory,
};

export const Images = {
  sampleBanner1,
  sampleBanner2,
  sampleBanner3,

  noResult,
  sendComplete,
  welcome,
  wallet,
  partialLoading,
  tutorial1,
  tutorial2,
  tutorial3,
  tutorial4,
  tutorial5,
  error,
  emailFail,
  emailSuccess,
  noWallet,
  loading,
  checkinBg,
  systemCheck,

  tmoWalletLogo,
  faqTop,
  faq,

  damdamPopupKo,
  damdamPopupEn,
};
