import { gql } from '@apollo/client';

const SET_ATTENDANCE = gql`
  mutation setAttendance {
    setAttendance {
      status
      message
    }
  }
`;

const GET_ATTENDANCE = gql`
  query getAttendance {
    getAttendance {
      data
    }
  }
`;

export { GET_ATTENDANCE, SET_ATTENDANCE };
