import styled from 'styled-components';
import { useState } from 'react';

import { Icons } from '@assets/images';
import CustomInput from '../input/CustomInput';

export default function PasswordInput({
  id,
  name,
  className,
  label,
  register,
  disabled,
  placeholder,
  maxlength,
  options,
  useToggle,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <Container>
        <CustomInput
          type={showPassword ? 'text' : 'password'}
          id={id}
          className={className}
          autoComplete={showPassword ? 'off' : 'new-password'}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxlength}
          register={register}
          name={name}
          options={options}
        />
        {useToggle && (
          <ToggleButton
            img={showPassword ? Icons.pwUnshow : Icons.pwShow}
            type="button"
            onClick={togglePassword}
          >
            toggle
          </ToggleButton>
        )}
      </Container>
    </>
  );
}

const Container = styled.div`
  position: relative;
`;

const ToggleButton = styled.button`
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  text-indent: -9999px;
  border: 0;
  background: transparent url(${(props) => props.img}) no-repeat center;
  width: 26px;
  height: 20px;
`;
