import moment from 'moment';
import { ACCESS_TOKEN, EXPIRED_AT, REFRESH_TOKEN, TUTORIAL_AT } from '@/constants';

// 토큰 정보
export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN);
export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN);
export const setAccessToken = (token) => localStorage.setItem(ACCESS_TOKEN, token);
export const setRefreshToken = (token) => localStorage.setItem(REFRESH_TOKEN, token);
export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN);
export const removeRefreshToken = () => localStorage.removeItem(REFRESH_TOKEN);

// 토큰 만료 날짜
export const getExpiredAt = () => localStorage.getItem(EXPIRED_AT);
export const setExpiredAt = (date) => localStorage.setItem(EXPIRED_AT, date);
export const removeExpiredAt = () => localStorage.removeItem(EXPIRED_AT);

export const setTutorialInfo = () => localStorage.setItem(TUTORIAL_AT, new Date());
export const getTutorialInfo = () => localStorage.getItem(TUTORIAL_AT);

// 클라이언트 설정 토큰 관련
export const defaults = {
  isLoggedIn: localStorage.getItem(ACCESS_TOKEN) !== null,
};

export const resolvers = {
  Mutation: {
    logUserIn: (_, { token }, { cache }) => {
      localStorage.setItem(ACCESS_TOKEN, token);
      cache.writeData({
        data: { isLoggedIn: true },
      });
      return null;
    },
    logUserOut: (_, __, { cache }) => {
      localStorage.removeItem(ACCESS_TOKEN);
      window.location.reload();
      return null;
    },
  },
};

/*
 토큰관련 저장정보는 토큰, 만료시간, 재로그인시간, 리프레시만료시간 4가지 이며 
 만료시간 ~ 재로그인시간 사이에는 리프레시를 통한 토큰연장 방법을 사용
 재로그인시간 ~ 리프레시만료시간 사이에는 재로그인을 통한 토큰갱신 방법을 사용한다
 */
export const setTokenInfos = (_accessToken, _expiresIn, _refreshToken) => {
  const newExpireAt = moment().add(_expiresIn, 'second').format('yyyy-MM-DD HH:mm:ss');

  setAccessToken(_accessToken);
  setExpiredAt(newExpireAt);
  setRefreshToken(_refreshToken);
};

export const removeTokenInfos = () => {
  removeAccessToken();
  removeRefreshToken();
  removeExpiredAt();
};
