import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import PageInfo from '@views/shared/pageInfo';
import FormButton from '@views/shared/forms/FormButton';
import FormButtonWrap from '@views/shared/forms/FormButtonWrap';
import { modals } from '@views/shared/modal/Modals';
import { useNavigate } from 'react-router-dom';
import Spinner from '@/views/shared/spinner/Spinner';
import useModals from '@/hooks/useModal';
import { CREATE_PRIVATE_KEY_ENCRYPTION_SYNTAX } from '@/graphql/Users';
import { useTranslate } from '@/hooks/useTranslate';
import { pagePaths } from '@/navigation/Pages';
import CustomInput from '@/views/shared/input/CustomInput';

export default function RegPrivateKey() {
  const { register, handleSubmit } = useForm();
  const { openModal } = useModals();
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [regPrivateKey, { loading }] = useMutation(CREATE_PRIVATE_KEY_ENCRYPTION_SYNTAX);

  const clearInput = () => {
    document.querySelector('.primaryKey').value = '';
  };

  const verifyPrivateKey = (key) => {
    const regex = /^(0x)?[0-9a-f]{64}$/i;
    return regex.test(key.trim());
  };

  const onSubmit = ({ pkey }) => {
    if (verifyPrivateKey(pkey)) {
      const currentPk = localStorage.getItem('privateKey');
      // 이미 보유중인 privateKey 가 없거나 유효하지 않은경우 개인키 등록
      if (!currentPk || !verifyPrivateKey(currentPk)) {
        regPrivateKey({
          variables: {
            private_key: pkey,
          },
          onCompleted: (data) => {
            if (data.createPrivateKeyEncryptionSyntax.status === 'fail') {
              openModal(modals.dialog, {
                title: t('settings.security.registerPrivateKey'),
                message: data.createPrivateKeyEncryptionSyntax.message,
              });
              return false;
            }

            // 키등록 완료시 디바이스에 저장 및 니모닉 확인창 이동
            if (data.createPrivateKeyEncryptionSyntax.status === 'success') {
              openModal(modals.dialog, {
                title: t('settings.security.registerPrivateKey.completed'),
                message: data.createPrivateKeyEncryptionSyntax.message,
                callback: () => {
                  navigate(pagePaths.WALLET_BACKUP, {
                    state: {
                      backupCode:
                        data.createPrivateKeyEncryptionSyntax.data.privateKeyDecryptionSyntax,
                    },
                  });
                },
              });
            }
          },
        });
      } else {
        openModal(modals.dialog, {
          title: t('common.info'),
          message: t('settings.security.registerPrivateKey.alreadyHasPrivateKey'),
        });
      }
    } else {
      openModal(modals.dialog, {
        title: t('settings.security.registerPrivateKey'),
        message: t('settings.security.registerPrivateKey.alertInvalidPrivateKey'),
      });
    }
  };

  const infoTexts = {
    title: t('settings.security.registerPrivateKey.title'),
    sutTxt: t('settings.security.registerPrivateKey.subText'),
  };

  if (loading) return <Spinner isLoading={loading} />;

  return (
    <Container>
      <PageInfo title={infoTexts.title} subTxt={infoTexts.sutTxt} />
      {/* <div className="warning">
        <AlertTriangle size={20} />
        {t('settings.security.keyWarning')}
      </div> */}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          className="primaryKey"
          type="text"
          register={register}
          name="pkey"
          placeholder={t('settings.security.registerPrivateKey.inputPlaceholder')}
          autoComplete="off"
        />

        <div className="btn-wrapper">
          {/* <FormButton text={t('common.inputReset')} rounded color="second" onClick={clearInput} /> */}
          <FormButton type="submit" text={t('common.submit')} color="black" />
        </div>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  padding: 56px 15px;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  input::placeholder {
    text-align: center;
  }
`;

const Form = styled.form`
  padding: 0 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
`;
