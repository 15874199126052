import styled from 'styled-components';
import cn from 'classnames';

function Repeat({ numTimes, children }) {
  const items = [];
  for (let i = 0; i < numTimes; i += 1) {
    items.push(children(i));
  }
  return <ol>{items}</ol>;
}

export default function Progress({ current, total }) {
  return (
    <Container>
      {current && total && (
        <Repeat numTimes={total}>
          {(index) => <li key={index} className={cn({ on: current === index + 1 })} />}
        </Repeat>
      )}
    </Container>
  );
}

const Container = styled.div`
  ol {
    display: flex;
    margin: 0 -5px;
    li {
      width: 17px;
      height: 5px;
      border-radius: 5px;
      background: #e0e0e2;
      margin: 0 5px;
      &.on {
        width: 36px;
        background: #523ee8;
      }
    }
  }
`;
