import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import useAuth from '@/hooks/useAuth';

export default function Logout() {
  const { expiredLogin } = useAuth();

  useEffect(() => {
    expiredLogin();
  }, []);
}
