import styled from 'styled-components';
import TermsText from '@views/shared/text/TermsText';

export default function Terms() {
  return (
    <TermsContainer>
      <TermsText />
    </TermsContainer>
  );
}

export const TermsContainer = styled.div`
  padding: 55px 15px 25px;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 700;
    font-size: 20px;
    color: #2f253d;
    margin-bottom: 10px;
  }
  .update {
    font-size: 13px;
  }
  .txt {
    font-size: 15px;
  }
`;
