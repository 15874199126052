import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

import userSlice from '@/redux/reducer/userSlice';
import walletSlice from '@/redux/reducer/walletSlice';
import trancSlice from '@/redux/reducer/trancSlice';
import swapSlice from '@/redux/reducer/swapSlice';
import boardSlice from '@/redux/reducer/boardSlice';
import settingSlice from '@/redux/reducer/settingSlice';
import swapHistorySlice from '@/redux/reducer/swapHistorySlice';

const reducers = combineReducers({
  user: userSlice.reducer,
  wallet: walletSlice.reducer,
  transaction: trancSlice.reducer,
  swap: swapSlice.reducer,
  board: boardSlice.reducer,
  setting: settingSlice.reducer,
  swapHistory: swapHistorySlice.reducer,
});

const persistConfig = {
  key: 'root',
  // localStorage에 저장합니다.
  storage,
  // auth, board, studio 3개의 reducer 중에 auth reducer만 localstorage에 저장합니다.
  whitelist: ['user', 'auth', 'wallet', 'swap'],
  // 그것만 제외합니다
  // blacklist :['swap']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
