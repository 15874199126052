import { useState } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

import FormButton from '@views/shared/forms/FormButton';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { pagePaths } from '@/navigation/Pages';
import { Icons, Images } from '@/assets/images';
import { B_COLOR, PRI_COLOR } from '@/constants';
import { setTutorialInfo } from '@/services/HandleToken';
import { useTranslate } from '@/hooks/useTranslate';

export default function Tutorial() {
  const [slide, setSlide] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslate();

  const handleSlide = () => {
    slide.slideNext();
  };

  return (
    <Container>
      <div className="slide_wrap">
        <Swiper
          pagination={{ clickable: true }}
          onSwiper={(slide) => {
            setSlide(slide);
          }}
          modules={[Pagination]}
        >
          <Slide>
            <Logo src={Images.tmoWalletLogo} alt="tut-1" />
            <div className="tut-1">
              <img src={Images.tutorial1} alt="" />
            </div>
            <BtnWrapper>
              <FormButton
                text={t('common.next')}
                color="black"
                onClick={handleSlide}
                flex={1}
                icon={<img src={Icons.arrowRightW} alt="next" />}
              />
            </BtnWrapper>
          </Slide>
          <Slide>
            <div>
              <Title>{t('tutorial.slide2.title')}</Title>
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t('tutorial.slide2.subtitle')),
                }}
              />
            </div>
            <div className="tut-2">
              <img src={Images.tutorial2} alt="tut2" />
            </div>
            <BtnWrapper>
              <FormButton
                text={t('common.next')}
                color="black"
                onClick={handleSlide}
                flex={1}
                icon={<img src={Icons.arrowRightW} alt="next" />}
              />
            </BtnWrapper>
          </Slide>
          <Slide>
            <div>
              <Title>{t('tutorial.slide3.title')}</Title>
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t('tutorial.slide3.subtitle')),
                }}
              />
            </div>
            <div className="tut-3">
              <img src={Images.tutorial3} alt="tut3" />
            </div>
            <BtnWrapper>
              <FormButton
                text={t('common.next')}
                color="black"
                onClick={handleSlide}
                flex={1}
                icon={<img src={Icons.arrowRightW} alt="next" />}
              />
            </BtnWrapper>
          </Slide>
          <Slide>
            <div>
              <Title>{t('tutorial.slide4.title')}</Title>
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t('tutorial.slide4.subtitle')),
                }}
                icon={<img src={Icons.arrowRightW} alt="next" />}
              />
            </div>
            <div className="tut-4">
              <img src={Images.tutorial4} alt="tut4" />
            </div>
            <BtnWrapper>
              <FormButton
                text={t('common.next')}
                color="black"
                onClick={handleSlide}
                flex={1}
                icon={<img src={Icons.arrowRightW} alt="next" />}
              />
            </BtnWrapper>
          </Slide>
          <Slide>
            <div>
              <Title>{t('tutorial.slide5.title')}</Title>
              <SubTitle
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t('tutorial.slide5.subtitle')),
                }}
                icon={<img src={Icons.arrowRightW} alt="next" />}
              />
            </div>
            <div className="tut-5">
              <img src={Images.tutorial5} alt="tut4" />
            </div>
            <BtnWrapper>
              <FormButton
                text={t('common.start')}
                color="black"
                flex={1}
                onClick={() => {
                  setTutorialInfo();
                  navigate(pagePaths.WELCOME);
                }}
              />
            </BtnWrapper>
          </Slide>
        </Swiper>
      </div>
    </Container>
  );
}

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10% 30px 5%;
  height: 100%;

  .slide_wrap {
    height: 100%;

    > .swiper {
      .swiper-pagination {
        display: none;

        &-bullet {
          width: 21px;
          height: 7px;
          border-radius: 8px;
          margin: 0 5px;
          transition: width 0.3s;
          &-active {
            width: 35px;
            background: ${PRI_COLOR};
          }
        }
      }
    }
  }
`;

const Slide = styled(SwiperSlide)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;

  .tut-1 {
    display: flex;
    justify-content: center;
    margin: 0 19%;

    img {
      max-height: 380px;
      margin: 20px 0;
    }
  }

  .tut-2 {
    display: flex;
    justify-content: center;
    margin: 0 22%;

    img {
      max-height: 380px;
      margin: 20px 0;
    }
  }

  .tut-3 {
    display: flex;
    justify-content: center;
    margin: 0 14%;

    img {
      max-height: 380px;
      margin: 20px 0;
    }
  }

  .tut-4 {
    display: flex;
    justify-content: center;
    margin: 0 14%;

    img {
      max-height: 380px;
      margin: 20px 0;
    }
  }

  .tut-5 {
    display: flex;
    justify-content: center;
    margin: 0 10%;

    img {
      max-height: 380px;
      margin: 20px 0;
    }
  }
`;

const Logo = styled.img`
  width: 130px;
  margin: 0 auto;
`;

const Title = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  text-align: left;
  width: 100%;
  white-space: pre-wrap;
  margin-bottom: 18px;
`;

const SubTitle = styled.div`
  white-space: pre-wrap;
  text-align: left;
  width: 100%;

  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
`;

const BtnWrapper = styled.div`
  margin-top: 5%;
  padding: 0 5%;

  & button {
    max-width: 400px;
  }
`;
