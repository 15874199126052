import styled, { css } from 'styled-components';

import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, TEXT_GRAY } from '@/constants';

export default function ListFilter({ setSend, setSwap, setShowList, showList }) {
  const { t } = useTranslate();

  return (
    <Container>
      {setSend && (
        <Tab
          value="transaction"
          isActive={showList === 'transaction'}
          onClick={(e) => {
            const value = e.currentTarget.getAttribute('value');
            setShowList(value);
          }}
        >
          {t('token.tab.transaction')}
        </Tab>
      )}
      {setSwap && (
        <Tab
          value="swap"
          isActive={showList === 'swap'}
          onClick={(e) => {
            const value = e.currentTarget.getAttribute('value');
            setShowList(value);
          }}
        >
          {t('token.tab.swap')}
        </Tab>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 0 10px;
  display: flex;
  gap: 24px;
`;

const Tab = styled.div`
  border-top: 6px solid transparent;
  color: ${TEXT_GRAY};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 400;
  padding-top: 12px;

  ${({ isActive }) =>
    isActive &&
    css`
      border-top: 6px solid ${B_COLOR};
      color: ${B_COLOR};
      font-family: 'Spoqa Han Sans Neo';
      font-size: 16px;
      font-weight: 700;
    `};
`;
