// 3자리 간격으로 "," 를 추가합니다.
export function addCommas(_number) {
  return _number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getRandomNumbers(from, to, count) {
  const randomNumbers = new Set();
  while (randomNumbers.size < count) {
    const randomNumber = Math.floor(Math.random() * (to - from + 1)) + from;
    randomNumbers.add(randomNumber);
  }
  return Array.from(randomNumbers);
}

export function isNumber(value) {
  return /^[0-9]*$/.test(value);
}
