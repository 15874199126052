import { SYSTEM_CHECK, LATEST_UPDATE } from '@/constants/index';

export const removeHtmlTags = (html) => {
  const tmpElement = document.createElement('div');
  tmpElement.innerHTML = html;
  return tmpElement.textContent || tmpElement.innerText || '';
};

export const isSystemChecking = () => Number(localStorage.getItem(SYSTEM_CHECK)) > 0;

export const setSystemCheck = (_value) => {
  if (_value) {
    localStorage.setItem(SYSTEM_CHECK, 1);
  } else {
    localStorage.setItem(SYSTEM_CHECK, 0);
  }
};

export const getLatestReleaseCheckTime = () => localStorage.getItem(LATEST_UPDATE);
export const setLatestReleaseCheckTime = (date) => localStorage.setItem(LATEST_UPDATE, date);

export const parseGqlErrorMsg = (error) => {
  const errorObj = error.graphQLErrors[0]?.extensions;

  let errorMsg = [];
  if ('errors' in errorObj) {
    errorMsg = Object.values(errorObj.errors);
  }
  if ('validation' in errorObj) {
    Object.keys(errorObj.validation).forEach((key) => {
      errorMsg.push(...errorObj.validation[key]);
    });
  }
  return errorMsg;
};

export const getVersion = () => {
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isAos = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  if (isAndroid) return '1.0.0';
  if (isAos) return '1.0.0';
  return '1.0.0';
};

export function groupByMonthSwapList(data) {
  const groupedData = {};

  data.forEach((item) => {
    const createdAt = new Date(item.created_at);
    const yearMonth = `${createdAt.getFullYear()}.${(createdAt.getMonth() + 1)
      .toString()
      .padStart(2, '0')}`;

    if (!groupedData[yearMonth]) {
      groupedData[yearMonth] = [];
    }

    groupedData[yearMonth].push(item);
  });

  return groupedData;
}

export function groupByMonthRewardList(data) {
  const groupedData = {};

  data.forEach((item) => {
    const createdAt = new Date(item.created_at);
    const yearMonth = `${createdAt.getFullYear()}.${(createdAt.getMonth() + 1)
      .toString()
      .padStart(2, '0')}`;

    if (!groupedData[yearMonth]) {
      groupedData[yearMonth] = { data: [], accumulated: 0 };
    }

    groupedData[yearMonth].data.push(item);
    groupedData[yearMonth].accumulated += item.reward;
  });

  return groupedData;
}

export function shortenAddress(address, maxLength = 20, ellipsis = '...') {
  if (address.length <= maxLength) {
    return address;
  }

  const prefixLength = Math.ceil((maxLength - ellipsis.length) / 2);
  const suffixLength = Math.floor((maxLength - ellipsis.length) / 2);
  return (
    address.substring(0, prefixLength) + ellipsis + address.substring(address.length - suffixLength)
  );
}

export function isInclude(_target, _value) {
  if (!_value) return true;
  // 검색 대상 문자열과 값을 모두 소문자로 변환하여 비교
  const targetLower = _target.toLowerCase();
  const valueLower = _value.toLowerCase();

  // 검색 대상 문자열을 단어 단위로 분할하여 배열로 저장
  const targetWords = targetLower.split(' ');

  // 값이 포함되는지 확인하는 플래그
  let isMatched = false;

  // 각 단어에 대해 값을 포함하는지 확인
  targetWords.forEach((word) => {
    // 단어와 값이 일치하는 경우
    if (word === valueLower) {
      isMatched = true;
    }
    // 단어가 값으로 시작하는 경우
    else if (word.startsWith(valueLower)) {
      isMatched = true;
    }
    // 값이 단어로 시작하는 경우
    else if (valueLower.startsWith(word)) {
      isMatched = true;
    }
  });

  return isMatched;
}

export function extractAddressFromEthereumString(str) {
  const regex = /(?:ethereum:)?(0x[0-9a-fA-F]{40})/; // 정규식 패턴 수정
  const match = regex.exec(str); // 정규식과 문자열 매칭

  if (match && match.length > 1) {
    return match[1]; // 첫 번째 캡처 그룹 반환
  }

  return null; // 매칭되지 않을 경우 null 반환
}
