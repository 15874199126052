import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  email: '',
  locale: '',
  privateKey: null,
  pin: null,
  me: {},
  isLoading: false,
  myWalletInfo: [],
  hasPrivateKey: false,
  private_key_decryption_syntax: null, // 유저테이블에 복구구문 암호화해서 저장되있는애
  hasWallet: 'unchecked',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { email, locale, privateKey, pin, userCoinWallets, id } = action.payload;
      state.id = id ?? state.id;
      state.email = email ?? state.email;
      state.locale = locale ?? state.locale;
      state.privateKey = privateKey ?? state.privateKey;
      state.pin = pin ?? state.pin;
      state.myWalletInfo = userCoinWallets ?? state.userCoinWallets;
    },
    setMyWalletInfo: (state, action) => {
      state.myWalletInfo = action.payload;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setMe: (state, action) => {
      state.me = action.payload;
    },

    setHasWallet: (state, action) => {
      state.hasWallet = action.payload;
    },
    setHasPrivateKeyTrue: (state, action) => {
      state.hasPrivateKey = true;
    },
    whenLogin: (state, action) => {
      state.private_key_decryption_syntax = action.payload;
    },
    resetUser: (state) => initialState,
  },
  extraReducers: {},
});

export const { setUser, setLocale, resetUser, setMyWalletInfo, setHasWallet } = userSlice.actions;

export default userSlice;
