import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { modals } from '@views/shared/modal/Modals';
import useModals from '@/hooks/useModal';
import { useTranslate } from '@/hooks/useTranslate';
import FormatAmount from '@/utils/formatAmount';
import { G_COLOR, TEXT_GRAY } from '@/constants';

function SwapItem({ item }) {
  const { openModal } = useModals();
  const { t } = useTranslate();
  // const dispatch = useDispatch();

  const getType = (_item) => {
    if (_item.type === 'import') {
      return t('swap.tab.import');
    }
    if (_item.type === 'export') {
      return t('swap.tab.export');
    }
    return '';
  };

  const getStatus = (_item) => {
    if (_item.status === 'CANCEL') {
      return t('common.usage.cancel');
    }
    if (_item.type === 'import') {
      return t('swap.type.import');
    }
    if (_item.type === 'export') {
      return t('swap.type.export');
    }
    return '';
  };

  // 환불
  // const [doRefund, { loading }] = useMutation(DO_REFUND, {
  //   onCompleted: (data) => {
  //     if (data.swapRefund.status === 'success') {
  //       dispatch(fetchMyQuery);
  //       openModal(modals.dialog, {
  //         title: t('common.info'),
  //         message: t('swap.list.alertRefundSuccess'),
  //         callback: () => {
  //           window.location.reload();
  //         },
  //       });
  //     }
  //   },
  //   onError: (error) => {
  //     openModal(modals.dialog, {
  //       title: t('common.error'),
  //       message: error.graphQLErrors[0]?.message,
  //     });
  //   },
  // });

  // const onClickRefund = () => {
  //   openModal(modals.confirm, {
  //     title: t('common.info'),
  //     message: t('swap.list.refund.confirm'),
  //     callback: () => doRefund({ variables: { id: item.id } }),
  //   });
  // };

  const showSwapDetail = (data) => {
    openModal(modals.tokenSwapDetail, {
      data,
    });
  };

  return (
    <ListItemContainer onClick={() => showSwapDetail(item)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        className="circle"
      >
        <circle cx="5.75391" cy="5.7041" r="5.5" fill={G_COLOR} />
      </svg>
      <ContentWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="type">{getType(item)}</div>
          {item.type === 'import' ? (
            <div className="amount">
              + {FormatAmount(item.import_amount)} {item.importCoin.unit.toUpperCase()}
            </div>
          ) : (
            <div className="amount">
              - {FormatAmount(item.export_amount)} {item.exportCoin.unit.toUpperCase()}
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="date">
            {getStatus(item)}﹒{moment(item.created_at).format('MM.DD HH:mm')}
          </div>
          {/* <div className="current-amount">잔액: 15900P</div> */}
        </div>
      </ContentWrapper>
    </ListItemContainer>
  );
}

export default SwapItem;

const ListItemContainer = styled('li')`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  padding-left: 12px;

  &::before {
    content: '';
    border: 1px dashed ${G_COLOR};
    height: calc(100% + 20px);
    position: absolute;
    top: 50%;
    left: 17px;
  }

  &:last-child::before {
    display: none; /* 마지막 자식에 대해 선을 숨깁니다. */
  }

  &:last-child {
    margin-bottom: 20px;
  }

  .type {
    color: #000;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .amount {
    color: #000;
    text-align: right;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .date {
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .current-amount {
    color: ${TEXT_GRAY};
    text-align: right;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

const RewardBox = styled('div')`
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  img {
    width: 20px;
    height: 20px;
  }

  .reward_info {
    display: flex;
    align-items: center;
  }

  .reward_amount {
    color: #00ffff;
  }
`;
