import { Route, Routes as ReactRoutes } from 'react-router-dom';

import Layout from '@views/layout';
import { defaultPages, headerMenuPages, headerPages, menuPages } from '@/navigation/Pages';
import SystemCheck from '@/views/SystemCheck';
import { isSystemChecking } from '@/utils';

export default function Routes() {
  // ip 체크시 모든라우트 점검페이지로 이동, 봐야할 컴포넌트(SystemCheck, popupHandler)
  // const { ip } = useCheckIp();

  // if (!ip) return <Spinner isLoading={!ip} />;

  // if (ip && !OFFICE_IP_LIST.includes(ip)) {
  //   return (
  //     <ReactRoutes>
  //       <Route path="/*" element={<SystemCheck />} />
  //     </ReactRoutes>
  //   );
  // }

  if (isSystemChecking()) {
    return (
      <ReactRoutes>
        <Route path="/*" element={<SystemCheck />} />
      </ReactRoutes>
    );
  }

  return (
    <ReactRoutes>
      <Route element={<Layout header menu />}>
        {headerMenuPages.map((page) => (
          <Route key={page.path} path={page.path} element={page.component} />
        ))}
      </Route>
      <Route element={<Layout header />}>
        {headerPages.map((page) => (
          <Route key={page.path} path={page.path} element={page.component} />
        ))}
      </Route>
      {defaultPages.map((page) => (
        <Route key={page.path} path={page.path} element={page.component} />
      ))}
      <Route element={<Layout menu />}>
        {menuPages.map((page) => (
          <Route key={page.path} path={page.path} element={page.component} />
        ))}
      </Route>
    </ReactRoutes>
  );
}
