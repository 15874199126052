import { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons, Images } from '@assets/images';
import FormButton from '@views/shared/forms/FormButton';
import { modals } from '@views/shared/modal/Modals';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@/views/shared/spinner/Spinner';
import useModals from '@/hooks/useModal';
import { CREATE_USER_COIN_WALLET } from '@/graphql/Service';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR } from '@/constants';
import CustomInput from '@/views/shared/input/CustomInput';

// 계정 연동 페이지
export default function ExternalServiceLink() {
  const [code, setCode] = useState('');
  const { state } = useLocation();
  const { locale } = useSelector((state) => state.user);
  const { openModal } = useModals();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createUserCoinWallet, { loading }] = useMutation(CREATE_USER_COIN_WALLET, {
    onCompleted: (data) => {
      setCode('');
      openModal(modals.dialog, {
        title: t('wallet.External.title'),
        message: data.createUserCoinWallet.message,
      });
    },
    onError: (error) => {
      setCode('');
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      openModal(modals.dialog, {
        title: t('common.error'),
        message: errorMsg.join(', '),
      });
    },
  });

  const handleCode = ({ target }) => {
    setCode(target.value);
  };

  const submitCode = () => {
    createUserCoinWallet({
      variables: {
        coin_id: parseInt(state.id, 10),
        code,
      },
      onCompleted: (data) => {
        const { status, message } = data.createUserCoinWallet;
        if (status === 'fail') {
          openModal(modals.centerOneBtnModal, {
            title: t('common.info'),
            message: message || t('auth.link.incorrect'),
          });
        }
        if (status === 'success') {
          openModal(modals.dialog, {
            title: t('common.info'),
            message,
            callback: () => {
              navigate(-1);
            },
          });
        }
      },
    });
  };

  if (loading) return <Spinner isLoading={loading} />;

  return (
    <Container>
      <Box>
        <img src={state.image?.url || Icons.defaultLogo} alt="" />

        <Title>
          {locale === 'KOREAN' ? `${state.name}` : `${state.name_en}`}
          <br />
          {t('wallet.External.title')}
        </Title>

        {state.id === '4' && (
          <Text>
            {t('wallet.External.Daemdaem.comment1')} <b>{t('wallet.External.commentCode')}</b>{' '}
            {t('wallet.External.Daemdaem.comment2')}
          </Text>
        )}
      </Box>

      <CodeWrapper>
        <CustomInput
          type="text"
          placeholder={t('wallet.External.inputPlaceholder')}
          onChange={handleCode}
        />

        <FormButton
          text={t('common.confirm')}
          color="black"
          onClick={submitCode}
          disabled={code.length === 0}
        />
      </CodeWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 28px 15px 54px;
  width: 100%;

  border-radius: 0px 0px 30px 30px;
  background: #fff;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

  img {
    width: 60px;
    height: 60px;

    margin-bottom: 22px;
  }
`;

const Title = styled.span`
  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 28px;
  font-weight: 500;

  margin-bottom: 18px;
`;

const Text = styled.span`
  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;

  & b {
    font-weight: 500;
  }
`;

const CodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  flex: 1;
  width: 73%;
  max-width: 380px;

  margin-top: 60px;
  margin-bottom: 60px;

  input::placeholder {
    text-align: center;
  }
`;
