import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icons, Images } from '@assets/images';
import { useMutation } from '@apollo/client';
import cn from 'classnames';

import { modals } from '@views/shared/modal/Modals';
import WebText from '@views/pages/web/WebText.json';
import Spinner from '@/views/shared/spinner/Spinner';
import { RESEND_VERIFY_EMAIL, VERIFY_EMAIL } from '@/graphql/Users';
import useModals from '@/hooks/useModal';
import { B_COLOR, TEXT_GRAY } from '@/constants';

export default function EmailVerify() {
  const { openModal } = useModals();
  const [authPass, setAuthPass] = useState(false);
  const [showResent, setShowResent] = useState(false);
  const [userMail, setUserMail] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const locale =
    new URLSearchParams(window.location.search).get('locale') ||
    (navigator.language === 'ko-KR' ? 'KOREAN' : 'ENGLISH');

  const [resendVerifyEmail, { data: resendMailData }] = useMutation(RESEND_VERIFY_EMAIL, {
    onCompleted: (data) => {
      openModal(modals.dialog, {
        title: WebText.common.info[locale],
        message: data.resendVerifyEmail.message,
      });
    },
    onError: (error) => {
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      openModal(modals.dialog, {
        title: WebText.common.error[locale],
        message: errorMsg,
      });
    },
  });

  const [verifyEmail, { loading: mailLoading }] = useMutation(VERIFY_EMAIL, {
    onCompleted: (data) => {
      setAuthPass(true);
      setStatus(WebText.common.info[locale]);
      setMessage(data.verifyEmail.message);
    },
    onError: (error) => {
      const graphQLError = error.graphQLErrors[0];
      setAuthPass(false);
      setUserMail(graphQLError?.extensions.validation.email);
      setStatus(WebText.common.error[locale]);
      setMessage(graphQLError?.extensions.validation.token);

      if (graphQLError?.extensions.validation.status === 'fail') {
        setShowResent(true);
      }
    },
  });

  const handleResendMail = () => {
    resendVerifyEmail({
      variables: {
        email: userMail,
      },
    });
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    verifyEmail({
      variables: {
        token,
      },
    });
  }, []);

  if (mailLoading) return <Spinner isLoading={mailLoading} />;

  return (
    <Container>
      <img src={Images.tmoWalletLogo} alt="logo" className="logo" />

      <article>
        <div className={cn('info', { success: authPass })}>
          {authPass ? (
            <>
              <img src={Images.emailSuccess} alt="" className="email" />
              <div className="status">{WebText.emailVerify.successStatus[locale]}</div>
              <h1>{message}</h1>
              <p>{WebText.emailVerify.successText[locale]}</p>
            </>
          ) : (
            <>
              <img src={Images.emailFail} alt="" className="email" />
              <div className="status">{WebText.emailVerify.failStatus[locale]}</div>
              <h1>{message}</h1>
              <p>
                {showResent
                  ? WebText.emailVerify.failTextResend[locale]
                  : WebText.emailVerify.failText[locale]}
              </p>
              {showResent && (
                <div className="button">
                  <button type="button" onClick={handleResendMail}>
                    {WebText.emailVerify.failResend[locale]}
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </article>
      <footer>© TMO Wallet. All rights reserved.</footer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 30px;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);

  .logo {
    width: 120px;
    height: auto;
  }

  .email {
    width: 92px;
    height: auto;
  }

  article {
    display: flex;
    overflow: auto;
    flex: 1;
    align-items: center;
    padding: 15px 0;
    font-family: 'Spoqa Han Sans Neo';

    .info {
      color: ${B_COLOR};
      .status {
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;

        margin-top: 35px;
      }

      h1 {
        margin-top: 8px;
        font-size: 40px;
        line-height: 1.25em;
        font-weight: 500;
      }

      p {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        white-space: pre-wrap;
      }

      .button {
        margin-top: 120px;
        button {
          padding: 0 30px;
          height: 45px;
          font-size: 15px;
          color: #fff;
          border-radius: 45px;
          background: ${B_COLOR};
        }
      }
    }
  }
  footer {
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 12px;
    font-weight: 400;
  }
`;
