import { createGlobalStyle } from 'styled-components';
import SpoqaHanSansNeo_Bold from './fonts/SpoqaHanSansNeo-Bold.ttf';
import SpoqaHanSansNeo_Light from './fonts/SpoqaHanSansNeo-Light.ttf';
import SpoqaHanSansNeo_Medium from './fonts/SpoqaHanSansNeo-Medium.ttf';
import SpoqaHanSansNeo_Regular from './fonts/SpoqaHanSansNeo-Regular.ttf';
import SpoqaHanSansNeo_Thin from './fonts/SpoqaHanSansNeo-Thin.ttf';
import { B_COLOR, PRI_COLOR, W_COLOR } from '@/constants';

export const GlobalStyle = createGlobalStyle`
 
  @font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 100;
    font-style: normal;
    src: url(${SpoqaHanSansNeo_Thin}) format("truetype");
  }
  
  @font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 300;
    font-style: normal;
    src: url(${SpoqaHanSansNeo_Light}) format("truetype");
  }
  
  @font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 400;
    font-style: normal;
    src: url(${SpoqaHanSansNeo_Regular}) format("truetype");
  }
  
  @font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 500;
    font-style: normal;
    src: url(${SpoqaHanSansNeo_Medium}) format("truetype");
  }
  
  @font-face {
    font-family: "Spoqa Han Sans Neo";
    font-weight: 700;
    font-style: normal;
    src: url(${SpoqaHanSansNeo_Bold}) format("truetype");
  }
  

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
	
	html, body, #root {
		height: 100%;
		min-width: 360px;
    color: ${B_COLOR};

	}

  *:focus {
    outline: none;  
  } 

  body {
    line-height: 1.5;
    word-break: keep-all;
    font-family : "Spoqa Han Sans Neo", monospace;

    // 롱프레스시 이벤트 방지
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-drag: none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  img {
    max-width: 100%;
    vertical-align: top;

    // 이미지 드래그 방지
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  
  a {
    color: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color:transparent;
  }
  
  *, *::before, *::after {
    box-sizing: border-box;
    font-family : "Spoqa Han Sans Neo", monospace;
  }
	


  /* Form */
  input::-moz-focus-inner {
    margin-top: -2px;
    margin-bottom: -2px;
    padding: 0;
    border: 0;
  }

  button::-moz-focus-inner {
    margin-top: -2px;
    margin-bottom: -2px;
    padding: 0;
    border: 0;
  }

  :-moz-submit-invalid {
    box-shadow: none;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }

  ::-webkit-input-placeholder {
    font-weight: 400;
    color: #99989a;
  }

  ::-moz-placeholder {
    font-weight: 400;
    color: #99989a;
  }

  :-ms-input-placeholder {
    font-weight: 400;
    color: #99989a;
  }

  :-moz-placeholder {
    font-weight: 400;
    color: #99989a;
  }

  :-webkit-contacts-auto-fill-button,
  :-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
  }
	

	
	button{
    border:none; 
    box-shadow:none;
    border-radius:0;
    padding:0;
    overflow:visible;
    cursor:pointer;
    background: transparent;
  }
 
  button:focus {
    outline: none;
  }

  textarea {
    resize: none;
		width: 100%;
		height: 180px;
		padding: 25px;
		font-weight: 500;
		font-size: 16px;
		color: ${B_COLOR};
		background: #f2f1f8;
    border: 1px solid #f2f1f8;
		border-radius: 15px;
		line-height: 1.75em;
  }

  input[type='text']:focus,
  input[type='password']:focus,
  input[type='email']:focus,
	textarea:focus {
		
	}

  strong {
    font-weight: 700;
  }

  /* Modal */
	.deemed {
		position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999999999;



    // 바텀 모달
		.modal {
			display: flex;
			flex-direction: column;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			min-height: 350px;
			max-height: 80vh;
			background: #fff;
      box-shadow: -5px 3px 30px rgba(8, 6, 57, 0.15);
      border-radius: 40px 40px 0px 0px;
			font-size: 15px;

			.title {
				font-size: 25px;
				font-weight: 500;
				color: ${B_COLOR};
				margin-bottom: 25px;
				line-height: 1.2em;
			}

			.msg {
				font-size: 15px;
				line-height: 25px;
        color: ${B_COLOR};
        white-space: pre-wrap;
        flex: 1;
        margin-bottom: 25px;
			}

			.button_wrap {
				padding-bottom: 0;
			}

			.close {
				position: absolute;
				right: 36px;
				top: 32px;
				width: 14px;
				height: 14px;
				text-indent: -9999px;
				border: 0;
				background: none;

				&:before {
					content: "";
					position: absolute;
					width: 20px;
					height: 2px;
					left: 50%;
					top: 50%;
					margin-top: -1px;
					background: #000;
					transform: rotate(45deg);
				}

        &:after {
          content: "";
          position: absolute;
          width: 20px;
          height: 2px;
          left: 50%;
          top: 50%;
          margin-top: -1px;
          background: #000;
          transform: rotate(-45deg);
        }
			}
		}

    // 중앙 모달
    .center-modal {
      display: flex;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      max-width: 400px;
      border-radius: 30px;
      background: ${W_COLOR};
      box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
      min-height: 220px;
    }    
	}
`;
