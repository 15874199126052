import React from 'react';
import styled from 'styled-components';
import { modals } from '@views/shared/modal/Modals';
import { useSelector } from 'react-redux';
import useModals from '@/hooks/useModal';
import FormatAmount from '@/utils/formatAmount';
import { TEXT_GRAY } from '@/constants';

function RewardListItem({ item }) {
  const { openModal } = useModals();
  const { locale } = useSelector((state) => state.user);
  const nameByLocale = locale === 'ENGLISH' ? 'name_en' : 'name';

  const showSwapDetail = (data) => {
    openModal(modals.tokenRewardDetail, {
      data,
    });
  };
  return (
    <ListItemContainer>
      <div className="swap_info" role="button" tabIndex="0" onClick={() => showSwapDetail(item)}>
        <div className="token">
          <img src={item.coinReward.coin.image.url} alt="" width="24px" height="24px" />
          <div className="swap_data">{item.coinReward.coin[nameByLocale]}</div>
        </div>
        <div className="income">
          <div>
            + {FormatAmount(item.amount)} {item.coinReward.coin.unit.toUpperCase()}
          </div>
          <div className="date">{item.created_at}</div>
        </div>
      </div>
    </ListItemContainer>
  );
}

export default RewardListItem;

const ListItemContainer = styled('li')`
  margin-bottom: 25px;

  .swap_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    width: 100%;

    .token {
      flex-shrink: 0;
      display: flex;
      gap: 6px;

      .swap_data {
        display: flex;
        align-items: center;
      }
    }

    .income {
      text-align: right;
    }

    .date {
      font-size: 12px;
      color: ${TEXT_GRAY};
    }
  }
`;
