import { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icons } from '@assets/images';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import History from '@views/pages/token/history';
import {
  GET_BALANCE,
  MY_COIN_TRANSFERS_BY_COIN_ID,
  MY_COIN_SWAPS_BY_COIN_ID,
} from '@/graphql/Coins';
import { setSendCoin } from '@/redux/reducer/walletSlice';
import { pagePaths } from '@/navigation/Pages';
import formatAmount from '@/utils/formatAmount';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR } from '@/constants';

export default function Token() {
  const { state } = useLocation();
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const coin = useSelector((_state) =>
    _state.wallet.coins.find((coin) => coin.id === state.coinId),
  );

  // const {
  //   data: transactionData,
  //   loading: transactionLoading,
  //   error: transactionError,
  //   fetchMore: transactionFetchMore,
  // } = useQuery(MY_COIN_TRANSFERS_BY_COIN_ID, {
  //   variables: {
  //     coin_id: Number(state.coinId),
  //     page: 1,
  //   },
  // });

  // const {
  //   data: swapData,
  //   loading: swapLoading,
  //   error: swapError,
  //   fetchMore: swapFetchMore,
  // } = useQuery(MY_COIN_SWAPS_BY_COIN_ID, {
  //   variables: {
  //     coin_id: Number(state.coinId),
  //     page: 1,
  //   },
  // });
  const [getBalance, { data: infoData, loading: infoLoading, error: infoError }] =
    useLazyQuery(GET_BALANCE);

  // const transactionFetchMoreData = (currentPage) => {
  //   transactionFetchMore({
  //     variables: {
  //       page: currentPage,
  //     },
  //     updateQuery: (prev, { fetchMoreResult }) => {
  //       const combinedData = [
  //         ...prev.myCoinTransactionsByCoinId.data,
  //         ...fetchMoreResult.myCoinTransactionsByCoinId.data,
  //       ];
  //       return {
  //         myCoinTransactionsByCoinId: {
  //           ...fetchMoreResult.myCoinTransactionsByCoinId,
  //           data: combinedData,
  //         },
  //       };
  //     },
  //   });
  // };

  // const swapFetchMoreData = (currentPage) => {
  //   swapFetchMore({
  //     variables: {
  //       page: currentPage,
  //     },
  //     updateQuery: (prev, { fetchMoreResult }) => {
  //       const combinedData = [
  //         ...prev.myCoinSwapsByCoinId.data,
  //         ...fetchMoreResult.myCoinSwapsByCoinId.data,
  //       ];
  //       return {
  //         myCoinSwapsByCoinId: {
  //           ...fetchMoreResult.myCoinSwapsByCoinId,
  //           data: combinedData,
  //         },
  //       };
  //     },
  //   });
  // };
  useEffect(() => {
    getBalance({
      variables: {
        coin_id: state.coinId,
      },
    });
    dispatch(setSendCoin(coin));
  }, [coin, dispatch, getBalance, state.coinId]);

  // if (infoLoading || transactionLoading || swapLoading)
  //   return <Spinner isLoading={infoLoading || transactionLoading || swapLoading} />;
  // if (infoError) {
  //   navigate(pagePaths.ERROR, { state: { error: infoError.message } });
  //   return null;
  // }
  // if (transactionError) {
  //   navigate(pagePaths.ERROR, { state: { error: transactionError.message } });
  //   return null;
  // }
  // if (swapError) {
  //   navigate(pagePaths.ERROR, { state: { error: swapError.message } });
  //   return null;
  // }

  return (
    <Container>
      <CoinInfo>
        <img src={coin.image?.url || Icons.defaultLogo2} alt="" />
        <div className="name">
          {coin.name_en} | {coin.unit}
        </div>
        <div className="amount">
          {infoData?.getBalance?.amount ? formatAmount(infoData.getBalance.amount) : 0}
        </div>
      </CoinInfo>
      <LinkContainer>
        {!!coin.is_transaction && (
          <LinkItem
            to={`/wallet/token/${coin.acronym}/send`}
            state={{
              coinId: coin.id,
              coinUnit: coin.acronym,
              coinBalance: infoData?.getBalance.amount,
              coinFee: coin.coinNetworkInfo.network_fee,
              coinFeeUnit: coin.coinNetworkInfo.network,
              coinPrice: coin.coinCurrencyPrices,
            }}
          >
            <img src={Icons.sendBtn} alt="" />
            <div>{t('token.menu.send')}</div>
          </LinkItem>
        )}
        {!!coin.is_transaction && (
          <LinkItem
            to={`/wallet/${coin.acronym}/receive`}
            state={{
              coinId: coin.id,
              coinUnit: coin.acronym,
              coinBalance: infoData?.getBalance.amount,
            }}
          >
            <img src={Icons.receiveBtn} alt="" />
            <div>{t('token.menu.receive')}</div>
          </LinkItem>
        )}
        {(!!coin.is_swap_export || !!coin.is_swap_import) && (
          <LinkItem to="/swap">
            <img src={Icons.swapBtn} alt="" />
            <div>{t('token.menu.swap')}</div>
          </LinkItem>
        )}
      </LinkContainer>

      <History
        setSend={!!coin.is_transaction}
        setSwap={!!coin.is_swap_export || !!coin.is_swap_import}
        coinId={state.coinId}
        // transactionData={transactionData?.myCoinTransactionsByCoinId?.data}
        // swapData={swapData?.myCoinSwapsByCoinId?.data}
        // transactionFetchMoreData={transactionFetchMoreData}
        // swapFetchMoreData={swapFetchMoreData}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 40px;
`;

const CoinInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 8px;
  }

  .name {
    font-size: 14px;
    font-weight: 400;

    margin-bottom: 10px;
  }

  .amount {
    font-size: 24px;
    font-weight: 700;
  }

  margin-bottom: 30px;
`;

const LinkItem = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
`;
