import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { modals } from '@views/shared/modal/Modals';
import Trnclist from '@views/pages/transaction/components/Trnclist';
import { RETRIEVE_LIST } from '@/graphql/Trans';
import { retrieveAdd, retrieve, resetList, setSearchToken } from '@/redux/reducer/trancSlice';
import useModals from '@/hooks/useModal';
import { pagePaths } from '@/navigation/Pages';
import ErrorMessages from '@/constants/errors';
import { Icons } from '@/assets/images';
import { B_COLOR, LIGHT_COLOR } from '@/constants';

// 전송 이력
export default function Transaction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal } = useModals();

  const transaction = useSelector((state) => state.transaction);

  // 페이지 진입시 실행되는 gql hook
  const { fetchMore } = useQuery(RETRIEVE_LIST, {
    variables: {
      coin_id: 0,
      page: 10,
      perPage: transaction.paginatorInfo.perPage,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      dispatch(retrieve(data.myCoinTransactions.data));
    },
    onError: (error) => {
      if (error.message !== ErrorMessages.AUTHORIZATION_ERROR) {
        navigate(pagePaths.ERROR);
      }
    },
  });

  const [getFilteredList] = useLazyQuery(RETRIEVE_LIST, {
    onCompleted: (data) => {
      dispatch(retrieve(data.myCoinTransactions.data));
    },
    onError: (error) => {
      if (error.message !== ErrorMessages.AUTHORIZATION_ERROR) {
        navigate(pagePaths.ERROR);
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleClickFilter = (data) => {
    openModal(modals.transactionFilter, {
      onSubmit: (values) => {
        getFilteredList({
          variables: {
            coin_id: values.token !== 0 ? parseInt(values.token, 10) : 0,
            page: 1,
            perPage: 10,
          },
          onCompleted: ({ myCoinTransactions }) => {
            dispatch(resetList());
            dispatch(setSearchToken(values));
            dispatch(retrieve(myCoinTransactions.data));
          },
        });
      },
    });
  };

  const fetchMoreMore = (currentPages) => {
    fetchMore({
      variables: {
        coin_id: parseInt(transaction.searchToken.token, 10),
        page: currentPages,
        perPage: 10,
      },
    }).then((data) => {
      dispatch(retrieveAdd(data.data.myCoinTransactions.data));
    });
  };

  return (
    <Container>
      <BtnsWrapper>
        <Button>
          <span>최근 6개월</span>
          <img src={Icons.downPolygon} alt="" />
        </Button>
        <Button>
          <span>전체 토큰</span>
          <img src={Icons.downPolygon} alt="" />
        </Button>
      </BtnsWrapper>
      {/* <button className="filter" type="button" name="selectedToken" onClick={handleClickFilter}>
        {transaction.selectedToken.name || 'Token All'}
        <ChevronDown size={14} />
      </button> */}

      <Trnclist datalist={transaction.trncList} fetchMore={fetchMoreMore} />
    </Container>
  );
}

const Container = styled.div`
  padding: 16px 15px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const BtnsWrapper = styled.div`
  display: flex;
  gap: 5%;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  max-width: 190px;
  justify-content: center;
  height: 50px;

  border-radius: 15px;
  border: 1px solid ${LIGHT_COLOR};

  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;
`;
