import { useSelector } from 'react-redux';

import TokenBalance from '@views/pages/swap/components/point/components/TokenBalance';
import { isInclude } from '@/utils';

export default function PointStatus({ type }) {
  const { importList, exportList, filterKeyword } = useSelector((state) => state.swap);

  if (type === 'import') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {importList?.length > 0 &&
          importList
            .filter(
              (item) =>
                isInclude(item.exportCoin.name, filterKeyword) ||
                isInclude(item.exportCoin.name_en, filterKeyword),
            )
            .map((item, index) => (
              <TokenBalance item={item} key={`${item.id}-${index}`} type={type} />
            ))}
      </div>
    );
  }

  if (type === 'export') {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {exportList?.length > 0 &&
          exportList
            .filter(
              (item) =>
                isInclude(item.importCoin.name, filterKeyword) ||
                isInclude(item.importCoin.name_en, filterKeyword),
            )
            .map((item, index) => (
              <TokenBalance item={item} key={`${item.id}-${index}`} type={type} />
            ))}
      </div>
    );
  }

  return null;
}
