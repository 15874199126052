import styled from 'styled-components';
import { B_COLOR, G_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';

export default function Switch({ id, checked, onChange }) {
  return (
    <Container>
      <input type="checkbox" checked={checked} id={id} onChange={onChange} />
      <label htmlFor={id} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: 52px;
  height: 100%;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    & + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 32px;
      background: ${G_COLOR};
      border-radius: 18px;
      transition: all 0.3s;
    }
    &:checked + label:before {
      background: ${B_COLOR};
    }
    & + label:after {
      content: '';
      position: absolute;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background: ${W_COLOR};
      transition: all 0.3s;
    }
    &:checked + label:after {
      left: 21px;
      background: ${W_COLOR};
    }
  }
`;
