import styled from 'styled-components';

import Progress from '@views/shared/progress';
import { B_COLOR } from '@/constants';

export default function PageInfo({ progress, category, title, subTxt }) {
  return (
    <Container>
      {progress && <Progress current={progress.current} total={progress.total} />}
      {category && <span className="category">{category}</span>}
      {title && <h1>{title}</h1>}
      {subTxt && <p>{subTxt}</p>}
    </Container>
  );
}

const Container = styled.div`
  .category {
    display: block;
    margin-top: 25px;
    font-size: 18px;
    line-height: 21px;
    color: #523ee8;
    margin-bottom: 0.25em;
  }

  h1 {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 28px;
    font-weight: 400;
    line-height: 35px;
    white-space: pre-wrap;
  }
  p {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 400;
    line-height: 25px; /* 156.25% */

    margin-top: 20px;
  }
`;
