import moment from 'moment';

export function addSeconds(_time) {
  return moment().add(_time, 'second').format('yyyy-MM-DD HH:mm:ss');
}

// second > min : second
export const formatTime = (time) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

export function checkShowNotice(isActive, startedAt, endedAt) {
  // isActive값 0 이면 안보여줌
  if (isActive < 1) {
    return false;
  }

  // 시작일이 있고 현재 날짜가 시작일 이전인 경우 안보여줌
  if (startedAt && moment(startedAt).diff(moment()) > 0) {
    return false;
  }

  // 종료일이 있고 현재 날짜가 종료일 이후인 경우 안보여줌
  if (endedAt && moment(endedAt).diff(moment()) < 0) {
    return false;
  }

  return true;
}

export function getTimestampFromDate(year, month, day, months) {
  const targetDate = new Date(year, month - 1, day); // 주어진 년, 월, 일로 새로운 Date 객체를 생성합니다.
  targetDate.setMonth(targetDate.getMonth() - months); // 원하는 개월 수를 뺍니다.

  return moment(targetDate).format('YYYY-MM-DD');
}
