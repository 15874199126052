import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import MnemonicFiled from '@/views/shared/forms/MnemonicFiled';
import PageInfo from '@/views/shared/pageInfo';
import { CHECK_PRIVATE_KEY_DECRYPTION_SYNTAX, RESET_PIN } from '@/graphql/Users';
import useModals from '@/hooks/useModal';
import { modals } from '@/views/shared/modal/Modals';
import { useTranslate } from '@/hooks/useTranslate';
import PinInput from '@/views/shared/forms/PinInput';
import { pagePaths } from '@/navigation/Pages';
import { parseGqlErrorMsg } from '@/utils';

const ResetPinByMnemonic = () => {
  const { openModal } = useModals();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const [myPin, setMyPin] = useState(null);
  const [mySyntax, setMySyntax] = useState(null);

  const [showChecksyntax, setShowChecksyntax] = useState(true);
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirmPin, setShowConfirmPin] = useState(false);

  const [checkSyntax, { loading, error }] = useMutation(CHECK_PRIVATE_KEY_DECRYPTION_SYNTAX, {
    onCompleted: ({ checkPrivateKeyDecryptionSyntax }) => {
      if (checkPrivateKeyDecryptionSyntax.status === 'success') {
        setShowChecksyntax(false);
        setShowNewPin(true);
      } else {
        openModal(modals.dialog, {
          title: t('settings.security.findPrivateKey.decryptionFailed'),
          message: checkPrivateKeyDecryptionSyntax.message,
        });
      }
    },
  });

  const [resetPin] = useMutation(RESET_PIN, {
    onCompleted: ({ resetPin }) => {
      if (resetPin.status === 'success') {
        openModal(modals.dialog, {
          title: t('settings.security.changePin.alertSuccess'),
          message: resetPin.message,
          callback: () => {
            navigate(pagePaths.SETTINGS_SECURITY);
          },
        });
      } else {
        openModal(modals.dialog, {
          title: t('settings.security.changePin.alertFail'),
          message: resetPin.message,
        });
      }
    },
    onError: (error) => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: parseGqlErrorMsg(error),
        callback: () => {
          setMyPin(null);
          setShowNewPin(true);
          setShowConfirmPin(false);
        },
      });
    },
  });

  const handleNewPin = (value) => {
    setMyPin(value);
    setShowNewPin(false);
    setShowConfirmPin(true);
  };

  const handleSubmit = (_confirmedPin) => {
    resetPin({
      variables: {
        pin: myPin,
        pin_confirmation: _confirmedPin,
        syntax: mySyntax,
      },
    });
  };

  const onSubmit = (values) => {
    const inputs = document.querySelectorAll('.recover');
    if ([...inputs].some((input) => !input.value.trim())) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('settings.security.findPrivateKey.decryptionFailed'),
      });
      return;
    }
    const syntaxStr = Object.values(values).join(' ');

    setMySyntax(syntaxStr);
    checkSyntax({
      variables: {
        syntax: syntaxStr,
      },
    });
  };

  return (
    <Container>
      {showChecksyntax && (
        <>
          <PageInfo
            title={t('settings.security.resetPin.title')}
            subTxt={t('settings.security.resetPin.subTitle')}
          />
          <MnemonicFiled onSubmit={onSubmit} />
        </>
      )}
      {showNewPin && (
        <PinInput length={6} isPassword placeholder="••••••" onComplete={handleNewPin}>
          <div className="txt_box">
            <div className="tit">{t('settings.security.changePin.new.title')}</div>
            <div className="txt">{t('settings.security.changePin.new.text')}</div>
          </div>
        </PinInput>
      )}
      {showConfirmPin && (
        <PinInput length={6} isPassword placeholder="••••••" onComplete={handleSubmit}>
          <div className="txt_box">
            <div className="tit">{t('settings.security.changePin.confirm.title')}</div>
            <div className="txt">{t('settings.security.changePin.confirm.text')}</div>
          </div>
        </PinInput>
      )}
    </Container>
  );
};

export default ResetPinByMnemonic;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 45px 15px;
`;
