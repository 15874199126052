import { createSlice, createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { Icons } from '@assets/images';
import client from '@/services';
import { GET_DEFAULT_COIN_INFO, MY_COIN_SWAP_LIST } from '@/graphql/Swap';
import { COIN_SWAP_IDS } from '@/graphql/Coins';
import { calcSwapResult } from '@/utils/calc';
import { mockSwapList } from '@/views/pages/swap/swapHistory/mockSwapList';

/** myMutationFunction
 *  GET_TMO_INFO
 *  팬시포인트의 정보를 가져온다.
 * */
// const myTmoPQueryFunction = async () => {
//   const { data } = await client.query({
//     query: GET_DEFAULT_COIN_INFO,
//     fetchPolicy: 'network-only',
//   });
//   return data;
// };

// /** myQueryFunction
//  *  나의 전체 스왑내역 목록을 리턴한다.
//  * */
// const myQueryFunction = async () => {
//   const { data } = await client.query({
//     query: MY_COIN_SWAP_LIST,
//     variables: {
//       coin_swap_id: 0,
//       page: 1,
//     },
//     fetchPolicy: 'network-only',
//   });

//   return data;
// };
// /** 코인 스왑 목록 조회
//  * COIN_SWAP_IDS 의 비동기
//  * */
// const coinSwapIds = async () => {
//   try {
//     const { data } = await client.query({
//       query: COIN_SWAP_IDS,
//     });
//     return data;
//   } catch (error) {
//     console.info(error);
//   }
// };

// /** fetchMyData
//  *  팬시포인트 정보를 가져온다.
//  *
//  * */
// export const fetchMyData = createAsyncThunk('swap/fetchMyData', async () => {
//   try {
//     // UseQueries 함수를 호출하여 mutationResult에 접근합니다.
//     const data = await myTmoPQueryFunction();
//     return data;
//   } catch (error) {
//     return isRejectedWithValue(error.message) ? error.message : '';
//   }
// });

// /** fetchMyDataRetry
//  *  팬시포인트 정보 조회를 재시도한다.
//  * */
// export const fetchMyDataRetry = createAsyncThunk(
//   'swap/fetchMyDataRetry',
//   async (_, { getState }) => {
//     const { status } = getState().swap.fetchMyData;
//     if (status === 'rejected') {
//       return myTmoPQueryFunction();
//     }
//   },
// );

// /** fetchMyQuery
//  *  myQueryFunction
//  * */
// export const fetchMyQuery = createAsyncThunk('swap/fetchMyQuery', async () => {
//   try {
//     const data = await myQueryFunction();
//     return data;
//   } catch (error) {
//     return isRejectedWithValue(error.message) ? error.message : '';
//   }
// });

// export const fetchSwapIds = createAsyncThunk('swap/coinSwapIds', async () => {
//   try {
//     const data = await coinSwapIds();
//     return data;
//   } catch (error) {
//     return isRejectedWithValue(error.message) ? error.message : '';
//   }
// });

// 초기값
const initialState = {
  initSet: [], // coinExchange 정보를 받는다 ( 데이터 가공을 위함)
  myWallets: [], // 지갑 정보
  default_img: Icons.defaultTmopLogo,
  TMOP: 0,
  TMOP_INFO: null,
  importList: [],
  exportList: [],
  loading: false,
  qloading: false,
  sloading: false,
  importCoin: {},
  importCoinBalance: 0,
  exportCoinBalance: 0,
  exportCoin: {},
  clickedImportCoin: {},
  exportCoinSwapInfo: [], // 내보내기 스왑정보들의 배열
  importCoinSwapInfo: [], // 가져오기 스왑정보들의 배열
  selectedExportCoinSwapInfo: {}, // 선택된 내보내기 스왑 정보 객체
  selectedImportCoinSwapInfo: {}, // 선택된 가져오기 스왑 정보 객체
  selectedSwapInfo: {},
  exportAmount: 0, // 내보내기 하는 금액
  importAmount: 0, // 받는 금액
  swapType: '', // 스왑 타입 (import, export)
  fetchMyData: '', //
  coinSwapInfos: [],
  swapRetrieveList: [],
  selectedId: 0,
  isChanged: 1,
  coinSwapId: '',
  fee: 0,
  finalFee: 0,
  rewardAmount: 0,
  filterKeyword: '',
};

export const swapSlice = createSlice({
  name: 'swap',
  initialState,
  reducers: {
    setDefaultCoinInfo: (state, action) => {
      state.TMOP = action.payload.amount;
      state.TMOP_INFO = action.payload;
    },
    // 페이지에 진입했을 때
    whenPageInit: (state, action) => {
      const swapInfos = action.payload;
      state.exportList = swapInfos.exportCoinSwaps;
      state.importList = swapInfos.importCoinSwaps;
      state.rewardAmount = initialState.rewardAmount;
    },
    // 내보내기 리스트를 화살표를 클릭했을 때
    whenExportListClicked: (state, action) => {
      const { balance, swapInfo } = action.payload;
      state.selectedSwapInfo = swapInfo;
      state.exportCoin = swapInfo.exportCoin;
      state.importCoin = swapInfo.importCoin;
      state.swapType = swapInfo.type;
      state.coinSwapId = swapInfo.id;
      state.importCoinBalance = balance;
      state.exportCoinBalance = state.TMOP;
    },
    // 가져오기 리스트에서 화살표를 클릭했을 때
    whenImportListClicked: (state, action) => {
      const { balance, swapInfo } = action.payload;
      state.selectedSwapInfo = swapInfo;
      state.exportCoin = swapInfo.exportCoin;
      state.importCoin = swapInfo.importCoin;
      state.swapType = swapInfo.type;
      state.coinSwapId = swapInfo.id;
      state.exportCoinBalance = balance;
      state.importCoinBalance = state.TMOP;
    },
    /*  입력수량 변경시 */
    whenChangeSwapAmount: (state, action) => {
      const { selectedSwapInfo, swapAmount } = action.payload;
      const { fee, reward, import_amount } = calcSwapResult(selectedSwapInfo, Number(swapAmount));

      state.exportAmount = swapAmount;
      state.importAmount = import_amount;
      state.fee = fee;
      state.rewardAmount = reward;
    },
    // 내역 페이지에서 스크롤이 바닥을 찍을 때
    whenScrollTouchBottom: (state, action) => {
      state.swapRetrieveList = [...state.swapRetrieveList, ...action.payload.swapRetrieveList];
      state.selectedId = action.payload.swapId;
    },
    // 내역페이지에서 셀렉트 박스가 바뀔 때
    whenSelectBoxChanged: (state, action) => {
      // 리스트 초기화
      state.swapRetrieveList = initialState.swapRetrieveList;
      // 새 배열을 집어넣는다.
      state.swapRetrieveList = action.payload.swapRetrieveList;
      // 선택된 코인,포인트의 아이디를 세팅한다.
      state.selectedId = action.payload.swapId;
      state.isChanged = true;
    },
    whenSwapListPageInit: (state, action) => {
      // 선택된 코인 밸류 리셋
      state.selectedId = initialState.selectedId;
      // 초기 배열을 넣어줌
      state.swapRetrieveList = action.payload.list;
    },
    // 스왑 폼에서 새로고침 버튼을 클릭했을 때
    whenRefreshButtonClicked: (state, action) => {
      if (state.swapType === 'export') {
        if (action.payload.type === 'export') {
          state.exportCoinBalance = action.payload.amount;
          state.TMOP = action.payload.amount;
        } else if (action.payload.type === 'import') {
          state.importCoinBalance = action.payload.amount;
        }
      } else if (state.swapType === 'import') {
        if (action.payload.type === 'export') state.exportCoinBalance = action.payload.amount;
        else if (action.payload.type === 'import') state.importCoinBalance = action.payload.amount;
      }
    },
    // 스왑폼 input 값 reset 버튼을 눌렀을 때
    whenResetButtonClicked: (state) => {
      state.exportAmount = initialState.exportAmount;
      state.importAmount = initialState.exportAmount;
      state.fee = initialState.fee;
      state.rewardAmount = initialState.rewardAmount;
    },
    setFilterKeyword: (state, action) => {
      state.filterKeyword = action.payload;
    },
    resetSwapSlice: (state) => initialState,
  },
  extraReducers: {
    // 비동기 작업의 상태를 처리하는 리듀서 함수를 생성합니다.
    // [fetchMyData.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [fetchMyData.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   if (action.payload) {
    //     state.TMOP = action.payload.getBalanceByDefaultCoin.amount;
    //     state.TMOP_INFO = action.payload.getBalanceByDefaultCoin;
    //   }
    // },
    // [fetchMyData.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.fetchMyData = 'fail';
    // },
    // // 재시도 로직
    // [fetchMyDataRetry.fulfilled]: (state, action) => {
    //   state.fetchMyData = { status: 'succeeded', data: action.payload };
    // },
    // // fetchMyQuery
    // [fetchMyQuery.pending]: (state, action) => {
    //   state.qloading = true;
    // },
    // [fetchMyQuery.fulfilled]: (state, action) => {
    //   state.qloading = false;
    //   state.swapRetrieveList = action.payload.myCoinSwaps.data;
    // },
    // [fetchMyQuery.rejected]: (state, action) => {
    //   state.qloading = false;
    // },
    // // 코인 스왑 목록
    // [fetchSwapIds.pending]: (state, action) => {
    //   state.sloading = true;
    // },
    // [fetchSwapIds.fulfilled]: (state, action) => {
    //   state.sloading = false;
    //   state.coinSwapInfos = action.payload.coinSwaps.data;
    // },
    // [fetchSwapIds.rejected]: (state, action) => {
    //   state.sloading = false;
    // },
  },
});

export const {
  resetSwapSlice,
  whenSelectBoxChanged,
  whenScrollTouchBottom,
  whenSwapListPageInit,
  setFilterKeyword,
  setDefaultCoinInfo,
} = swapSlice.actions;

export default swapSlice;
