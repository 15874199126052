import styled, { css } from 'styled-components';
import _ from 'lodash';
import { LIGHT_COLOR, TEXT_GRAY, B_COLOR, W_COLOR } from '@/constants';

export default function TabButtons({ tabs, active, onClickTabButton, className }) {
  return (
    <Container className={className}>
      {tabs.map((info) => (
        <Button
          key={info.key}
          isActive={active === info.key}
          onClick={() => onClickTabButton(info.key)}
        >
          {info.title}
        </Button>
      ))}
    </Container>
  );
}

const Container = styled.div`
  background-color: ${W_COLOR};
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  border-radius: 15px;
  gap: 12px;
`;

const Button = styled.div`
  background-color: ${({ isActive }) => (isActive ? LIGHT_COLOR : 'none')};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  border-radius: 10px;
  cursor: pointer;

  color: ${TEXT_GRAY};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  // test
  // :active {
  //   background-color: ${LIGHT_COLOR};
  // }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${LIGHT_COLOR};
      color: ${B_COLOR};
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Spoqa Han Sans Neo;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    `};
`;
