import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import Header from '@views/shared/header';
import Menu from '@views/shared/menu';
import { BG_COLOR } from '@/constants';

export default function Layout({ header, menu }) {
  return (
    <Container>
      {header && <Header />}
      <main>
        <Outlet />
      </main>
      {menu && <Menu />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${BG_COLOR};

  main {
    overflow: auto;
    flex: 1;
  }
`;
