import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Icons, Images } from '@assets/images';
import axios from 'axios';
import { useTranslate } from '@/hooks/useTranslate';
import { initializeLocale, convertDateTimeFormat } from '@/utils/locale';
import { systemCheckS3Url } from '@/services/config';
import { BG_COLOR, B_COLOR, G_COLOR, PRI_COLOR } from '@/constants';

function SystemCheck() {
  const { t } = useTranslate();
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const locale = initializeLocale();

  const checkDateRange = useMemo(
    () => convertDateTimeFormat(startTime, endTime, locale),
    [startTime, endTime, locale],
  );

  // 네트워크 에러가 아닌 ip체크에 의한 점검인 경우 네트워크상태 확인 불필요
  // 시스템 점검유무 지속 체크
  // useEffect(() => {
  //   const checkSystem = () => {
  //     axios
  //       .get(process.env.REACT_APP_API_URL)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           setSystemCheck(false);
  //           window.location.reload();
  //         }
  //       })
  //       .catch((err) => console.error(err));
  //   };

  //   const interval = setInterval(checkSystem, 300000);
  //   checkSystem();

  //   return () => clearInterval(interval);
  // }, []);

  // 네트워크 에러가 아닌 ip체크에 의한 점검인 경우 네트워크상태 확인 불필요
  // 당일 점검일정 불러오기
  useEffect(() => {
    axios
      .get(systemCheckS3Url())
      .then(({ data }) => {
        setStartTime(data.started_at);
        setEndTime(data.ended_at);
      })
      .catch((err) => console.error(err));
  }, []);

  return (
    <Container>
      <Content>
        <img src={Images.systemCheck} alt="logo" className="text_logo" />
        <span className="title">{t('system.check.title')}</span>
        <div className="text">{t('system.check.text')}</div>
      </Content>
      <Detail>
        <div>
          <span>{t('system.check.info.date')} :</span>
          <span className="date">3월 15일(금) 09:00 ~ 13:00 (4시간)</span>
        </div>
        <div>
          <span>{t('system.check.info.reason')} :</span>
          <span>DB 점검 작업을 위한 서비스 중단</span>
        </div>
        <div className="comment">※ {t('system.check.comment')}</div>
      </Detail>
    </Container>
  );
}

export default SystemCheck;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${BG_COLOR};
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  img {
    margin-bottom: 30px;
  }

  & .title {
    margin-bottom: 30px;
    color: ${B_COLOR};
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
  }

  & .text {
    white-space: pre-line;
    text-align: center;
    color: ${B_COLOR};
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0 60px;
  gap: 3px;

  font-family: 'Spoqa Han Sans Neo';
  font-size: 13px;
  font-weight: 400;
  line-height: 23px; /* 176.923% */

  border-top: 1px solid ${G_COLOR};

  .date {
    color: ${PRI_COLOR};
  }

  .comment {
    margin-top: 15px;
  }
`;
