import React from 'react';
import { useNavigate } from 'react-router-dom';
import { modals } from '@views/shared/modal/Modals';
import useModals from '@/hooks/useModal';
import { useTranslate } from '@/hooks/useTranslate';
import { pagePaths } from '@/navigation/Pages';
import { Icons } from '@/assets/images';
import { ListItem } from '../personal';

function ReissueWallet() {
  const { openModal } = useModals();
  const navigate = useNavigate();
  const { t } = useTranslate();

  // 재발급 이전 인증메일 전송
  // 기존 기기에 지갑 보유자에 한해서 인증절차 > EmailVerifyForm > reissueWallet
  // const [sendVerifyEmail, { loading }] = useMutation(VERIFY_REISSUE_WALLET_EMAIL, {
  //   onCompleted: ({ sendEthereumWalletGenerateVerifyEmail }) => {
  //     if (sendEthereumWalletGenerateVerifyEmail.status === 'success') {
  //       openModal(modals.dialog, {
  //         title: t('common.info'),
  //         message: sendEthereumWalletGenerateVerifyEmail.message,
  //       });
  //       navigate(pagePaths.VERIFY_EMAIL_WALLET_REISSUE);
  //     }
  //   },

  //   onError: (error) => {
  //     openModal(modals.dialog, {
  //       title: t('common.error'),
  //       message: error.message,
  //     });
  //   },
  // });

  // if (loading) return <Spinner isLoading={loading} />;

  return (
    <ListItem
      onClick={() => {
        openModal(modals.confirm, {
          title: t('common.info'),
          message: t('settings.security.reissueWallet.confirm'),
          callback: () => {
            // sendVerifyEmail();
            navigate(pagePaths.REISSUE_WALLET_CHECK_PIN, {
              state: {
                from: 'reissue_wallet',
              },
            });
          },
        });
      }}
    >
      <span>{t('settings.security.reissueWallet')}</span>
      <img src={Icons.detail} alt="" />
    </ListItem>
  );
}

export default ReissueWallet;
