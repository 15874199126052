import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import FormButton from '@views/shared/forms/FormButton';
import FormButtonWrap from '@views/shared/forms/FormButtonWrap';
import { Images } from '@assets/images';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, PRI_COLOR } from '@/constants';

export default function Error() {
  const { state } = useLocation();
  const { t } = useTranslate();

  return (
    <Container>
      <Content>
        <img src={Images.error} className="logo" alt="error" />

        {state?.error && <h2>{state?.error}</h2>}
        <div className="txt">
          <div>{t('error.supportComment1')}</div>
          <div>{t('error.supportComment2')}</div>
        </div>
        <a className="mail" href="mailto:support@tmolabs.io">
          {t('error.supportEmail')}
        </a>
      </Content>
      <BtnWrapper>
        <FormButton
          link={state?.link || pagePaths.LOGIN}
          text={t('error.backButton')}
          color="black"
          flex={1}
        />
      </BtnWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 52px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-family: 'Spoqa Han Sans Neo';

  img {
    width: 96px;
    height: auto;

    margin-bottom: 60px;
  }

  h2 {
    color: ${B_COLOR};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;

    margin-bottom: 20px;
  }

  .txt {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;

    margin-bottom: 20px;
  }

  .mail {
    color: ${PRI_COLOR};
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
  }
`;

const BtnWrapper = styled.div``;
// "error.supportComment1": "Please try again.",
// "error.supportComment2": "If the issue persists, please contact the administrator.",
// "error.supportEmail": "support@tmolabs.io",
// "error.backButton": "Go back to the beginning",
