import { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import styled, { css } from 'styled-components';

import SearchInput from '@views/shared/forms/SearchInput';
import ArticleList from '@views/pages/board/components/ArticleList';
import Banner from '@views/shared/banner/Banner';
import { NOTICE_SEARCH } from '@/graphql/Board';
import { useTranslate } from '@/hooks/useTranslate';
import {
  setKeyword,
  setNotices,
  setNoticePageInfo,
  updateNotice,
  resetBoardSlice,
} from '@/redux/reducer/boardSlice';
import { B_COLOR, W_COLOR } from '@/constants';

export default function Board() {
  const { locale } = useSelector((state) => state.user);
  const { keyword, notices } = useSelector((state) => state.board);
  const containerRef = useRef();
  const [activeTab, setActiveTab] = useState('ALL');

  const { t } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [getNoticesInit, { error, loading }] = useLazyQuery(NOTICE_SEARCH, {
    onCompleted: ({ noticeSearch }) => {
      dispatch(setNoticePageInfo(noticeSearch.paginatorInfo));
      dispatch(setNotices({ keyword, data: noticeSearch.data }));
    },
  });

  const [getMoreNotice] = useLazyQuery(NOTICE_SEARCH, {
    onCompleted: ({ noticeSearch }) => {
      dispatch(setNoticePageInfo(noticeSearch.paginatorInfo));
      dispatch(updateNotice({ keyword, data: noticeSearch.data }));
    },
  });

  const handleSearch = (value) => {
    dispatch(setKeyword(`%${value.keyword}%`));
    if (activeTab === 'ALL') {
      getNoticesInit({
        variables: {
          locale,
          keyword: `%${value.keyword}%`,
          page: 1,
        },
      });
    } else {
      getNoticesInit({
        variables: {
          locale,
          keyword: `%${value.keyword}%`,
          page: 1,
          type: activeTab,
        },
      });
    }
  };

  // 처음검색한경우 혹은 처음 페이지 들어간 경우 초기로드
  useEffect(() => {
    if (!notices[keyword] || notices[keyword]?.length === 0) {
      if (activeTab === 'ALL') {
        getNoticesInit({
          variables: {
            locale,
            keyword,
            page: 1,
          },
        });
      } else {
        getNoticesInit({
          variables: {
            locale,
            keyword,
            page: 1,
            type: activeTab,
          },
        });
      }
    }
  }, [activeTab]);

  // mount 시 스크롤 위치 불러오기
  useEffect(() => {
    const prevScrollPosition = sessionStorage.getItem('notice_board_scroll_y');
    if (prevScrollPosition && containerRef.current) {
      containerRef.current.scrollTo(0, Number(prevScrollPosition));
    }
  }, []);

  // unmount 시 스크롤 위치 저장
  useLayoutEffect(
    () => () => {
      const scrollPosition = containerRef.current?.scrollTop || 0;
      sessionStorage.setItem('notice_board_scroll_y', scrollPosition);
    },
    [],
  );

  return (
    <Container ref={containerRef}>
      <Banner />
      <BtnWrapper>
        <Button
          value="ALL"
          isActive={activeTab === 'ALL'}
          onClick={(e) => {
            dispatch(resetBoardSlice());
            setActiveTab(e.target.value);
          }}
        >
          {t('board.type.all')}
        </Button>
        <Button
          value="NOTICE"
          isActive={activeTab === 'NOTICE'}
          onClick={(e) => {
            dispatch(resetBoardSlice());
            setActiveTab(e.target.value);
          }}
        >
          {t('board.type.notice')}
        </Button>
        <Button
          value="EVENT"
          isActive={activeTab === 'EVENT'}
          onClick={(e) => {
            dispatch(resetBoardSlice());
            setActiveTab(e.target.value);
          }}
        >
          {t('board.type.event')}
        </Button>
      </BtnWrapper>
      <div>
        <SearchInput
          onSubmit={handleSearch}
          prevKeyword={keyword}
          placeholder={t('common.search.placeholder')}
        />
      </div>
      <ArticleList getMoreNotice={getMoreNotice} initLoading={loading} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
  min-height: 100%;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px 0;
`;

const Button = styled.button`
  display: flex;
  min-width: 80px;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  background: ${W_COLOR};
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${W_COLOR};
      border: none;
      box-shadow: none;
      background: ${B_COLOR};
    `};
`;
