import React from 'react';
import { isNumber } from '@/utils/number';

function CodeInputItem({ id, list, setValue, setFocus, register }) {
  const firstInput = list[0];
  const lastInput = list[list.length - 1];

  // 숫자만 입력가능
  const removeString = (e) => {
    const input = e.target.value;
    if (!isNumber(input)) {
      e.target.value = input.replace(/[^0-9]/g, '');
    }
  };

  // 카피한 값을 각각 요소를 value값에 셋팅한다.
  const spreadCopy = (e) => {
    removeString(e);
    const copyText = e.target.value.split('');
    list.forEach((name, idx) => {
      setValue(name, copyText[idx]);
    });

    if (copyText.length >= 4) {
      setFocus(list[list.length - 1]);
    } else {
      setFocus(list[copyText.length]);
    }
  };

  const moveFocus = (e) => {
    const inputValue = e.target.value;
    const inputName = e.target.name;

    // 타이핑 이후 다음 포커스로 이동(마지막 인풋제외, 처음인풋 전체복붙되는 경우제외)
    list.forEach((name, idx) => {
      const lastInput = list[list.length - 1];

      if (name === lastInput) return;
      if (inputName === firstInput && inputValue.length > 1) return;
      if (inputName === name && inputValue.length === 1 && isNumber(inputValue))
        setFocus(list[idx + 1]);
    });
  };

  const getMaxLength = (id) => {
    if (id === firstInput) return '';
    if (id === lastInput) return 1;
    return 2;
  };

  return (
    <input
      type="text"
      className="verifyNum"
      {...register(id, { required: false })}
      inputMode="numeric"
      maxLength={getMaxLength(id)}
      onChange={(e) => {
        // 지우다가 입력하는 케이스
        if (id !== firstInput && e.target.value.length > 1) {
          if (e.nativeEvent.inputType === 'insertText') {
            const [prevValue, nextValue] = e.target.value.split('');
            setValue(id, prevValue);
            setValue(list[list.indexOf(id) + 1], nextValue);
            setFocus(list[list.indexOf(id) + 1]);
            return;
          }
          setValue(id, '');
        }
        // 첫번째칸에 복붙하는 케이스
        if (id === firstInput && e.target.value.length > 1) {
          spreadCopy(e);
          return;
        }

        // 일반적인 플로우로 타이핑하는 케이스
        removeString(e);
        moveFocus(e);
      }}
      onKeyDown={(e) => {
        // 지우는 행위시 인풋 포커스 이동
        if (e.code === 'Backspace') {
          e.preventDefault();
          setValue(id, '');
          setFocus(list[list.indexOf(id) - 1]);
        }
      }}
      autoComplete="off"
    />
  );
}

export default React.memo(CodeInputItem);
