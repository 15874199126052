import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Icons } from '@assets/images';
import CustomInput from '../input/CustomInput';

export default function SearchInput({ placeholder = 'search', onSubmit, prevKeyword = '' }) {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      keyword: prevKeyword.slice(1, -1),
    },
  });

  const sendData = (keyword) => {
    onSubmit(keyword);
  };

  return (
    <SearchBar>
      <CustomInput type="text" placeholder={placeholder} register={register} name="keyword" />
      <img src={Icons.search} alt="search" onClick={handleSubmit(sendData)} />
    </SearchBar>
  );
}

const SearchBar = styled.div`
  flex: 1;
  display: flex;
  gap: 12px;

  & input[type='text'] {
    flex: 1;
  }
`;
