import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import Result from '@views/pages/token/sendToken/Result';
import FormButton from '@views/shared/forms/FormButton';
import FormButtonWrap from '@views/shared/forms/FormButtonWrap';
import { Icons } from '@assets/images';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, PRI_COLOR } from '@/constants';

export default function SendComplete() {
  const { state } = useLocation();
  // const state = {
  //   tokenId: '1',
  //   target: '0x0e1e54211531be6ca04d711ee5b7f0af5b883f64',
  //   tokenName: 'TMO',
  //   amount: '11.123111',
  //   fee: '0.2',
  // };
  const navigate = useNavigate();
  const { t } = useTranslate();

  const handlePage = () => {
    navigate(`/wallet/token/${state.tokenName}`, {
      state: {
        coinId: state.tokenId,
        coinUnit: state.tokenName,
      },
    });
  };

  return (
    <Container>
      <Box>
        <img src={Icons.successCheckBox} alt="" />
        <div className="title">{t('token.send.resultTitle')}</div>
        <div className="desc">{t('token.send.resultText')}</div>
        <div className="subtitle">{t('token.send.resultSubtitle')}</div>
        <div className="token">{state.tokenName}</div>
        <Result
          target={state.target}
          token={state.tokenName}
          amount={state.amount}
          fee={state.fee}
        />
      </Box>
      <div className="btn-wrapper">
        <FormButton text={t('common.confirm')} color="black" onClick={handlePage} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  padding: 60px 25px;

  .btn-wrapper {
    padding: 0 28px;
    margin-top: 20%;
  }
`;

const Box = styled.div`
  margin-bottom: 20px;
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';

  .title {
    font-size: 28px;
    font-weight: 400;
    line-height: 35px;

    margin-bottom: 12px;
    margin-top: 20px;
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;

    margin-bottom: 58px;
  }

  .subtitle {
    font-size: 20px;
    font-weight: 400;

    margin-bottom: 8px;
  }

  .token {
    color: ${PRI_COLOR};
    font-size: 16px;
    font-weight: 400;
  }
`;
