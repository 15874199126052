import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ChevronDown } from 'react-feather';
import FormButtonWrap from '@views/shared/forms/FormButtonWrap';
import FormButton from '@views/shared/forms/FormButton';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import Spinner from '@/views/shared/spinner/Spinner';
import { OPTIONS } from '@/constants/options';
import { SET_LOCALE, CHANGE_LOCALE } from '@/graphql/Locale';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { initializeLocale } from '@/utils/locale';
import { parseGqlErrorMsg } from '@/utils/index';
import { setLocale } from '@/redux/reducer/userSlice';
import { getAccessToken } from '@/services/HandleToken';
import useModals from '@/hooks/useModal';
import { modals } from './shared/modal/Modals';

export default function Language() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locale = initializeLocale();
  const [selectLocale, setSelectLocale] = useState(locale);
  const { openModal } = useModals();

  const [setStorageLocale, { loading }] = useMutation(SET_LOCALE, {
    onCompleted: () => {
      localStorage.setItem('isLanguageSelected', true);
      dispatch(setLocale(selectLocale));
      navigate(pagePaths.WELCOME);
    },
    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: parseGqlErrorMsg(error), link: -1 } });
    },
  });

  const [changeUserLocale, { loading: changeLoading }] = useMutation(CHANGE_LOCALE, {
    onCompleted: (data) => {
      localStorage.setItem('isLanguageSelected', true);
      dispatch(setLocale(selectLocale));
      navigate(pagePaths.WELCOME);
    },
  });

  const handleLocaleChange = ({ target }) => {
    setSelectLocale(target.value);
  };

  const setLocaleChange = () => {
    if (!getAccessToken()) {
      setStorageLocale({
        variables: {
          locale: selectLocale,
        },
      });
    } else {
      changeUserLocale({
        variables: {
          locale: selectLocale,
        },
      });
    }
  };

  if (loading) return <Spinner isLoading={loading || changeLoading} />;

  const modalTitle = () => (
    <div className="title" style={{ color: 'red' }}>
      test
    </div>
  );

  const modalMsg = () => {};

  return (
    <Container>
      <button
        type="button"
        onClick={() => {
          openModal(modals.centerOneBtnModal, {
            title: t('common.info'),
            message: t('auth.certification.popupResendMail'),
          });
        }}
      >
        모달테스트
      </button>
      <div className="cont">
        <p>{t('welcome.selectLanguage')}</p>
        <div className="select_wrap">
          <div className="select">
            <select name="lang" onChange={handleLocaleChange} defaultValue={selectLocale}>
              {OPTIONS.LOCALE.map((option) => (
                <option key={option.value} value={option.value} code={option.code}>
                  {option.label}
                </option>
              ))}
            </select>
            <ChevronDown size={16} />
          </div>
        </div>
      </div>
      <FormButtonWrap>
        <FormButton text={t('common.confirm')} color="white" onClick={setLocaleChange} rounded />
      </FormButtonWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  background: #0e043c;
  .cont {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
    color: #fff;
  }
  .select_wrap {
    text-align: center;
    color: #fff;
    .select {
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;
      position: relative;
      border: 1px solid #84819d;
      border-radius: 7px;
      color: #84819d;
      padding: 0 10px;
      select {
        border: 0;
        background: none;
        font-size: 15px;
        color: #84819d;
        font-weight: 500;
        height: 35px;
        text-align: center;
        -o-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin-right: 5px;
        option {
          text-align-last: center;
          text-align: center;
          -ms-text-align-last: center;
          -moz-text-align-last: center;
        }
      }
    }
  }
`;
