import styled from 'styled-components';
import { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';

import TokenStatus from '@views/pages/wallet/components/assets/token/components/TokenStatus';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TOKEN_COINS } from '@/graphql/Coins';
import { useTranslate } from '@/hooks/useTranslate';
import { pagePaths } from '@/navigation/Pages';
import { isInclude, parseGqlErrorMsg } from '@/utils';
import { setCoins } from '@/redux/reducer/walletSlice';
import PartialLoading from '@/views/shared/spinner/PartialLoading';

export default function TokenList() {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [refreshKey, setRefreshKey] = useState(0);
  const [tokenList, setTokenList] = useState([]);
  const { coins, filterKeyword } = useSelector((state) => state.wallet);

  const filteredList = useMemo(() => {
    if (tokenList.length === 0) return [];

    const list = tokenList.filter(
      (el) => isInclude(el.name, filterKeyword) || isInclude(el.name_en, filterKeyword),
    );

    return list;
  }, [tokenList, filterKeyword]);

  const { data, loading } = useQuery(TOKEN_COINS, {
    // currencyPrice 반영을 위한 no-cache
    // fetchPolicy: 'cache-and-network',
    // fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      // dispatch(setCoins(data));
      setTokenList(data.tokenCoins);
    },
    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: parseGqlErrorMsg(error) } });
    },
  });

  // const refreshList = () => {
  //   setRefreshKey((prevKey) => prevKey + 1);
  // };

  if (loading) {
    return <PartialLoading />;
  }

  return (
    <Container>
      {filteredList.map((item) => (
        <TokenStatus key={item.id} id={item.id} item={item} refreshKey={refreshKey} />
      ))}
    </Container>
  );
}

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
