import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import debounce from 'lodash/debounce';
import isFloat from 'validator/es/lib/isFloat';
import styled from 'styled-components';

import { modals } from '@views/shared/modal/Modals';
import FormButton from '@views/shared/forms/FormButton';
import { Icons } from '@assets/images';
import Spinner from '@/views/shared/spinner/Spinner';
import formatAmount from '@/utils/formatAmount';
import useModals from '@/hooks/useModal';
import { CHECK_SWAP, COIN_SWAP_COMMENT, VERIFY_SWAP_EMAIL } from '@/graphql/Swap';
import { pagePaths } from '@/navigation/Pages';
import swapSlice from '@/redux/reducer/swapSlice';
import { GET_BALANCE } from '@/graphql/Coins';
import { addCommas } from '@/utils/number';
import { useTranslate } from '@/hooks/useTranslate';
import '@/common/common.css';
import { calcSwapResult } from '@/utils/calc';
import { parseGqlErrorMsg } from '@/utils';
import { B_COLOR, LIGHT_COLOR, PRI_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';
import ColorChangingSVG from '@/views/shared/progress/ChangeArrow';
import useFocus from '@/hooks/useFocus';

export default function SwapForm() {
  const { t } = useTranslate();
  const swap = useSelector((state) => state.swap);
  const user = useSelector((state) => state.user);
  const [focusRef, isFocus] = useFocus(); // TODO: 이거 css 로 할수 있는데
  const swapType = swap.swapType === 'import' ? t('swap.tab.import') : t('swap.tab.export');
  const exportCoinName =
    user.locale === 'KOREAN' ? swap.exportCoin?.name : swap.exportCoin?.name_en;
  const importCoinName =
    user.locale === 'KOREAN' ? swap.importCoin?.name : swap.importCoin?.name_en;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openModal } = useModals();

  const targetCoinInfo = useMemo(
    () => ({
      ...swap.exportCoin,
      name: user.locale === 'KOREAN' ? swap.exportCoin.name : swap.exportCoin.name_en,
      amount: swap.exportCoinBalance,
      prefixText: swap.swapType === 'import' ? t('swap.import.prefix') : t('swap.export.prefix'),
    }),
    [swap.exportCoin, swap.exportCoinBalance, swap.swapType, t, user.locale],
  );

  const [isLoadingEx, setIsLoadingEx] = useState(false);
  const [isLoadingIm, setIsLoadingIm] = useState(false);
  const [swapAmount, setSwapAmount] = useState('');
  const [swappedAmount, setSwappedAmount] = useState('');
  const [fee, setFee] = useState('');
  const [inputAmount, setInputAmount] = useState('');

  // 인증 이메일 보내기
  const [sendVerifyEmail, { loading }] = useMutation(VERIFY_SWAP_EMAIL, {
    onError: (error) => {
      openModal(modals.dialog, {
        title: t('common.error'),
        message: error.message,
      });
    },
  });

  // 잔액 가져오기
  const [getBalance] = useLazyQuery(GET_BALANCE, {
    fetchPolicy: 'network-only',
  });

  const { data: commentData } = useQuery(COIN_SWAP_COMMENT, {
    variables: {
      id: swap.coinSwapId,
    },
  });

  const [checkSwap, { loading: checkswapLoading }] = useLazyQuery(CHECK_SWAP, {
    fetchPolicy: 'network-only',
    onCompleted: ({ checkSwapAmount }) => {
      if (checkSwapAmount.skip) {
        navigate(pagePaths.SWAP_CHECK_PIN, { state: { isSwapWithEth: false } });
      } else {
        // TODO: 이더리움 수수료 지급시 확인필요
        openModal(modals.swapFeeTypeCheck, {
          info: {
            swapAmount: checkSwapAmount.swapAmount,
            ethSwapAmount: checkSwapAmount.swapAmountIncludeImportEthAmount,
          },
          onSubmit: (option) => {
            const { isSwapWithEth } = option;
            navigate(pagePaths.SWAP_CHECK_PIN, {
              state: {
                isSwapWithEth,
              },
            });

            // sendVerifyEmail({
            //   onCompleted: (data) => {
            //     openModal(modals.dialog, {
            //       title: t('common.info'),
            //       message: data.sendSwapVerifyEmail.message,
            //     });
            //     navigate(pagePaths.VERIFY_EMAIL_SWAP, {
            //       state: {
            //         isSwapWithEth,
            //       },
            //     });
            //   },
            // });
          },
        });
      }
    },
    onError: (error) => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: parseGqlErrorMsg(error),
      });
    },
  });

  const refreshBalance = (type) => {
    getBalance({
      variables: {
        coin_id: type === 'export' ? swap.exportCoin.id : swap.importCoin.id,
      },
      onCompleted: ({ getBalance }) => {
        setIsLoadingEx(false);
        setIsLoadingIm(false);
        dispatch(
          swapSlice.actions.whenRefreshButtonClicked({
            type,
            amount: getBalance.amount,
          }),
        );
      },
      onError: () => {
        setIsLoadingEx(false);
        setIsLoadingIm(false);
      },
    });
  };

  // 디도스 공격 방지 debounce
  const debouncedRefreshBalance = debounce(refreshBalance, 2500);

  const CheckBalance = (_swapType, _amount) => {
    const alertMaxAmountAndInitAmount = () => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('swap.form.alertPopupMaxAmount'),
      });
      setInputAmount('');
      setSwapAmount('');
      setSwappedAmount(0);
    };

    if (_swapType === 'import') {
      if (
        _amount > swap.exportCoinBalance ||
        swap.exportCoinBalance < 0 ||
        swap.exportCoinBalance === undefined
      ) {
        alertMaxAmountAndInitAmount();
        return false;
      }
    } else if (_swapType === 'export') {
      if (_amount > swap.TMOP || swap.TMOP < 0 || swap.TMOP === undefined) {
        alertMaxAmountAndInitAmount();
        return false;
      }
    }
    return true;
  };

  /** swappedOnchange
   * 스왑 금액에 대한 validation 및 스왑 계산식
   * 수수료 있을 때 , 수수료율이 있을때
   * 보상 있을때, 보상율이 있을 때 -> @todo 다른 정보는 정적인데 얘는 동적으로 보임
   * */
  const swappedOnchange = (value) => {
    const amount = value.replaceAll(',', '');
    // 수량이 다 지워지지않는 현상 처리
    if (amount === '0') {
      setInputAmount('');
      setSwapAmount('');
      setSwappedAmount(0);
    }

    // 소수점 자리수가 5보다 작을때(4자리 이하일때) 스왑 금액을 셋팅한다.
    if (amount.indexOf('.') !== -1 && amount.substring(amount.indexOf('.') + 1).length > 4) {
      return false;
    }

    // 보내는 금액 셋팅 - 입력된 금액이 실수이고
    if (isFloat(amount) && CheckBalance(swap.swapType, amount)) {
      setSwapAmount(amount);
      const { fee, import_amount } = calcSwapResult(swap.selectedSwapInfo, Number(amount));
      setSwappedAmount(import_amount);
      setFee(fee);
    }
  };

  const handleInputAmount = (value) => {
    const removedCommaValue = value.replaceAll(',', '');

    // if (!/^[\d.]*$/.test(removedCommaValue)) return; // 소수점 허용
    if (!/^\d*$/.test(removedCommaValue)) return; // 소수점 비허용
    if (removedCommaValue.split('.').length > 2) return;
    if (value[0] === '.') {
      setInputAmount('0.');
      return;
    }

    let displayValue;
    const splittedNum = removedCommaValue.split('.');
    if (splittedNum.length === 2) {
      const [int, decimal] = splittedNum;
      if (decimal.length > 4) return;
      displayValue = `${addCommas(int)}.${decimal}`;
    } else {
      displayValue = addCommas(splittedNum[0]);
      // 왼쪽 0 제거
      if (displayValue.length === 2 && displayValue[0] === '0') {
        displayValue = displayValue.replace(/(^0+)/, '');
      }
    }
    setInputAmount(displayValue.toLocaleString());

    if (displayValue < swap.selectedSwapInfo?.minimum_amount) {
      setSwapAmount(0);
      setSwappedAmount(0);
      return false;
    }

    swappedOnchange(displayValue);
  };

  const handleSwapConfirm = () => {
    // 스왑 최소금액보다 적게 입력했을 경우
    if (swap.selectedSwapInfo?.minimum_amount > swapAmount) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('swap.form.alertPopupMinAmount'),
      });
      return;
    }

    // 핀번호 등록하지 않은경우
    if (!user.pin) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('swap.form.alertPin'),
        callback: () => {
          navigate(pagePaths.SETTINGS_SECURITY);
        },
      });
      return;
    }

    const title =
      swap.swapType === 'import' ? t('swap.form.import.title') : t('swap.form.export.title');

    const message =
      swap.swapType === 'import' ? t('swap.form.import.desc') : t('swap.form.export.desc');

    openModal(modals.centerTwoBtnModal, {
      title,
      message,
      callback: () => {
        checkSwap({
          variables: { swapId: swap.coinSwapId, amount: Number(swapAmount) },
        });
      },
    });
  };

  // 전체사용 버튼 클릭시 실행되는 함수
  const setAllBalance = () => {
    // swapType이 export 일때는 팬시포인트 총액 import 일때는 exportCoinBalance를 전체금액 세팅한다.
    const targetPoint = swap.swapType === 'export' ? swap.TMOP : swap.exportCoinBalance;
    const allBalance = Math.floor(targetPoint * 10000) / 10000;
    setInputAmount(allBalance.toLocaleString());
    setSwapAmount(allBalance);

    dispatch(
      swapSlice.actions.whenChangeSwapAmount({
        selectedSwapInfo: swap.selectedSwapInfo,
        swapAmount: allBalance,
      }),
    );
  };

  const setResetBalance = () => {
    setInputAmount('');
    setSwapAmount(''); /** 입력금액 초기화 * */
    dispatch(swapSlice.actions.whenResetButtonClicked()); /** 나머지 초기화 * */
  };

  useEffect(() => {
    dispatch(
      swapSlice.actions.whenChangeSwapAmount({
        selectedSwapInfo: swap.selectedSwapInfo,
        swapAmount,
      }),
    );
  }, [dispatch, fee, swap.selectedSwapInfo, swapAmount, swappedAmount]);

  if (checkswapLoading) return <Spinner isLoading={checkswapLoading} />;

  return (
    <Container>
      <Header>{swapType}</Header>
      <Title>
        <b>{exportCoinName}</b>
        {t('swap.form.text1')}
        <br /> → {importCoinName}
        {t('swap.form.text2')}
      </Title>
      <AvailPointWrapper>
        <span>
          {targetCoinInfo.prefixText} {targetCoinInfo.name}
        </span>
        <span className="point">
          {formatAmount(targetCoinInfo.amount)} {targetCoinInfo.unit}
        </span>
      </AvailPointWrapper>
      <InputBox isFocus={isFocus}>
        <input
          ref={focusRef}
          type="text"
          inputMode="decimal"
          onChange={(e) => {
            handleInputAmount(e.target.value);
          }}
          value={inputAmount || ''}
          placeholder={t('swap.form.amountPlaceholder')}
        />
        <button onClick={setAllBalance} type="button">
          {t('swap.form.swapAll')}
        </button>
      </InputBox>
      <SwapView>
        <SwapInfo>
          <div className="left">
            <img src={`${swap.exportCoin?.image?.url ?? swap.default_img}`} alt="" />
            <div>{exportCoinName}</div>
          </div>
          <div className="right">
            <div className="point">
              <div className="change">
                {swapAmount < swap.selectedSwapInfo?.minimum_amount
                  ? '0'
                  : `-${formatAmount(swapAmount)}`}
                {swap.exportCoin?.unit}
              </div>
              <div>
                {formatAmount(swap.exportCoinBalance)} {swap.exportCoin?.unit}
              </div>
            </div>
            <ResetIcon
              onClick={(event) => {
                const imageElement = event.currentTarget.querySelector('img');
                imageElement.classList.add('rotated');
                imageElement.addEventListener('transitionend', () =>
                  imageElement.classList.remove('rotated'),
                );
                debouncedRefreshBalance('export');
              }}
            >
              <img src={Icons.reset} alt="" />
            </ResetIcon>
          </div>
        </SwapInfo>

        <div className="icon-wrapper">
          <ColorChangingSVG />
        </div>

        <SwapInfo>
          <div className="left">
            <img src={`${swap.importCoin?.image?.url ?? swap.default_img}`} alt="" />
            <div>{importCoinName}</div>
          </div>
          <div className="right">
            <div className="point">
              <div className="changed">
                {swapAmount < swap.selectedSwapInfo?.minimum_amount
                  ? '0'
                  : `+${formatAmount(swappedAmount || swap.importAmount)}`}
                {swap.exportCoin?.unit}
              </div>
              <div>
                {formatAmount(swap.importCoinBalance)} {swap.importCoin?.unit}
              </div>
            </div>
            <ResetIcon
              onClick={(event) => {
                const imageElement = event.currentTarget.querySelector('img');
                imageElement.classList.add('rotated');
                imageElement.addEventListener('transitionend', () =>
                  imageElement.classList.remove('rotated'),
                );
                debouncedRefreshBalance('import');
              }}
            >
              <img src={Icons.reset} alt="" />
            </ResetIcon>
          </div>
        </SwapInfo>
      </SwapView>
      {/* {swap.selectedSwapInfo && <SwapDetailInfo info={swap.selectedSwapInfo} />} */}
      <SwapNoticeWrapper>
        <div className="tit">{t('swap.form.notice')}</div>

        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: commentData?.coinSwapComment.comment }}
        />
      </SwapNoticeWrapper>

      <div className="btn-wrapper">
        <FormButton text={swapType} color="black" onClick={handleSwapConfirm} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  /* 유저 선택 및 복사 금지 */
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  padding: 16px 15px 56px;
  display: flex;
  flex-direction: column;

  .icon-wrapper {
    padding: 15px 12px;
  }

  .btn-wrapper {
    margin-top: 56px;
    margin-bottom: 24px;
    padding: 0 37px;
  }
`;

const Header = styled.h1`
  display: flex;
  align-items: center;
  align-self: stretch;
  color: ${B_COLOR};
  font-family: Spoqa Han Sans Neo;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;

  margin-bottom: 40px;
`;

const Title = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 20px;
  font-weight: 400;

  & b {
    font-weight: 700;
  }
`;

const AvailPointWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;
  margin: 30px 0 20px;

  .point {
    text-align: right;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 700;
  }
`;

const InputBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 15px;
  background: ${W_COLOR};
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  padding: 10px;
  padding-left: 16px;
  height: 80px;
  align-items: center;
  border: ${({ isFocus }) => (isFocus ? `1px solid ${PRI_COLOR}` : 'none')};

  margin-bottom: 44px;

  input {
    border: none;
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    width: calc(100% - 80px);

    :placeholder {
      color: ${LIGHT_COLOR};
    }
  }

  button {
    background: transparent;
    width: 60px;
    min-width: 60px;
    height: 100%;
    border-radius: 15px;
    border: 1px solid ${LIGHT_COLOR};

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;

    // test
    // :active {
    //   background-color: ${LIGHT_COLOR};
    // }
  }
`;

const SwapView = styled.div`
  padding: 0 5px;
`;

const SwapInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      width: 35px;
      height: 35px;
    }
  }

  .right {
    display: flex;
    gap: 17px;
    align-items: center;
    color: ${TEXT_GRAY};
    text-align: right;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;

    .change {
      color: ${B_COLOR};
      text-align: right;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: 700;
    }

    .changed {
      color: ${PRI_COLOR};
      text-align: right;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

const ResetIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${W_COLOR};
  border: 0.5px solid ${LIGHT_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  .rotated {
    transform: rotate(1080deg);
    transition: transform 1.5s ease-in-out;
    will-change: transform;
  }
`;

const SwapNoticeWrapper = styled.div`
  padding: 20px;
  margin-top: 32px;
  border-radius: 15px;
  background: rgba(238, 237, 244, 0.5);

  .tit {
    color: #242424;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 22px;
  }

  .content {
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
`;
