import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import PinInput from '@views/shared/forms/PinInput';
import { modals } from '@views/shared/modal/Modals';
import { forwardRef } from 'react';
import Spinner from '@/views/shared/spinner/Spinner';
import useModals from '@/hooks/useModal';
import { CHECK_PIN } from '@/graphql/Users';
import { WITHDRAW_AMOUNT } from '@/graphql/Send';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';

const CheckPIN = ({ callback }, ref) => {
  // const { state } = useLocation();
  // const state = { okenId: 1, amount: 1, target: '123123' };
  const navigate = useNavigate();
  const { openModal } = useModals();
  const { t } = useTranslate();

  // const [withdrawAmount, { loading: sendLoading, error: sendError }] = useMutation(
  //   WITHDRAW_AMOUNT,
  //   {
  //     variables: {
  //       coin_id: Number(state.tokenId),
  //       amount: Number(state.amount),
  //       to_wallet_address: state.target,
  //       private_key: localStorage.getItem('privateKey'),
  //     },
  //     onCompleted: (data) => {
  //       if (data.withdrawAmount.status === 'success') {
  //         navigate(`/wallet/token/${state.tokenName}/send_complete`, { state });
  //       } else {
  //         openModal(modals.dialog, {
  //           title: t('common.info'),
  //           message: data.withdrawAmount.message,
  //           callback: () => {
  //             navigate(-1);
  //           },
  //         });
  //       }
  //     },
  //   },
  // );

  // const [checkPIN, { loading: checkLoading, error: checkError }] = useMutation(CHECK_PIN, {
  //   onCompleted: (data) => {
  //     if (data.checkPin.status === 'success') {
  //       withdrawAmount();
  //     } else {
  //       openModal(modals.dialog, {
  //         title: t('token.send.alertPinError'),
  //         message: data.checkPin.message,
  //       });
  //     }
  //   },
  // });

  const onComplete = (value) => {
    callback(value);
  };

  // if (checkLoading || sendLoading) return <Spinner isLoading={checkLoading || sendLoading} />;
  // if (checkError) {
  //   navigate(pagePaths.ERROR, { state: { error: checkError.message } });
  //   return null;
  // }
  // if (sendError) {
  //   navigate(pagePaths.ERROR, { state: { error: sendError.message } });
  //   return null;
  // }

  return (
    <Container>
      <PinInput
        isForgot
        length={6}
        isPassword
        placeholder="••••••"
        onComplete={onComplete}
        ref={ref}
      >
        <div className="txt_box">
          <div className="tit">{t('auth.pin')}</div>
        </div>
      </PinInput>
    </Container>
  );
};

export default forwardRef(CheckPIN);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 30px;
`;
