import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Images } from '@assets/images';
import { modals } from '@views/shared/modal/Modals';
import FormButton from '@views/shared/forms/FormButton';
import { useMutation } from '@apollo/client';
import useModals from '@/hooks/useModal';
import { RESEND_VERIFY_EMAIL } from '@/graphql/Users';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, TEXT_GRAY } from '@/constants';

export default function EmailCertification() {
  const { state } = useLocation();
  const { openModal } = useModals();
  const { t } = useTranslate();

  const [resendVerifyEmail, { data: resendMailData }] = useMutation(RESEND_VERIFY_EMAIL, {
    onCompleted: (data) => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('auth.certification.popupResendMail'),
      });
    },
  });

  const handleResendMail = () => {
    resendVerifyEmail({
      variables: {
        email: state.userId,
      },
    });
  };

  return (
    <Container>
      <img src={Images.welcome} alt="" />

      <div className="tit">{t('auth.certification.title')}</div>
      {state.notCertification ? (
        <div className="txt">{t('auth.certification.noCertification')}</div>
      ) : (
        <div className="txt">{t('auth.certification.text')}</div>
      )}
      <ButtonWrapper>
        <FormButton link={state.link} text={t('common.confirm')} color="black" />
      </ButtonWrapper>
      <Nomail>
        {t('auth.certification.noMail')}{' '}
        <button type="button" onClick={handleResendMail}>
          {t('auth.certification.reMail')}
        </button>
      </Nomail>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 72px 52px 52px;

  img {
    width: 70%;
    max-width: 280px;
    height: auto;
  }

  .tit {
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;

    margin-top: 56px;
    margin-bottom: 42px;
  }

  .txt {
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    white-space: pre-wrap;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: auto;
`;

const Nomail = styled.div`
  margin-top: 24px;

  color: ${TEXT_GRAY};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;

  button {
    color: ${B_COLOR};
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
  }
`;
