import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Images } from '@assets/images';
import FaqList from '@views/pages/settings/faqs/FaqList';
import SearchInput from '@views/shared/forms/SearchInput';
import { FAQ_SEARCH } from '@/graphql/Board';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { useFetchMoreData } from '@/hooks/useFetchMoreData';
import { setFaqKeyword } from '@/redux/reducer/settingSlice';

export default function FAQs() {
  const { locale } = useSelector((state) => state.user);
  const { keyword } = useSelector((state) => state.setting);

  const { t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, error, data, fetchMore } = useQuery(FAQ_SEARCH, {
    variables: {
      locale,
      keyword,
      page: 1,
    },
  });

  const fetchMoreData = useFetchMoreData(fetchMore, 'faqSearch');
  const handleSearch = (value) => dispatch(setFaqKeyword(`%${value.keyword}%`));

  if (error) {
    navigate(pagePaths.ERROR, { state: { error: error.message } });
    return null;
  }

  return (
    <Container>
      <ImgWrapper>
        <div className="header">
          <div className="title">{t('settings.faq')}</div>
          <div className="sub-title">{t('settings.faq.question')}</div>
        </div>
        <div className="wrapper">
          <img src={Images.faq} alt="FAQ" />
        </div>
      </ImgWrapper>
      <ContentWrapper>
        <div>
          <SearchInput
            onSubmit={handleSearch}
            placeholder={t('common.search.placeholder')}
            prevKeyword={keyword}
          />
        </div>
        <FaqList data={data} fetchMore={fetchMoreData} initLoading={loading} />
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .sub-title {
    white-space: pre-wrap;
  }
`;

const ImgWrapper = styled.div`
  position: relative;
  padding-top: 26px;
  border-radius: 0px 0px 30px 30px;
  background: #fff;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 30px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  height: auto;

  .header {
    .title {
      color: #000;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 22px;
      font-weight: 400;
      line-height: 23px; /* 104.545% */

      margin-bottom: 16px;
    }

    .subtitle {
      color: #000;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: 400;
      line-height: 23px; /* 164.286% */
    }
  }

  .wrapper {
    text-align: right;
    width: calc(100% - 140px);
  }
`;

const ContentWrapper = styled.div`
  padding: 0 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
