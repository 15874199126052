import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import { Icons, Images } from '@assets/images';
import { GET_BALANCE } from '@/graphql/Coins';
import formatAmount from '@/utils/formatAmount';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, LIGHT_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';

// wallet 페이지에서 박스
export default function TokenStatus({ id, item, refreshKey }) {
  const { t } = useTranslate();
  const { locale } = useSelector((state) => state.user);
  const symbolByLocale = locale === 'KOREAN' ? '원' : 'USD';
  const [getBalance, { data: balanceData, loading }] = useLazyQuery(GET_BALANCE);
  const navigate = useNavigate();

  const amountByCash = useMemo(() => {
    if (item.coinCurrencyPrices.length === 0) {
      return 0;
    }

    if (!balanceData) return 0;

    return formatAmount(
      balanceData.getBalance.amount * item.coinCurrencyPrices[locale === 'KOREAN' ? 0 : 1].price,
      locale,
    );
  }, [balanceData, item.coinCurrencyPrices, locale]);

  useEffect(() => {
    getBalance({
      variables: {
        coin_id: id,
      },
      fetchPolicy: refreshKey > 0 ? 'network-only' : 'cache-and-network',
    });
  }, [getBalance, id, refreshKey]);

  return (
    <Container
      onClick={() => {
        navigate(`/wallet/token/${item.acronym}`, {
          state: {
            coinId: item.id,
            coinUnit: item.acronym,
          },
        });
      }}
    >
      <img src={item.image?.url || Icons.defaultLogo} alt="logo" className="logo" />

      <ContentWrapper>
        <div className="col">
          <div className="name">{locale === 'KOREAN' ? item.name : item.name_en}</div>
          <div className="symbol">{item.acronym}</div>
        </div>
        {!balanceData && loading ? (
          <img src={Images.partialLoading} alt="" style={{ width: '50px', height: '50px' }} />
        ) : (
          <div className="col">
            <div className="amount">{formatAmount(balanceData?.getBalance.amount)}</div>
            <div className="cash-amount">{`${amountByCash} ${symbolByLocale}`}</div>
          </div>
        )}
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  border-radius: 15px;
  background: ${W_COLOR};
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  padding: 36px 24px;
  display: flex;
  align-items: center;

  .logo {
    margin-right: 20px;
    width: 45px;
    height: 45px;
  }

  // test
  // :active {
  //   background-color: ${LIGHT_COLOR};
  //   transform: scale(0.97);
  // }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;

  & > .col {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  & .name {
    color: ${B_COLOR};
    font-family: Spoqa Han Sans Neo;
    font-size: 16px;
    font-weight: 400;
  }
  & .symbol {
    color: ${TEXT_GRAY};
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-weight: 400;
  }
  & .amount {
    color: ${B_COLOR};
    text-align: right;
    font-family: Spoqa Han Sans Neo;
    font-size: 18px;
    font-weight: 700;
  }
  & .cash-amount {
    color: ${B_COLOR};
    text-align: right;
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-weight: 400;
  }
`;

// {loading ? (
//   <div className="box">
//     <div className="name">
//       <img src={item.image.url} alt="" />
//       {user.locale === 'KOREAN' ? item.name : item.name_en}
//     </div>
//     <div className="value">
//       <img src={Icons.assetLoading} alt="" />
//     </div>
//   </div>
// ) : balanceData ? (

// ) : (
//   <div className="box">
//     <div className="name">
//       {/* <img src={item.image.url} alt="" /> */}
//       {user.locale === 'KOREAN' ? item.name : item.name_en}
//     </div>
//     <div className="value">{t('common.error')}</div>
//   </div>
// )}
