import React, { useMemo, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { modals } from '@views/shared/modal/Modals';
import { Icons } from '@assets/images';
import FormButtonWrap from '@views/shared/forms/FormButtonWrap';
import FormButton from '@views/shared/forms/FormButton';
import CodeInputItem from '@views/shared/input/CodeInputItem';
import Spinner from '@/views/shared/spinner/Spinner';
import { pagePaths } from '@/navigation/Pages';
import useModals from '@/hooks/useModal';
import {
  CHECK_REISSUE_WALLET_VERIFY,
  VERIFY_REISSUE_WALLET_EMAIL,
  REISSUE_WALLET,
} from '@/graphql/Users';
import { useTranslate } from '@/hooks/useTranslate';
import useCountDown from '@/hooks/useCountDown';
import { formatTime } from '@/utils/time';
import {
  Nomail,
  InputWrapper,
  Container,
  Title,
  Desc,
  Form,
} from '../../token/swapToken/components/verifyInputForm';
import { CREATE_SYNTAX } from '@/graphql/Coins';
import { parseGqlErrorMsg } from '@/utils';

// 핀체크로 대체되서 안쓰는 페이지
// 지갑재발급자이지만 기존 지갑이 기기에 저장되어있는경우 이메일 인증 진행이후 지갑 재발급 절차를 진행한다.
export default function WalletReissueEmailVerify() {
  const { remainingTime, startTimer, resetTimer } = useCountDown(3 * 60);
  const { handleSubmit, setValue, setFocus, register } = useForm();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { openModal } = useModals();
  const codeInputList = useMemo(() => ['1st', '2nd', '3rd', '4th'], []);
  const clearInput = () => codeInputList.forEach((name) => setValue(name, ''));

  // 복구구문 생성
  const [createSyntax, { loading }] = useMutation(CREATE_SYNTAX, {
    onCompleted: ({ createPrivateKeyDecryptionSyntax }) => {
      navigate(pagePaths.WALLET_BACKUP, {
        state: {
          backupCode: createPrivateKeyDecryptionSyntax.data.privateKeyDecryptionSyntax,
          from: 'reissue_wallet',
        },
        replace: true,
      });
    },

    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: parseGqlErrorMsg(error) } });
    },
  });

  // 인증번호 검증
  const [checkVerify, { loading: checkEmailLoading }] = useMutation(CHECK_REISSUE_WALLET_VERIFY, {
    onCompleted: ({ checkEthereumWalletGenerateVerifyEmail: data }) => {
      if (data.status === 'fail') {
        clearInput();
        openModal(modals.dialog, {
          title: t('common.info'),
          message: data.message,
        });
      }
      if (data.status === 'success') {
        openModal(modals.dialog, {
          title: t('common.info'),
          message: data.message,
          callback: () => createSyntax(),
        });
      }
    },
    onError: (error) => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: error.message,
      });
      navigate(pagePaths.SETTINGS_SECURITY, { replace: true });
    },
  });

  // 인증 메일 재전송
  const [resendVerifyEmail, { loading: resendLoading }] = useMutation(VERIFY_REISSUE_WALLET_EMAIL, {
    onCompleted: (data) => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: data.sendEthereumWalletGenerateVerifyEmail.message,
      });
      resetTimer();
      clearInput();
    },
    onError: (error) => {
      openModal(modals.dialog, {
        title: t('common.error'),
        message: error.message,
      });
    },
  });

  const sendData = (number) => {
    const certNum = Object.values(number).join('');

    if (certNum.length < 4) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('common.certification.popupNoCode'),
      });
      return false;
    }

    // 이메일 인증번호 확인
    checkVerify({
      variables: {
        certification_number: certNum,
      },
    });
  };

  useEffect(() => {
    if (remainingTime === 0) navigate(pagePaths.SETTINGS_SECURITY, { replace: true });
  }, [navigate, remainingTime]);

  useEffect(() => {
    startTimer();
  }, [startTimer]);

  if (checkEmailLoading || resendLoading)
    return <Spinner isLoading={checkEmailLoading || resendLoading} />;

  return (
    <Container>
      <img src={Icons.emailSend} alt="" />
      <Title>{t('swap.certification.title')}</Title>
      <Desc>{t('settings.security.reissueWallet.certification.text')}</Desc>

      <Form onSubmit={handleSubmit(sendData)}>
        <InputWrapper>
          {codeInputList.map((name) => (
            <CodeInputItem
              id={name}
              list={codeInputList}
              setValue={setValue}
              setFocus={setFocus}
              register={register}
            />
          ))}
        </InputWrapper>
        <div className="counter">{formatTime(remainingTime)}</div>

        <FormButton type="submit" text={t('common.confirm')} color="black" disabled={loading} />
      </Form>
      <Nomail>
        {t('swap.certification.noMail')}{' '}
        <button type="button" onClick={resendVerifyEmail}>
          {t('swap.certification.reMail')}
        </button>
      </Nomail>
    </Container>
  );
}
