import styled from 'styled-components';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { Images } from '@assets/images';
import PasswordInput from '@views/shared/forms/PasswordInput';
import FormButton from '@views/shared/forms/FormButton';
import FormButtonWrap from '@views/shared/forms/FormButtonWrap';
import { modals } from '@views/shared/modal/Modals';
import WebText from '@views/pages/web/WebText.json';
import { useNavigate } from 'react-router-dom';
import Spinner from '@/views/shared/spinner/Spinner';
import useModals from '@/hooks/useModal';
import { UPDATE_FORGOTTEN_PASSWORD } from '@/graphql/Users';
import { B_COLOR, LIGHT_COLOR, RED_COLOR, TEXT_GRAY } from '@/constants';
import { useTranslate } from '@/hooks/useTranslate';
import { pagePaths } from '@/navigation/Pages';
import { getLocale } from '@/utils/locale';

export default function SettingPassword() {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const token = new URLSearchParams(window.location.search).get('token');
  const email = new URLSearchParams(window.location.search).get('email');
  const locale = getLocale();

  const { openModal } = useModals();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: 'onBlur' });
  const [changePassword, setChangePassword] = useState(false);
  const [updateForgottenPassword, { loading }] = useMutation(UPDATE_FORGOTTEN_PASSWORD, {
    onCompleted: (data) => {
      if (data.updateForgottenPassword.status === 'success') {
        // setChangePassword(true);
        openModal(modals.dialog, {
          title: t('common.info'),
          message: data.updateForgottenPassword.message,
          callback: navigate(pagePaths.WELCOME),
        });
      } else {
        openModal(modals.dialog, {
          title: WebText.common.info[locale],
          message: data.updateForgottenPassword.message,
        });
      }
    },
  });

  const onSubmit = ({ password, passwordConfirm }) => {
    updateForgottenPassword({
      variables: {
        email,
        token,
        password,
        password_confirmation: passwordConfirm,
      },
    });
  };

  if (loading) return <Spinner isLoading={loading} />;

  return (
    <Container>
      <img src={Images.tmoWalletLogo} alt="" className="logo" />
      <Article>
        <div className="title">{t('auth.retry.setting.password.title')}</div>
        <div className="subtitle">{t('auth.retry.setting.password.subtitle')}</div>

        <FormWrapper onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormBox>
            <PasswordInput
              id="userPw"
              name="password"
              placeholder={t('settings.personalData.newPasswordPlaceholder')}
              label={WebText.settingPassword.password[locale]}
              register={register}
              maxlength="16"
              useToggle
              options={{
                required: WebText.settingPassword.alertEssential[locale],
                pattern: {
                  value: /^(?=.*[a-zA-Z])((?=.*\d)(?=.*\W)).{8,16}$/,
                  message: WebText.settingPassword.alertInvalidPassword[locale],
                },
                minLength: {
                  value: 8,
                  message: WebText.settingPassword.alertMinPassword[locale],
                },
              }}
            />
            {errors.password && <small role="alert">{errors.password.message}</small>}
          </FormBox>
          <FormBox>
            <PasswordInput
              id="userPwCf"
              name="passwordConfirm"
              placeholder={t('auth.signup.repeatPassword')}
              label={WebText.settingPassword.repeatPassword[locale]}
              register={register}
              maxlength="16"
              useToggle
              options={{
                required: WebText.settingPassword.alertEssential[locale],
                validate: (val) => {
                  if (watch('password') !== val) {
                    return WebText.settingPassword.alertMatchPassword[locale];
                  }
                },
              }}
            />
            {errors.passwordConfirm && <small role="alert">{errors.passwordConfirm.message}</small>}
          </FormBox>
          <BtnWrapper>
            <FormButton
              type="submit"
              text={WebText.common.confirm[locale]}
              color="black"
              flex={1}
              disabled={!isValid}
            />
          </BtnWrapper>
        </FormWrapper>
      </Article>
      <Footer>© TMO Wallet. All rights reserved.</Footer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 30px;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  .logo {
    width: 120px;
    height: auto;
  }
`;

const Article = styled.article`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  margin: auto 0;

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;

    margin-bottom: 20px;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;

    margin-bottom: 90px;
  }
`;

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;

  & > :first-child {
    margin-bottom: 20px;
  }

  & > :last-child {
    margin-top: 56px;
  }
`;

const FormBox = styled('div')`
  label {
    margin-left: 19px;
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }

  input {
    border-radius: 30px;
    border: 1px solid ${LIGHT_COLOR};
    background: transparent;
    box-shadow: none;
    height: 55px;

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;

    &:focus {
      border: 1px solid ${B_COLOR};
    }
  }

  small {
    padding-left: 20px;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px; /* 153.846% */

    &[role='alert'] {
      color: ${RED_COLOR};
    }
  }
`;

const BtnWrapper = styled.div`
  padding: 0 12px;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
`;

const Footer = styled.footer`
  color: ${TEXT_GRAY};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 12px;
  font-weight: 400;
`;
