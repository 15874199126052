import styled from 'styled-components';
import { useState } from 'react';

import ListFilter from '@views/pages/token/components/ListFilter';
import { modals } from '@views/shared/modal/Modals';
import TransactionList from '@views/pages/token/history/components/TransactionList';
import SwapList from '@/views/pages/token/history/components/TokenSwapList';
import useModals from '@/hooks/useModal';
import { useTranslate } from '@/hooks/useTranslate';

export default function History({ setSend, setSwap, coinId }) {
  const { openModal } = useModals();
  const { t } = useTranslate();
  const [showList, setShowList] = useState(() => {
    if (setSend && !setSwap) {
      return 'transaction';
    }
    if (!setSend && setSwap) {
      return 'swap';
    }
    return 'transaction';
  });

  const showTransactionDetail = (data) => {
    openModal(modals.tokenTransactionDetail, {
      data,
    });
  };

  const showSwapDetail = (data) => {
    openModal(modals.tokenSwapDetail, {
      data,
    });
  };

  return (
    <Container>
      <ListFilter
        setSend={setSend}
        setSwap={setSwap}
        setShowList={setShowList}
        showList={showList}
      />

      {setSend && showList === 'transaction' && (
        <TransactionList coinId={coinId} showTransactionDetail={showTransactionDetail} />
      )}
      {setSwap && showList === 'swap' && (
        <SwapList coinId={coinId} showSwapDetail={showSwapDetail} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  padding: 0 20px;
  margin-top: 34px;

  border-radius: 30px 30px 0px 0px;
  background: #fff;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
`;
