import React, { useEffect } from 'react';
import { IntlProvider, ReactIntlErrorCode } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import langEn from '@/lang/en.json';
import langKo from '@/lang/ko.json';
import { initializeLocale, checkLocale, setLocale, getLocale } from '@/utils/locale';
import { pagePaths } from '@/navigation/Pages';

// 리덕스변경시 검토이후 언어반영
function LocaleProvider({ children }) {
  const navigate = useNavigate();
  const { locale } = useSelector((state) => state.user);

  const initialLocale = initializeLocale();
  const messageLocale = checkLocale(locale) ? locale : initialLocale;
  const messages = { ENGLISH: langEn, KOREAN: langKo }[messageLocale];

  const onError = (e) => {
    if (e.code === ReactIntlErrorCode.MISSING_DATA) return;
    console.error(e);
  };

  // locale 값 임의 변경 방지
  useEffect(() => {
    const localLocale = getLocale();

    if (!localLocale && !checkLocale(locale)) {
      // navigate(pagePaths.WELCOME);
      setLocale(initialLocale);
    } else {
      setLocale(locale);
    }
  }, [initialLocale, locale]);

  return (
    <IntlProvider locale={messageLocale} messages={messages} onError={onError}>
      {children}
    </IntlProvider>
  );
}

export default LocaleProvider;
