import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import PageInfo from '@views/shared/pageInfo';
import { useTranslate } from '@/hooks/useTranslate';
import { getRandomNumber, getRandomNumbers } from '@/utils/number';
import WalletCode from './components/WalletCode';
import WalletCodeConfirm from './components/WalletCodeConfirm';

// 신규 회원가입 회원에게 노출
// 지갑 재생성으로 들어올수있고 지갑생성으로 들어오는 케이스 두개 있음 state의 from 으로 구분
export default function BackupWallet() {
  const { t } = useTranslate();
  const { state } = useLocation();
  // const state = {
  //   backupCode: 'kitten sample settle wage welcome predict twelve river',
  //   userId: 'asdfa',
  //   from: 'asdf',
  // };

  const [hiddenIdx, setHiddenIdx] = useState([]);
  const [confirm, setConfirm] = useState(false);

  const infoTexts = {
    category: t('auth.backupWallet.category'),
    title: t('auth.backupWallet.title'),
    subTxt: t('auth.backupWallet.subText'),
    subTxtConfirm: t('auth.backupWallet.subTextConfirm'),
  };

  const handleConfirm = () => setConfirm((prev) => !prev);

  // 숨김 구문 인덱스 설정
  useEffect(() => {
    if (confirm && state.backupCode && hiddenIdx.length === 0) {
      const randomNum = getRandomNumbers(0, 7, 2);
      setHiddenIdx(randomNum);
    }
  }, [state.backupCode, confirm, hiddenIdx]);

  return (
    <Container>
      {!confirm ? (
        <WalletCode backupCodeText={state.backupCode} onToggleClick={handleConfirm} />
      ) : (
        <WalletCodeConfirm
          userId={state.userId}
          from={state.from}
          backupCodeText={state.backupCode}
          pin={state.pin}
          hiddenIdx={hiddenIdx}
          onToggleClick={handleConfirm}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 40px 15px;
`;
