import { gql } from '@apollo/client';

const SITE_GUIDE = gql`
  query ($id: ID!) {
    siteGuide(id: $id) {
      id
      category
      locale
      subject
      content
      revisioned_at
      created_at
      previous {
        id
        subject
        revisioned_at
      }
      next {
        id
        subject
        revisioned_at
      }
    }
  }
`;

const SITE_GUIDE_LATEST_BY_CATEGORY = gql`
  query ($category: SiteGuideCategory!, $locale: LocaleStatus!) {
    siteGuideLatestByCategory(category: $category, locale: $locale) {
      id
      subject
      content
      revisioned_at
      previous {
        id
      }
    }
  }
`;

const INITIAL_DATA = gql`
  query initialDataFetchQuery($locale: LocaleStatus!) {
    getBalanceByDefaultCoin {
      coin {
        id
        name
        name_en
        symbol
        acronym
        unit
        created_at
        image {
          url
        }
      }
      amount
      balanced_at
    }

    checkEthereumUserCoinWalletExists {
      status
      message
    }

    bannersByRoute(locale: $locale) {
      route
      banners {
        id
        description
        link
        image {
          url
        }
      }
    }

    tokenCoins {
      id
      name
      name_en
      symbol
      acronym
      unit
      is_token
      is_swap_import
      is_swap_export
      is_transaction
      is_private_key
      is_active
      order_by_no
      created_at
      updated_at
      image {
        url
      }
      coinCurrencyPrices {
        symbol
        price
      }
      coinNetworkInfo {
        network
        network_fee
        gas_price
        gas_limit
      }
    }

    getAttendance {
      data
    }
  }
`;

export { SITE_GUIDE, SITE_GUIDE_LATEST_BY_CATEGORY, INITIAL_DATA };
