import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AlertTriangle } from 'react-feather';

import PinInput from '@views/shared/forms/PinInput';
import { modals } from '@views/shared/modal/Modals';
import { useMutation } from '@apollo/client';
import Spinner from '@/views/shared/spinner/Spinner';
import { setUser } from '@/redux/reducer/userSlice';
import useModals from '@/hooks/useModal';
import { CHECK_PRIVATE_KEY_DECRYPTION_SYNTAX, CREATE_PIN } from '@/graphql/Users';
import { useTranslate } from '@/hooks/useTranslate';
import PageInfo from '@/views/shared/pageInfo';
import MnemonicFiled from '@/views/shared/forms/MnemonicFiled';

export default function RegisterPIN() {
  const navigate = useNavigate();
  const { openModal } = useModals();
  const { t } = useTranslate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [myPin, setMyPin] = useState(null);
  const [mySyntax, setMySyntax] = useState(null);

  const [showCheckSyntax, setShowChecksyntax] = useState(true);
  const [showPin, setShowPin] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [createPin, { loading }] = useMutation(CREATE_PIN, {
    onCompleted: ({ createPin }) => {
      openModal(modals.dialog, {
        title: t('settings.security.registerPin.alertSuccess'),
        message: createPin.message,
        callback: () => {
          navigate(-1);
        },
      });
      dispatch(setUser({ ...user, pin: createPin.data.pin }));
    },
    onError: (error) => {
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      openModal(modals.dialog, {
        title: t('settings.security.registerPin.alertFail'),
        message: errorMsg,
      });
      navigate(-1);
    },
  });

  const handlePin = (value) => {
    setMyPin(value);
    setShowPin(false);
    setShowConfirm(true);
  };

  const handleSubmit = (value) => {
    createPin({
      variables: {
        pin: myPin,
        pin_confirmation: value,
        syntax: mySyntax,
      },
    });
  };

  const [checkSyntax, { loading: checkSyntaxLoading, error }] = useMutation(
    CHECK_PRIVATE_KEY_DECRYPTION_SYNTAX,
    {
      onCompleted: ({ checkPrivateKeyDecryptionSyntax }) => {
        if (checkPrivateKeyDecryptionSyntax.status === 'success') {
          setShowChecksyntax(false);
          setShowPin(true);
        } else {
          openModal(modals.dialog, {
            title: t('settings.security.findPrivateKey.decryptionFailed'),
            message: checkPrivateKeyDecryptionSyntax.message,
          });
        }
      },
    },
  );

  const onCheckSyntaxSubmit = (values) => {
    const inputs = document.querySelectorAll('.recover');
    if ([...inputs].some((input) => !input.value.trim())) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('settings.security.findPrivateKey.decryptionFailed'),
      });
      return;
    }
    const syntaxStr = Object.values(values).join(' ');

    setMySyntax(syntaxStr);
    checkSyntax({
      variables: {
        syntax: syntaxStr,
      },
    });
  };

  useLayoutEffect(() => {
    if (!state.hasWallet) {
      setShowChecksyntax(false);
      setShowPin(true);
    }
  }, [state.hasWallet]);

  if (loading) return <Spinner isLoading={loading} />;

  return (
    <Container>
      {showCheckSyntax && (
        <>
          <PageInfo
            title={t('settings.security.registerPin.checkMnemonic.title')}
            subTxt={t('settings.security.registerPin.checkMnemonic.subtitle')}
          />
          <MnemonicFiled onSubmit={onCheckSyntaxSubmit} />
        </>
      )}
      {showPin && (
        <PinInput length={6} isPassword placeholder="••••••" onComplete={handlePin}>
          <div className="txt_box">
            <div className="tit">{t('settings.security.registerPin.title')}</div>
            <div className="txt">{t('settings.security.registerPin.text')}</div>
            {/* <div className="warning">
              <AlertTriangle size={20} />
              {t('settings.security.registerPin.info')}
            </div> */}
          </div>
        </PinInput>
      )}
      {showConfirm && (
        <PinInput length={6} isPassword placeholder="••••••" onComplete={handleSubmit}>
          <div className="txt_box">
            <div className="tit">{t('settings.security.registerPin.confirm.title')}</div>
            <div className="txt">{t('settings.security.registerPin.confirm.text')}</div>
          </div>
        </PinInput>
      )}
    </Container>
  );
}

const Container = styled.div`
  min-height: 100%;
  padding: 72px 15px;
  display: flex;
  flex-direction: column;

  .warning {
    text-align: left;
    position: relative;
    margin-top: 30px;
    padding: 20px 20px 20px 50px;
    border-radius: 10px;
    color: #db2b1d;
    background: rgba(219, 43, 29, 0.05);
    font-size: 13px;
    svg {
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }
`;
