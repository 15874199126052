import React, { useEffect, useState } from 'react';
import { G_COLOR } from '@/constants';

const ColorChangingSVG = () => {
  const [colorIndex, setColorIndex] = useState(0);

  // 색상 배열
  const colors = [G_COLOR, '#7F7F7F', '#111111'];

  useEffect(() => {
    const intervalId = setInterval(() => {
      // 현재 색상을 다음 색상으로 변경
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 280);

    // 컴포넌트가 unmount 되면 interval을 정리합니다.
    return () => clearInterval(intervalId);
  }, [colors.length]);

  return (
    <svg width="11" height="24" viewBox="0 0 11 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.73468 0.493164L5.50004 5.25845L10.2653 0.493164"
        stroke={colors[colorIndex]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.73468 9.60913L5.50004 14.3744L10.2653 9.60913"
        stroke={colors[(colorIndex + 1) % colors.length]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.73468 18.7251L5.50004 23.4904L10.2653 18.7251"
        stroke={colors[(colorIndex + 2) % colors.length]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ColorChangingSVG;
