import React, { useRef, useState } from 'react';
import { AlertTriangle } from 'react-feather';

import FormButton from '@views/shared/forms/FormButton';
import { modals } from '@views/shared/modal/Modals';
import styled from 'styled-components';
import { useTranslate } from '@/hooks/useTranslate';
import useModals from '@/hooks/useModal';
import { B_COLOR, G_COLOR, RED_COLOR } from '@/constants';
import { Icons } from '@/assets/images';

function WalletCode({ backupCodeText, onToggleClick }) {
  const { openModal } = useModals();
  const { t } = useTranslate();
  const [isChecked, setIsChecked] = useState(false);
  const backupCode = useRef();

  const backupCodeArray = backupCodeText.split(' ');

  const onClickCopy = () => {
    const code = backupCode.current.value;
    const textarea = document.createElement('textarea');
    textarea.value = code;
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    openModal(modals.dialog, {
      title: t('common.info'),
      message: t('common.copyClipboard'),
    });
  };

  return (
    <>
      <Header>
        <div className="step">step 2</div>
        <div className="title">{t('auth.backupWallet.step1.title')}</div>
        <div className="subtitle">{t('auth.backupWallet.step1.subtitle')}</div>
      </Header>

      <TextArea ref={backupCode} value={backupCodeText} />
      <CodeList className="code_list">
        {backupCodeArray.map((code, index) => (
          <li key={index}>
            <span className="num">{index + 1}.</span>
            <span className="code">{code}</span>
          </li>
        ))}
      </CodeList>
      <Warning>
        <div style={{ paddingTop: '6px' }}>
          <AlertTriangle size={20} />
        </div>
        <div>{t('auth.backupWallet.step1.warning')}</div>
      </Warning>

      <CheckBox>
        <img
          src={isChecked ? Icons.circleCheckBoxOn : Icons.circleCheckBoxOff}
          alt="check"
          onClick={() => setIsChecked((prev) => !prev)}
        />
        <div>
          <button
            type="button"
            className="link"
            onClick={() => {
              openModal(modals.centerConfirmModal, {
                title: t('auth.backupWallet.recovery.guide'),
                message: t('auth.backupWallet.recovery.text'),
                isConfirmed: isChecked,
                callback: () => setIsChecked(true),
              });
            }}
          >
            {t('auth.backupWallet.recovery')}
          </button>
          <span>{t('common.have.checked')}</span>
        </div>
      </CheckBox>

      <ButtonWrapper>
        <FormButton
          onClick={onToggleClick}
          text={t('common.confirm')}
          color="black"
          flex={1}
          disabled={!isChecked}
        />
        <img src={Icons.saveBtn} alt="" onClick={onClickCopy} />
      </ButtonWrapper>
    </>
  );
}

export default WalletCode;

const Header = styled.div`
  color: ${B_COLOR};
  width: 100%;

  .step {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 10px;
  }

  .title {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 400;

    margin-bottom: 10px;
  }

  .subtitle {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
  }

  margin-bottom: 56px;
`;

const CodeList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 45px;

  li {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    width: calc(50% - 12px);
    border-radius: 15px;
    border: none;
    background: white;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

    .num {
      color: ${G_COLOR};
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: 400;
      line-height: 23px; /* 164.286% */
    }

    .code {
      color: #111;
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: 400;
      line-height: 23px;
      margin-left: 6px;
    }
  }
`;

const TextArea = styled.textarea`
  opacity: 0;
  width: 1px;
  height: 1px;
  visibility: hidden;
  overflow: hidden;
  padding: 0;
`;

const Warning = styled.div`
  padding: 0 24px;
  display: flex;
  gap: 12px;
  color: ${RED_COLOR};
  justify-content: center;
  margin-bottom: 32px;

  font-family: 'Spoqa Han Sans Neo';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px; /* 150% */
`;

const CheckBox = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  gap: 4.5px;

  color: ${B_COLOR};
  font-size: 14px;
  font-weight: 500;

  .link {
    color: ${B_COLOR};
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }

  margin-bottom: 24px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 14px;
  margin: 0 25px;
  max-width: 300px;
  width: 100%;
`;
