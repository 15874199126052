import styled from 'styled-components';
import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useInView } from 'react-intersection-observer';
import { useTranslate } from '@/hooks/useTranslate';
import { MY_REWARD_LIST_BY_MONTH } from '@/graphql/Swap';
import { groupByMonthRewardList } from '@/utils';
import PartialLoading from '@/views/shared/spinner/PartialLoading';
import { Observer } from '@/views/shared';
import NoResult from '@/views/shared/noResult';
import RewardItem from './RewardItem';
import { Icons } from '@/assets/images';
import formatAmount from '@/utils/formatAmount';

export default function PointHistory() {
  const [ref, inView] = useInView();
  const { t } = useTranslate();

  const { data, loading, fetchMore } = useQuery(MY_REWARD_LIST_BY_MONTH, {
    variables: {
      year: 0,
      month: 0,
    },
  });

  const list = useMemo(() => {
    if (!data) return [];
    return groupByMonthRewardList(data.myUserRewardsByMonth.data);
  }, [data]);

  const getContent = () => {
    // 초기로딩
    if (!data && loading) {
      return <PartialLoading />;
    }

    if (Object.keys(list).length > 0) {
      return (
        <ul>
          {Object.keys(list).map((date) => (
            <div key={date}>
              <MonthWrapper>
                <MonthTitle>{date}</MonthTitle>
                <div className="desc">
                  <img src={Icons.cash} alt="" />
                  <strong>{formatAmount(list[date].accumulated)}P</strong>
                  {t('point.history.month.text')}
                </div>
              </MonthWrapper>
              {list[date].data.map((item, index) => (
                <RewardItem key={`${item.id}-${index}`} item={item} />
              ))}
            </div>
          ))}
          <Observer ref={ref} />
        </ul>
      );
    }

    // 없는경우
    return (
      <NoResultWrapper>
        <NoResult title={t('common.noUsed')} text={t('common.noUsed.walletText')} />
      </NoResultWrapper>
    );
  };

  useEffect(() => {
    if (inView && data && !loading) {
      const nextYear = data.myUserRewardsByMonth.next_year;
      const nextMonth = data.myUserRewardsByMonth.next_month;

      if (!nextYear || !nextMonth) return;

      fetchMore({
        variables: {
          year: nextYear,
          month: nextMonth,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            ...prev,
            myUserRewardsByMonth: {
              ...fetchMoreResult.myUserRewardsByMonth,
              data: [
                ...prev.myUserRewardsByMonth.data,
                ...fetchMoreResult.myUserRewardsByMonth.data,
              ],
            },
          };
        },
      });
    }
  }, [data, fetchMore, inView, loading]);

  return <Container>{getContent()}</Container>;
}

const Container = styled.div`
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  overflow: hidden;
`;

const MonthWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;

  & .desc {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 400;
  }
`;

const MonthTitle = styled.div`
  margin-left: 12px;
  color: #000;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 700;
`;

const NoResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;
