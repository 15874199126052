import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { BANNER_BY_ROUTE } from '@/graphql/Board';
import Spinner from '../spinner/Spinner';

export default function Banner() {
  const autoplayDelay = 2500;
  const { locale } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const [data, setData] = useState([]);

  const { loading } = useQuery(BANNER_BY_ROUTE, {
    variables: {
      locale,
    },
    onCompleted: ({ bannersByRoute }) => {
      const filteredData = bannersByRoute.filter((el) => el.route === pathname)[0];
      setData(filteredData.banners);
    },
  });

  if (loading) {
    return <Spinner isLoading={loading} />;
  }

  return (
    <Container>
      <Swiper
        modules={[Autoplay, Pagination]}
        loop
        spaceBetween={16}
        autoplay={{
          delay: autoplayDelay,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
      >
        {data?.map((item) => (
          <SwiperSlide key={item.id}>
            <Link to={item.link}>
              <img src={item.image.url} alt="" style={{ width: '100%' }} />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
}

const Container = styled.div`
  a {
    display: block;
    overflow: hidden;
    border-radius: 10px;
  }
  .swiper-pagination {
    bottom: 0;
    .swiper-pagination-bullet {
      display: none;
      border: 1px solid #fff;
      &-active {
        background: rgb(82, 62, 232);
      }
    }
  }
`;
