import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ChevronDown } from 'react-feather';

import { Icons } from '@assets/images';
import { modals } from '@views/shared/modal/Modals';
import { useState } from 'react';
import { setLocale } from '@/redux/reducer/userSlice';
import { pagePaths } from '@/navigation/Pages';
import { OPTIONS } from '@/constants/options';
import { CHANGE_LOCALE } from '@/graphql/Locale';
import useModals from '@/hooks/useModal';
import { useTranslate } from '@/hooks/useTranslate';
import { getLocale } from '@/utils/locale';
import { getVersion } from '@/utils';
import LinkItem from './LinkItem';
import CustomItem from './CustomItem';
import { G_COLOR, PRI_COLOR, TEXT_GRAY } from '@/constants';
import Switch from '@/views/shared/forms/Switch';

export default function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { openModal } = useModals();
  const { t } = useTranslate();
  const locale = getLocale();
  const [changeLocale, { error: localeError }] = useMutation(CHANGE_LOCALE);
  const [isChecked, setIsChecked] = useState(false);

  const handleLocaleChange = ({ target }) => {
    changeLocale({
      variables: {
        locale: target.value,
      },
      onCompleted: ({ changeLocale }) => {
        if (changeLocale.status === 'success') {
          dispatch(setLocale(target.value));
          window.location.reload();
        }
      },
    });
  };

  const handleLogout = () => {
    openModal(modals.confirm, {
      title: t('common.info'),
      message: t('settings.alertSignOut'),
      callback: () => {
        navigate(pagePaths.LOGOUT);
      },
    });
  };

  const handleAlert = () => {
    setIsChecked((prev) => !prev);
    // window.getPushToken();
  };

  if (localeError) {
    navigate(pagePaths.ERROR, { state: { error: localeError.message } });
    return null;
  }

  return (
    <Container>
      <ul className="menu">
        {/* 내 정보 */}
        <LinkItem
          to={pagePaths.SETTINGS_PERSONAL}
          imgSrc={Icons.setMyBtn}
          title={t('settings.personalData')}
          subTitle={t('settings.personalData.sub')}
        />
        {/* 보안 설정 */}
        <LinkItem
          to={pagePaths.SETTINGS_SECURITY}
          imgSrc={Icons.privacyBtn}
          title={t('settings.security')}
          subTitle={t('settings.security.sub')}
        />
        {/* 자주 하는 질문 */}
        <LinkItem
          to={pagePaths.SETTINGS_FAQS}
          imgSrc={Icons.faqBtn}
          title={t('settings.faq')}
          subTitle={t('settings.faq.question')}
        />
        {/* 이용약관 및 정책 */}
        <LinkItem
          to={pagePaths.SETTINGS_TERMS_CONDITION}
          imgSrc={Icons.setDocBtn}
          title={t('settings.termsAndPrivacy')}
          // subTitle={t('settings.termsAndPrivacy.sub')}
        />
        {/* 알림 test */}
        <CustomItem imgSrc={Icons.setAlertBtn} title={t('settings.noti')}>
          <Switch id="alert" checked={isChecked} onChange={handleAlert} />
        </CustomItem>
        {/* language */}
        <CustomItem imgSrc={Icons.langBtn} title={t('settings.language')}>
          <select name="lang" onChange={handleLocaleChange} defaultValue={locale}>
            {OPTIONS.LOCALE.map((option) => (
              <option key={option.value} value={option.value} code={option.code}>
                {option.label}
              </option>
            ))}
          </select>
          <ChevronDown color={TEXT_GRAY} size={16} />
        </CustomItem>

        {/* 앱 버전 */}
        <CustomItem imgSrc={Icons.versionBtn} title={t('settings.version')}>
          <span>V{getVersion()}</span>
        </CustomItem>

        {/* 로그아웃 */}
        <CustomItem
          imgSrc={Icons.setLogoutBtn}
          title={t('settings.signOut')}
          onClick={handleLogout}
        />
      </ul>

      <CustomerService>
        <img src={Icons.customerService} alt="" />
        <div className="content">
          <div className="title">{t('settings.supportTitle')}</div>
          <div className="desc">{t('settings.supportComment')}</div>
          <div className="mail">{t('settings.supportEmail')}</div>
        </div>
      </CustomerService>
    </Container>
  );
}

const Container = styled.ul`
  padding: 0px 20px 80px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 24px 0 30px;
  }

  select {
    border: 0;
    padding: 0;
    margin-right: 4px;

    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 400;

    background: transparent;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align: right;
  }
`;

const CustomerService = styled.div`
  margin-top: 30px;
  border-top: 1px solid ${G_COLOR};
  padding-top: 20px;
  display: flex;
  gap: 18px;

  font-family: 'Spoqa Han Sans Neo';

  img {
    margin-top: 3px;
    height: 23px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .title {
    color: var(--main3, #111);
    font-size: 16px;
    font-weight: 500;
  }

  .desc {
    color: ${TEXT_GRAY};
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.36px;
  }
  .mail {
    color: ${PRI_COLOR};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.42px;
  }
`;
