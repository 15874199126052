import styled from 'styled-components';
import PrivacyText from '@views/shared/text/PrivacyText';
import { TermsContainer } from '../terms';

export default function Conditions() {
  return (
    <TermsContainer>
      <PrivacyText />
    </TermsContainer>
  );
}
