import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import PinInput from '@/views/shared/forms/PinInput';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR } from '@/constants';
import { CREATE_SYNTAX } from '@/graphql/Coins';
import { pagePaths } from '@/navigation/Pages';
import { parseGqlErrorMsg } from '@/utils';
import useModals from '@/hooks/useModal';
import { modals } from '@/views/shared/modal/Modals';
import { CHECK_PIN } from '@/graphql/Users';
import Spinner from '@/views/shared/spinner/Spinner';

const WalletCheckpin = () => {
  const { state } = useLocation();
  const ref = useRef(null);
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { email } = useSelector((state) => state.user);
  const { openModal } = useModals();
  const [tempMyPin, setTempMyPin] = useState('');

  const [createSyntax, { loading: createSyntaxLoading }] = useMutation(CREATE_SYNTAX, {
    onCompleted: ({ createPrivateKeyDecryptionSyntax }) => {
      navigate(pagePaths.WALLET_BACKUP, {
        state: {
          userId: email,
          backupCode: createPrivateKeyDecryptionSyntax.data.privateKeyDecryptionSyntax,
          from: state.from,
          pin: tempMyPin,
        },
      });
    },

    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: parseGqlErrorMsg(error) } });
    },
  });

  const [checkPIN, { loading: checkLoading }] = useMutation(CHECK_PIN, {
    onCompleted: (data) => {
      if (data.checkPin.status === 'success') {
        createSyntax();
      } else {
        openModal(modals.dialog, {
          title: t('common.info'),
          message: data.checkPin.message,
          callback: () => {
            ref.current.clearPin();
          },
        });
      }
    },
  });

  const onComplete = (_pin) => {
    setTempMyPin(_pin);
    checkPIN({ variables: { pin: _pin } });
  };

  if (createSyntaxLoading || checkLoading) return <Spinner isLoading />;

  return (
    <Container>
      <PinInput length={6} isPassword placeholder="••••••" onComplete={onComplete} ref={ref}>
        <Header>
          <div className="step">step 1</div>
          <div className="title">{t('auth.pin')}</div>
          <div className="subtitle">{t('auth.walletRecovery.prompt')}</div>
        </Header>
      </PinInput>
    </Container>
  );
};

export default WalletCheckpin;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 30px;
`;

const Header = styled.div`
  color: ${B_COLOR};
  width: 100%;

  .step {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 10px;
  }

  .title {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 400;

    margin-bottom: 10px;
  }

  .subtitle {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
  }
`;
