import styled from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ChevronRight, RotateCw } from 'react-feather';

import { Icons, Images } from '@assets/images';
import { GET_BALANCE } from '@/graphql/Coins';
import formatAmount from '@/utils/formatAmount';
import { pagePaths } from '@/navigation/Pages';
import swapSlice from '@/redux/reducer/swapSlice';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, LIGHT_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';
import useModals from '@/hooks/useModal';
import { modals } from '@/views/shared/modal/Modals';

// swap 페이지에서 박스
export default function TokenBalance({ item, type }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { openModal } = useModals();
  const navigate = useNavigate();

  const { locale } = useSelector((state) => state.user);
  const swap = useSelector((state) => state.swap);
  const [balanceData, setBalanceData] = useState(null); // 잔액
  // const [isLoading, setIsLoading] = useState(false); // 로딩
  const [isError, setIsError] = useState(false); // 에러

  const [getBalance, { data, loading }] = useLazyQuery(GET_BALANCE, {
    fetchPolicy: 'cache-and-network',
  });

  /*
  tmoP 기준으로 타입이 정해짐
  import 타입인경우 보여줘야할 코인인포는 exportCoin : exportCoin > tmoP
  export 타입인경우 보여줘야할 코인인포는 importCoin : tmoP > importCoin
  */
  const coinInfo = useMemo(() => {
    if (type === 'import') return item.exportCoin;
    if (type === 'export') return item.importCoin;
  }, [item.exportCoin, item.importCoin, type]);

  const tmopointName = useMemo(() => {
    if (!swap.TMOP_INFO) {
      return '';
    }
    return locale === 'KOREAN' ? swap.TMOP_INFO.coin.name : swap.TMOP_INFO.coin.name_en;
  }, [locale, swap.TMOP_INFO]);

  const isToken = coinInfo.acronym === 'TMO';

  const coinName = useMemo(() => {
    if (!coinInfo) return '';
    if (locale === 'KOREAN') {
      return coinInfo.name;
    }
    return coinInfo.name_en;
  }, [coinInfo, locale]);

  function msgComp(_balance, _minimumBalance) {
    return (
      <div>
        <div>
          {t('swap.info.myPoint')}: {_balance} P
        </div>
        <div>
          {t('swap.info.minimumAmount')}: {_minimumBalance} P
        </div>
      </div>
    );
  }

  const onClickFun = (data) => {
    if (!data && loading) return;
    if (data.balance === null || data.balance === 'Not in wallet') {
      data.balance = 0;
    }
    if (type === 'export') dispatch(swapSlice.actions.whenExportListClicked(data));
    if (type === 'import') dispatch(swapSlice.actions.whenImportListClicked(data));

    navigate(pagePaths.SWAP_FORM);
  };

  const onClickLink = (_isConnected) => {
    if (!data && loading) return;
    const targetBalance = data?.getBalance.amount || 0;
    const minimumAmount = item.minimum_amount;

    let exportBalance;
    if (type === 'export') {
      exportBalance = swap.TMOP;
    } else {
      exportBalance = targetBalance;
    }

    // 출금하는 발란스랑 최소 출금 수량 비교
    if (_isConnected) {
      if (minimumAmount > exportBalance) {
        openModal(modals.centerOneBtnModal, {
          title:
            type === 'import'
              ? `${tmopointName}${t('swap.insufficient.text1')} ${coinName}${t(
                  'swap.insufficient.text2',
                )}`
              : `${coinName}${t('swap.insufficient.text1')} ${tmopointName}${t(
                  'swap.insufficient.text2',
                )}`,
          msgComponent: msgComp(exportBalance, minimumAmount),
        });
        return;
      }

      onClickFun({
        swapInfo: item,
        balance: targetBalance,
      });
    } else {
      if (isToken) {
        navigate(pagePaths.WALLET);
        return;
      }
      openModal(modals.integrationAgreeModal, {
        coinInfo,
      });
    }
  };

  useEffect(() => {
    /** 내 코인지갑에 있는 코인(토큰)아이디 배열에 해당할때 잔액을 조회한다. */

    if (item.isGetBalance) {
      getBalance({
        variables: {
          coin_id: coinInfo.id,
        },
      })
        .then((result) => {
          setBalanceData(result.data);
          // setIsLoading(false);
          setIsError(false);
        })
        .catch((err) => {
          // setIsLoading(false);
          setIsError(true);
        });
    }
  }, [coinInfo.id, getBalance, item.isGetBalance]);

  if (isToken) return null; // TODO: 임시로 토큰 안보여줌

  return (
    <Container onClick={() => onClickLink(item.isGetBalance)}>
      <img src={coinInfo.image?.url || Icons.defaultLogo} alt="" />
      <Content>
        <div>{coinName}</div>
        {!item.isGetBalance ? (
          <div className="link">
            {isToken ? t('wallet.create') : t('wallet.External.link')}
            <ChevronRight size={14} />
          </div>
        ) : (
          <div className="value">
            {formatAmount(data?.getBalance.amount ?? '')} {coinInfo.unit}
          </div>
        )}
      </Content>
      {!data && loading ? (
        <img src={Images.partialLoading} alt="" style={{ width: '50px', height: '50px' }} />
      ) : (
        <ArrowWrapper>
          <ChevronRight size={24} color={LIGHT_COLOR} />
        </ArrowWrapper>
      )}
    </Container>
  );
}

const Container = styled.li`
  background-color: ${W_COLOR};
  height: 120px;
  border-radius: 15px;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 20px;

  img {
    width: 45px;
    height: 45px;
  }

  // test
  // :active {
  //   background-color: ${LIGHT_COLOR};
  // }
`;

const Content = styled.div`
  flex: 1;
  color: ${B_COLOR};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Spoqa Han Sans Neo;
  font-size: 16px;
  font-weight: 400;

  & .link {
    color: ${TEXT_GRAY};
  }

  .value {
    font-size: 20px;
    font-weight: 700;
  }
`;

const ArrowWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
