import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';

import { getAccessToken, setTokenInfos } from '@services/HandleToken';
import PasswordInput from '@views/shared/forms/PasswordInput';
import { modals } from '@views/shared/modal/Modals';
import Spinner from '@/views/shared/spinner/Spinner';
import useModals from '@/hooks/useModal';
import { LOGIN_USER } from '@/graphql/Users';
import { DEFAULT_PAGE, pagePaths } from '@/navigation/Pages';
import { setUser } from '@/redux/reducer/userSlice';
import { useTranslate } from '@/hooks/useTranslate';
import { Images } from '@/assets/images';
import { B_COLOR, G_COLOR, LIGHT_COLOR, RED_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';
import CustomInput from '@/views/shared/input/CustomInput';

export default function Login() {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openModal } = useModals();
  const user = useSelector((state) => state.user);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();

  const [loginUser, { loading: loginLoading }] = useMutation(LOGIN_USER, {
    onCompleted: ({ loginUser }) => {
      dispatch(setUser(loginUser.user));

      const { access_token, expires_in, refresh_token } = loginUser;
      setTokenInfos(access_token, expires_in, refresh_token);
    },
    onError: (error, { variables }) => {
      if (error.graphQLErrors[0]?.extensions.errors === 'Kindly verify') {
        navigate(pagePaths.EMAIL_CERTIFICATION, {
          state: {
            userId: variables.email,
            link: pagePaths.LOGIN,
            notCertification: true,
          },
        });
      } else {
        openModal(modals.dialog, {
          title: t('auth.login.alertPopupLoginFailed'),
          message: error.message,
        });
      }
    },
  });

  const onSubmit = ({ email, password }) => {
    loginUser({ variables: { email, password } });
  };

  useLayoutEffect(() => {
    if (user.email && getAccessToken()) {
      navigate(DEFAULT_PAGE, { replace: false });
    }
  }, [navigate, user.email]);

  if (loginLoading) return <Spinner isLoading={loginLoading} />;

  return (
    <Container onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Header>
        <Link to={pagePaths.WELCOME}>
          <img src={Images.tmoWalletLogo} alt="" />
        </Link>
        <span className="signup">{t('auth.login.title')}</span>
      </Header>

      <FormWrapper>
        <FormBox>
          <label htmlFor="userEmail">Email</label>
          <CustomInput
            id="userEmail"
            type="email"
            autoComplete="off"
            placeholder={t('auth.login.email.ph')}
            register={register}
            name="email"
            options={{
              required: t('auth.login.alertEssential'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('auth.login.alertEmailType'),
              },
            }}
          />
          {errors.email && <small role="alert">{errors.email.message}</small>}
        </FormBox>

        <FormBox>
          <PasswordInput
            id="userPw"
            name="password"
            label="Password"
            placeholder={t('auth.login.password.ph')}
            register={register}
            useToggle
            options={{
              required: t('auth.login.alertEssential'),
            }}
          />
          {errors.password && <small role="alert">{errors.password.message}</small>}
        </FormBox>
      </FormWrapper>
      <ButtonsWrapper>
        <Button disabled={!isValid}>{t('auth.login.submit')}</Button>

        <Link to={pagePaths.FIND_PASSWORD} className="forget-password">
          {t('auth.login.forgotPassword')}
        </Link>
      </ButtonsWrapper>
    </Container>
  );
}

const Container = styled.form`
  padding: 80px 39px 64px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${W_COLOR};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 129px;
    height: auto;

    margin-bottom: 23px;
  }

  .signup {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 0.88;
  }

  .subtitle {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;

    margin-top: 19px;
  }
`;

const FormWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 30px;
`;

const FormBox = styled('div')`
  label {
    margin-left: 19px;
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
  }

  input {
    border-radius: 30px;
    border: 1px solid ${LIGHT_COLOR};
    background: transparent;
    box-shadow: none;
    height: 55px;

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.25;

    &:focus {
      border: 1px solid ${B_COLOR};
    }
  }

  small {
    padding-left: 20px;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-weight: 400;
    line-height: 20px; /* 153.846% */

    &[role='alert'] {
      color: ${RED_COLOR};
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & .forget-password {
    color: ${TEXT_GRAY};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;

    margin-top: 20px;
  }
`;

const Button = styled('button')`
  border-radius: 15px;
  margin: 0 13px;
  height: 60px;

  background-color: ${B_COLOR};
  color: ${W_COLOR};

  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 17px;
  font-weight: 400;

  &:disabled {
    background: ${G_COLOR};
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 17px;
    font-weight: 400;
  }
`;
