import { gql } from '@apollo/client';

// const CREATE_CELEBE_AUTH = gql`
// mutation($code: String!) {
//   createCelebeAuth(input: { code: $code }) {
//     status
//     message
//   }
// }
// `;

const CREATE_USER_COIN_WALLET = gql`
  mutation ($coin_id: Int!, $code: String) {
    createUserCoinWallet(input: { coin_id: $coin_id, code: $code }) {
      status
      message
    }
  }
`;

const RELEASE_INFO = gql`
  query getFrontRelease {
    getFrontRelease(service_type: app) {
      service_type
      released_at
    }
  }
`;

export {
  // CREATE_CELEBE_AUTH,
  CREATE_USER_COIN_WALLET,
  RELEASE_INFO,
};
