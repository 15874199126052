import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import FormButton from '@views/shared/forms/FormButton';
import { Icons } from '@/assets/images';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR } from '@/constants';

const Step1 = ({ onChangeStep }) => {
  const user = useSelector((state) => state.user);
  const { t } = useTranslate();
  const [agree, setAgree] = useState(false);

  return (
    <Container>
      <Box>
        <Title>{t('settings.personalData.deleteAccount.title1')}</Title>
        <Text>﹒{user.email}</Text>
      </Box>
      <Box>
        <Title>{t('settings.personalData.deleteAccount.title2')}</Title>
        <Text>﹒{t('settings.personalData.deleteAccount.desc1')}</Text>
        <Text>﹒{t('settings.personalData.deleteAccount.desc2')}</Text>
        <Text>﹒{t('settings.personalData.deleteAccount.desc3')}</Text>
        <Text>﹒{t('settings.personalData.deleteAccount.desc4')}</Text>
      </Box>
      <Box>
        <Title>{t('settings.personalData.deleteAccount.title3')}</Title>
        <Text>﹒{t('settings.personalData.deleteAccount.desc5')}</Text>
      </Box>

      <div className="btn-wrapper">
        <Button type="button" onClick={() => setAgree((prev) => !prev)}>
          <img src={agree ? Icons.circleCheckBoxOn : Icons.circleCheckBoxOff} alt="" />
          <span>{t('settings.personalData.deleteAccount.agree')}</span>
        </Button>
        <div style={{ padding: '0 22px' }}>
          <FormButton
            text={t('common.next')}
            color="black"
            disabled={!agree}
            onClick={() => {
              onChangeStep(2);
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default Step1;

const Container = styled.div`
  padding: 36px 30px;

  .btn-wrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 120px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px; /* 164.286% */
`;

const Text = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  color: #111;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 500;
`;
