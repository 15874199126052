import { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import NoResult from '@views/shared/noResult';
import { useQuery } from '@apollo/client';
import { useInView } from 'react-intersection-observer';
import formatAmount from '@/utils/formatAmount';
import { useTranslate } from '@/hooks/useTranslate';
import { Observer } from '@/views/shared';
import { MY_COIN_TRANSFERS_BY_COIN_ID } from '@/graphql/Coins';
import PartialLoading from '@/views/shared/spinner/PartialLoading';
import { Icons } from '@/assets/images';
import { B_COLOR, PRI_COLOR, TEXT_GRAY } from '@/constants';
import { shortenAddress } from '@/utils';

export default function TransactionList({ showTransactionDetail, coinId }) {
  const { t } = useTranslate();
  const [ref, inView] = useInView();

  const { data, loading, error, fetchMore } = useQuery(MY_COIN_TRANSFERS_BY_COIN_ID, {
    variables: {
      coin_id: Number(coinId),
      page: 1,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (inView && data && !loading) {
      const pageInfo = data.myCoinTransactionsByCoinId.paginatorInfo;
      const nextPage = pageInfo.currentPage + 1;

      if (nextPage > pageInfo.lastPage) return;

      fetchMore({
        variables: {
          coin_id: Number(coinId),
          page: nextPage,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const latestPage = prev.myCoinTransactionsByCoinId.paginatorInfo.currentPage;
          const updatePage = fetchMoreResult.myCoinTransactionsByCoinId.paginatorInfo.currentPage;

          if (!fetchMoreResult) return prev;
          if (latestPage >= updatePage) return prev; // 중복된 페이지 데이터 방지

          return {
            ...prev,
            myCoinTransactionsByCoinId: {
              ...prev.myCoinTransactionsByCoinId,
              paginatorInfo: fetchMoreResult.myCoinTransactionsByCoinId.paginatorInfo,
              data: [
                ...prev.myCoinTransactionsByCoinId.data,
                ...fetchMoreResult.myCoinTransactionsByCoinId.data,
              ],
            },
          };
        },
      });
    }
  }, [coinId, data, fetchMore, inView, loading]);

  if (loading) {
    return <PartialLoading />;
  }

  return (
    <Container>
      {data?.myCoinTransactionsByCoinId.data?.length > 0 ? (
        data.myCoinTransactionsByCoinId.data.map((item) => {
          if (item.type === 'send') {
            return (
              <ItemWrapper
                key={item.id}
                role="button"
                tabIndex="0"
                onClick={() => showTransactionDetail({ ...item })}
              >
                <img src={Icons.send} alt="send" />
                <Info flex={1}>
                  <span>{t('token.send')}</span>
                  <span className="detail">
                    {moment(item.created_at).format('YYYY-MM-DD')}﹒To:
                    {shortenAddress(item.to_wallet_address)}
                  </span>
                </Info>
                <div>
                  <div className="amount send">
                    -{formatAmount(item.amount)} {item.coin.acronym}
                  </div>
                  {/* <div className="price">534,041원</div> */}
                  {/* TODO: price 가격 반영 필요 */}
                </div>
              </ItemWrapper>
            );
          }

          if (item.type === 'receive') {
            return (
              <ItemWrapper
                key={item.id}
                role="button"
                tabIndex="0"
                onClick={() => showTransactionDetail({ ...item })}
              >
                <img src={Icons.receive} alt="receive" />
                <Info flex={1}>
                  <span>{t('token.receive')}</span>
                  <span className="detail">
                    {moment(item.created_at).format('YYYY-MM-DD')}﹒from:
                    {shortenAddress(item.from_wallet_address)}
                  </span>
                </Info>
                <div>
                  <div className="amount">
                    {formatAmount(item.amount)} {item.coin.acronym}
                  </div>
                  {/* <div className="price">534,041원</div> */}
                </div>
              </ItemWrapper>
            );
          }

          return null;
        })
      ) : (
        <NoResultWrapper>
          <NoResult title={t('common.noSend')} text={t('common.noSendText')} />
        </NoResultWrapper>
      )}
      <Observer ref={ref} />
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 32px;
`;

const NoResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 20px;
`;

const ItemWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .detail {
    color: ${TEXT_GRAY};
    font-size: 12px;
    font-weight: 400;
    word-break: break-word;
  }

  .amount {
    font-size: 16px;
    font-weight: 500;
    white-space: no-wrap;
  }

  .amount.send {
    color: ${PRI_COLOR};
  }

  .price {
    text-align: right;
    color: ${TEXT_GRAY};
    font-size: 12px;
    font-weight: 400;
  }
`;

const Info = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 400;
  overflow-wrap: break-word;
  margin-right: 6px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: ${({ flex }) => flex || 0};
`;
