import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    id : '',
    token : '',
    selected : false,
    trncList : [],                  // 검색조건 있는 전송목록
    selectedToken : {},             // 선택된 토큰의 정보를 셋팅
    searchToken : {
        token : 0
    },                // 검색 조건 토큰
    paginatorInfo : {
        page : 1,
        perPage : 10,
        hasMorePages : false
    },
    isFilter : false,
    mode : 'SEARCH_ALL'
};

export const trancSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setTab: (state, action) => {
            state.tab = action.payload;
        },
        // 검색조건이 있는 리스트를 셋팅한다.
        retrieve: (state, action) => {
            state.trncList = action.payload;
        },
        retrieveAdd: (state, action) => {
            state.trncList = [...state.trncList,...action.payload];
        },
        // 리스트를 초기화한다.
        resetList : (state) => {
            state.trncList = initialState.trncList;
        },
        // 선택한 토큰의 정보를 셋팅한다.
        setSelectedToken : (state, action) =>{
            state.selectedToken = action.payload;
        },
        // 검색조건을 셋팅한다.
        setSearchToken : (state,action) =>{
            state.searchToken = {...state.searchToken,...action.payload
            };
        },
        // 페이징 정보를 셋팅한다.
        setPaginatorInfo : (state, action)=>{
            state.paginatorInfo = action.payload;
        },
        setFiltered : (state,action)=>{
            state.isFilter = action.payload;
        },
        setMode : (state, action)=>{
            state.mode = action.payload;
        },
        resetTrancSlice : (state)=>initialState
    },
});


export const {  setTab,
                retrieve,
                setMode,
                setSearchToken,
                setPaginatorInfo,
                setFiltered,
                resetList,
                retrieveAdd,
                resetTrancSlice
                } = trancSlice.actions;
export default trancSlice;