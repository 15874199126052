import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useQuery } from '@apollo/client';
import { CHECK_WALLET } from '@/graphql/Users';
import useModals from '@/hooks/useModal';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import ReissueWalletBtn from './ReissueWallet';
import { B_COLOR, LIGHT_COLOR } from '@/constants';
import { Icons } from '@/assets/images';
import { setHasWallet } from '@/redux/reducer/userSlice';
import { ListItem } from '../personal';

export default function Security() {
  const { openModal } = useModals();
  const dispatch = useDispatch();

  const { t } = useTranslate();

  const { pin, hasWallet } = useSelector((state) => state.user);

  useQuery(CHECK_WALLET, {
    onCompleted: (data) => {
      const { status } = data.checkEthereumUserCoinWalletExists;
      if (status === 'success') {
        dispatch(setHasWallet(true));
      }
      if (status === 'fail') {
        dispatch(setHasWallet(false));
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Container>
      <ul>
        {pin ? (
          // 결제 비밀번호 변경
          <ListItem>
            <Link to={pagePaths.SETTINGS_CHANGE_PIN}>
              <span>{t('settings.security.changePin')}</span>
              <img src={Icons.detail} alt="" />
            </Link>
          </ListItem>
        ) : (
          // 결제 비밀번호 등록
          <ListItem>
            <Link to={pagePaths.SETTINGS_REGISTER_PIN} state={{ hasWallet }}>
              <span>{t('settings.security.registerPin')}</span>
              <img src={Icons.detail} alt="" />
            </Link>
          </ListItem>
        )}

        {/* 개인키 확인 */}
        <ListItem>
          <Link to={pagePaths.SETTINGS_RESTORE_PKEY}>
            <span>{t('settings.security.findPrivateKey')}</span>
            <img src={Icons.detail} alt="" />
          </Link>
        </ListItem>

        {/* 지갑 재발급 */}
        {hasWallet && <ReissueWalletBtn />}
      </ul>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 44px;

  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 18px;
  font-weight: 400;
`;
