import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  keyword: '%%',
  notices: {
    '%%': [],
  },
  pageInfo: {},
};

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    setKeyword: (state, action) => {
      state.keyword = action.payload;
    },
    setNoticePageInfo: (state, action) => {
      state.pageInfo = action.payload;
    },
    setNotices: (state, action) => {
      state.notices = action.payload;
      const { keyword, data } = action.payload;
      state.notices = {
        ...state.notices,
        [keyword]: data,
      };
    },
    updateNotice: (state, action) => {
      const { keyword, data } = action.payload;
      state.notices = {
        ...state.notices,
        [keyword]: [...state.notices[keyword], ...data],
      };
    },
    resetBoardSlice: (state) => initialState,
  },
});

export const { setKeyword, setNoticePageInfo, setNotices, updateNotice, resetBoardSlice } =
  boardSlice.actions;
export default boardSlice;
