import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Banner from '@views/shared/banner/Banner';
import { useQuery } from '@apollo/client';
import TokenList from '@views/pages/wallet/components/assets/token';
import SearchInput from '@views/shared/forms/SearchInput';
import { useEffect } from 'react';
import { useTranslate } from '@/hooks/useTranslate';
import CreateWallet from './components/CreateWallet';
import { setFilterKeyword } from '@/redux/reducer/walletSlice';
import Notice from './components/Notice';
import { CHECK_WALLET } from '@/graphql/Users';
import PartialLoading from '@/views/shared/spinner/PartialLoading';
import { setHasWallet } from '@/redux/reducer/userSlice';

export default function Wallet() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { hasWallet } = useSelector((state) => state.user);

  useQuery(CHECK_WALLET, {
    onCompleted: (data) => {
      const { status } = data.checkEthereumUserCoinWalletExists;
      if (status === 'success') {
        dispatch(setHasWallet(true));
      }
      if (status === 'fail') {
        dispatch(setHasWallet(false));
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleSearch = (value) => {
    dispatch(setFilterKeyword(value.keyword));
  };

  const getContent = () => {
    if (hasWallet === 'unchecked') {
      return <PartialLoading />;
    }

    if (hasWallet) {
      return <TokenList />;
    }
    return <CreateWallet />;
  };

  // 키워드 초기화
  useEffect(() => {
    dispatch(setFilterKeyword(''));
  }, [dispatch]);

  return (
    <Container>
      <Banner />
      <Notice />

      {hasWallet && (
        <div className="input-wrapper">
          <SearchInput onSubmit={handleSearch} placeholder={t('common.search.placeholder')} />
        </div>
      )}
      {getContent()}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding: 30px 15px 60px;

  .input-wrapper {
    margin-bottom: 20px;
  }
`;
