import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Icons } from '@assets/images';
import { pagePaths } from '@/navigation/Pages';
import { W_COLOR } from '@/constants';

export default function Menu() {
  return (
    <Container>
      <StyleLink to={pagePaths.BOARD} img={Icons.newsMenu} imgon={Icons.newsOnMenu} />
      <StyleLink to={pagePaths.SWAP} img={Icons.pointMenu} imgon={Icons.pointOnMenu} />
      <StyleLink to={pagePaths.POINT} img={Icons.earnMenu} imgon={Icons.earnMenuOn} />
      {/* TODO: 지갑부분 주석처리 */}
      {/* <StyleLink to={pagePaths.WALLET} img={Icons.walletMenu} imgon={Icons.walletOnMenu} /> */}
      <StyleLink to={pagePaths.SETTINGS} img={Icons.moreMenu} imgon={Icons.moreOnMenu} />
    </Container>
  );
}

const Container = styled.ul`
  display: flex;
  justify-content: space-evenly;
  gap: 24px;
  background: ${W_COLOR};
  border-radius: 40px 40px 0px 0px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.11);
  padding: 15.5px 12px 18.5px;
`;

const StyleLink = styled(NavLink)`
  width: 54px;
  height: 54px;

  background: transparent url(${(props) => props.img}) no-repeat center;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.active {
    background: transparent url(${(props) => props.imgon}) no-repeat center;
  }
`;
