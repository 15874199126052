import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Tooltip } from 'react-tooltip';
import DOMPurify from 'dompurify';
import FormButton from '@/views/shared/forms/FormButton';
import { Icons, Images } from '@/assets/images';
import { pagePaths } from '@/navigation/Pages';
import { RECEIVE_REWARD, REWARD_AVAIL_AMOUNT } from '@/graphql/Users';
import { B_COLOR, W_COLOR } from '@/constants';
import formatAmount from '@/utils/formatAmount';
import { useTranslate } from '@/hooks/useTranslate';
import { MY_REWARD_LIST_BY_MONTH } from '@/graphql/Swap';
import { GET_DEFAULT_COIN_INFO } from '@/graphql/Coins';

const Point = () => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { data } = useQuery(REWARD_AVAIL_AMOUNT, { fetchPolicy: 'cache-and-network' });

  const [receiveReward, { loading }] = useMutation(RECEIVE_REWARD, {
    refetchQueries: [
      { query: MY_REWARD_LIST_BY_MONTH, variables: { month: 0, year: 0 } }, // 리워드목록
      { query: REWARD_AVAIL_AMOUNT }, // 적립가능포인트
      { query: GET_DEFAULT_COIN_INFO }, // 내 포인트
    ],

    onCompleted: ({ receiveAllReward }) => {
      if (receiveAllReward.status === 'success') {
        navigate(pagePaths.POINT_HISTORY);
      }
    },
  });

  return (
    <Container>
      <PointBox>
        <Row className="title">
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(t('point.accumulate.title')),
            }}
          />
          <img
            src={Icons.detailInfo}
            alt="detail"
            data-tooltip-id="reward-point"
            data-tooltip-html={t('point.accumulate.tooltip')}
            data-tooltip-place="bottom"
          />
          <Tooltip id="reward-point" />
        </Row>

        <Row className="point">
          {data ? (
            `${formatAmount(data.getRewardAbleAmount.amount)} P`
          ) : (
            <img src={Images.partialLoading} alt="" style={{ width: '50px', height: '50px' }} />
          )}
        </Row>

        <Row className="btn-wrapper">
          <FormButton
            text={t('point.accumulate.btn')}
            color="black"
            flex={1}
            disabled={data?.getRewardAbleAmount.amount === 0}
            onClick={() => receiveReward()}
          />
        </Row>

        <Row
          className="history"
          onClick={() => {
            navigate(pagePaths.POINT_HISTORY);
          }}
        >
          <img src={Icons.cash} alt="" />
          <span className="text">
            {t('point.accumulate.text1')}{' '}
            <strong>{formatAmount(data?.getRewardAbleAmount.monthly_amount) || 0} P</strong>{' '}
            {t('point.accumulate.text2')}
          </span>
          <img src={Icons.detailRight} alt="" />
        </Row>
      </PointBox>

      <EventBox>
        <Title>{t('point.event.title')}</Title>
        <img src={Images.sampleBanner1} alt="" />
        <img src={Images.sampleBanner2} alt="" />
        <img src={Images.sampleBanner3} alt="" onClick={() => navigate(pagePaths.CHECKIN)} />
      </EventBox>

      <EventBox>
        <Title>{t('point.guide.title')}</Title>
        <GuideBox>{t('point.guide.desc1')}</GuideBox>
        <GuideBox>{t('point.guide.desc2')}</GuideBox>
      </EventBox>
    </Container>
  );
};

export default Point;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 15px;
  overflow-y: auto;
  gap: 50px;
  width: 100%;

  & .banner-wrapper {
    margin-top: 20px;
  }
`;

const PointBox = styled.div`
  border-radius: 15px;
  background: ${W_COLOR};
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  padding: 30px 22px 15px;
  text-align: center;
  height: auto;

  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 400;

  .title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    strong {
      margin-right: 4px;
    }

    img {
      margin-left: 4px;
    }
  }

  .point {
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;

    margin-bottom: 35px;
  }

  .btn-wrapper {
    padding: 0 8px;
    width: 100%;

    margin-bottom: 36px;
  }

  .history {
    display: flex;
    justify-content: space-between;
    gap: 9px;

    font-size: 14px;
    font-weight: 400;

    .text {
      text-align: left;
      flex: 1;
    }
  }
`;

const EventBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GuideBox = styled.div`
  border-radius: 15px;
  background: var(--light, #eeedf4);
  padding: 30px 22px;

  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 500;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  color: ${B_COLOR}l
  font-family: 'Spoqa Han Sans Neo';
  font-size: 22px;
  font-weight: 400;
  line-height: 23px; /* 104.545% */
`;
