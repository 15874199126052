export function useFetchMoreData(fetchMore, fieldToUpdate) {
  const fetchMoreData = (currentPage) => {
    fetchMore({
      variables: {
        page: currentPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const combinedData = [...prev[fieldToUpdate].data, ...fetchMoreResult[fieldToUpdate].data];

        return {
          [fieldToUpdate]: {
            ...fetchMoreResult[fieldToUpdate],
            data: combinedData,
          },
        };
      },
    });
  };

  return fetchMoreData;
}
