import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  qrCode: '',
};

export const scanSlice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    setQrCode: (state, action) => {
      state.qrCode = action.payload;
    },
  },
});

export const { setQrCode } = scanSlice.actions;
export default scanSlice;
