import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { B_COLOR, PRI_COLOR } from '@/constants';

const FaqDetail = () => {
  const { state } = useLocation();
  return (
    <Container>
      <div className="type">이용 가이드</div>
      <div className="title">{state.subject}</div>
      <div className="content" dangerouslySetInnerHTML={{ __html: state.content }} />
    </Container>
  );
};

export default FaqDetail;

const Container = styled.div`
  padding: 45px 15px;

  .type {
    color: ${PRI_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;

    margin-bottom: 5px;
  }

  .title {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 500;
    line-height: 35px; /* 145.833% */

    margin-bottom: 24px;
  }

  .content {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
  }
`;
