import { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

import FormButton from '@views/shared/forms/FormButton';
import { Icons, Images } from '@assets/images';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OPTIONS } from '@/constants/options';
import { DEFAULT_PAGE, pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { initializeLocale, setLocale } from '@/utils/locale';

import { setLocale as dispatchSetLocale } from '@/redux/reducer/userSlice';
import { LIGHT_COLOR } from '@/constants';
import { getAccessToken } from '@/services/HandleToken';

export default function Welcome() {
  const { t } = useTranslate();
  const locale = initializeLocale();
  const [selectLocale, setSelectLocale] = useState(locale);
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleLocaleChange = ({ target }) => {
    setSelectLocale(target.value);
    dispatch(dispatchSetLocale(target.value));
  };

  useEffect(() => {
    setLocale(locale);
  }, [locale]);

  useLayoutEffect(() => {
    if (email && getAccessToken()) {
      navigate(DEFAULT_PAGE, { replace: false });
    }
  }, [navigate, email]);

  return (
    <Container>
      <div className="img-wrapper">
        <img src={Images.tmoWalletLogo} alt="" />
      </div>
      <div className="content">
        <div className="btn-wrapper">
          <FormButton link={pagePaths.LOGIN} text={t('auth.login')} color="black" />
          <FormButton link={pagePaths.SIGNUP} text={t('auth.login.signUp')} color="white" />
        </div>
        <SelectWrapper>
          <img src={Icons.language} alt="" />
          <Select name="lang" onChange={handleLocaleChange} defaultValue={selectLocale}>
            {OPTIONS.LOCALE.map((option) => (
              <option key={option.value} value={option.value} code={option.code}>
                {option.label}
              </option>
            ))}
          </Select>
        </SelectWrapper>
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  .img-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 173px;
      height: auto;
    }
  }

  .content {
    height: 44%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
`;

const SelectWrapper = styled.div`
  margin-top: 60px;
  border-radius: 5px;
  background: ${LIGHT_COLOR};
  min-width: 100px;
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
`;

const Select = styled.select`
  appearance: none;
  cursor: pointer;
  background: transparent;
  border: none;
  text-align: center;
  flex: 1;
  padding: 0 20px;

  &::-ms-expand {
    display: none;
  }
`;
