import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { Images } from '@assets/images';
import FormButton from '@views/shared/forms/FormButton';
import SwapResult from '@views/pages/token/components/SwapResult';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR } from '@/constants';

/* 스왑 성공화면 */
export default function SwapSuccess() {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { state } = useLocation();

  const resultType = useMemo(() => {
    const { type } = state.swapInfo;

    if (type === 'import') {
      return t('swap.resultTitle.import');
    }

    if (type === 'export') {
      return t('swap.resultTitle.export');
    }
  }, [state.swapInfo, t]);

  const resultStatus = useMemo(() => {
    const { status } = state.swapInfo;

    if (status === 'COMPLETED') {
      return t('swap.resultTitle.complete');
    }

    if (status === 'PENDING') {
      return t('swap.resultTitle.pending');
    }

    if (status === 'CANCEL') {
      return t('swap.resultTitle.cancel');
    }
  }, [state.swapInfo, t]);

  const handlePage = () => {
    navigate(pagePaths.SWAP);
  };

  return (
    <Container>
      <ContentWrapper>
        <img src={Images.sendComplete} alt="" />
        <Title>
          <b>{resultType}</b> {resultStatus}
        </Title>
        <SwapResult swapInfo={state.swapInfo} />
      </ContentWrapper>

      <BtnWrapper>
        <FormButton text={t('common.confirm')} color="black" onClick={handlePage} />
      </BtnWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 85px 32px 56px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;

  img {
    margin-bottom: 50px;
    margin-right: 20px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BtnWrapper = styled.div`
  width: 100%;
`;

const Title = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;

  b {
    font-weight: 700;
  }
`;
