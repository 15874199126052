import styled from 'styled-components';

import { Images } from '@assets/images';
import FormButton from '@views/shared/forms/FormButton';

import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR } from '@/constants';
import { MY_WALLETS } from '@/graphql/Coins';
import { setMyWalletInfo } from '@/redux/reducer/userSlice';

export default function CreateSuccessWallet() {
  const { state } = useLocation();
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);

  useQuery(MY_WALLETS, {
    onCompleted: ({ myCoinWallets }) => {
      dispatch(setMyWalletInfo(myCoinWallets));
      setDisabled(false);
    },
  });

  return (
    <Container>
      <Content>
        <img src={Images.wallet} alt="" />

        <div className="tit">{t('wallet.create.success.title')}</div>
        <div className="txt">{t('wallet.create.success.subtitle1')}</div>
        <div className="txt">{t('wallet.create.success.subtitle2')}</div>
      </Content>
      <ButtonWrapper>
        <FormButton
          link={state.link}
          text={t('common.confirm')}
          color="black"
          flex={1}
          disabled={disabled}
        />
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  padding: 62px 52px 36px;
`;

const Content = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 112px;
    height: auto;
  }

  .tit {
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;

    margin-top: 56px;
    margin-bottom: 42px;
  }

  .txt {
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
    white-space: pre-wrap;
  }
`;

const ButtonWrapper = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  width: 100%;
`;
