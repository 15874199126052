import { GlobalStyle } from '@assets/styles/GlobalStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import Routes from '@/router/Routes';

import LocaleProvider from './services/LocaleProvider';
import TutorialHandler from './services/TutorialHandler';
import { INITIAL_DATA } from './graphql/SiteGuide';
import { setDefaultCoinInfo } from './redux/reducer/swapSlice';
import { setHasWallet } from './redux/reducer/userSlice';
import { setCoins } from './redux/reducer/walletSlice';

function App() {
  const dispatch = useDispatch();
  const { email, locale } = useSelector((state) => state.user);

  // RouteChangeTracker();
  // UpdateChecker();
  // NetworkChecker();
  // PopupHandler();

  useQuery(INITIAL_DATA, {
    skip: !locale || !email,
    variables: { locale },
    onCompleted: ({ getBalanceByDefaultCoin, checkEthereumUserCoinWalletExists, tokenCoins }) => {
      dispatch(setDefaultCoinInfo(getBalanceByDefaultCoin)); // tmopoint info 전역 저장
      dispatch(setCoins(tokenCoins)); // tokeninfo 전역 저장
      // 지갑 유무 전역 저장
      if (checkEthereumUserCoinWalletExists.status === 'success') {
        dispatch(setHasWallet(true));
      } else if (checkEthereumUserCoinWalletExists.status === 'fail') {
        dispatch(setHasWallet(false));
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return (
    <LocaleProvider>
      <GlobalStyle />
      <Routes />
      <TutorialHandler />
    </LocaleProvider>
  );
}

export default App;
