import styled from 'styled-components';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import { Link } from 'react-router-dom';

import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { Icons } from '@/assets/images';
import { B_COLOR, G_COLOR, TEXT_GRAY } from '@/constants';

const PinInput = forwardRef(
  (
    {
      isForgot,
      length,
      isPassword,
      placeholder,
      onComplete,
      children,
      additionalBtnName,
      additionalHandler,
    },
    ref,
  ) => {
    const keyboardRef = useRef();
    const [pinNumber, setPinNumber] = useState('');
    const { t } = useTranslate();

    const clearPin = () => {
      setPinNumber('');
      keyboardRef.current.clearInput();
    };

    useImperativeHandle(ref, () => ({
      clearPin: () => clearPin(),
    }));

    const handleChange = (value) => {
      setPinNumber(value);
    };

    useEffect(() => {
      if (pinNumber.length === length) {
        onComplete(pinNumber);
      }
    }, [pinNumber]);

    return (
      <Container>
        {children}
        <PasswordBoardWrapper>
          {Array.from({ length }, (_, index) => (
            <div key={index + 1} className={index < pinNumber.length ? 'fill' : 'circle'} />
          ))}
          {additionalBtnName && additionalHandler && (
            <LinkWrapper>
              <span onClick={additionalHandler}>{additionalBtnName}</span>
            </LinkWrapper>
          )}
        </PasswordBoardWrapper>
        <input
          maxLength={length}
          type={isPassword ? 'password' : ''}
          value={pinNumber}
          placeholder={placeholder}
          onChange={() => {}}
          onPaste={(e) => {
            const pastedText = e.clipboardData.getData('text').slice(0, 6).replace(/\D/g, '');
            setPinNumber(pastedText);
          }}
          readOnly
        />
        {isForgot && (
          <Link className="forgot" to={pagePaths.SETTINGS_SECURITY}>
            {t('token.send.forgotPin')}
          </Link>
        )}
        <Keyboard
          layout={{
            default: ['1 2 3', '4 5 6', '7 8 9', '0 {bksp}'],
          }}
          mergeDisplay
          display={{
            '{bksp}': `<img src="${Icons.bksp}" alt="bksp" />`,
          }}
          onChange={handleChange}
          keyboardRef={(r) => {
            keyboardRef.current = r;
          }}
        />
      </Container>
    );
  },
);

export default PinInput;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  flex: 1;

  input {
    display: none;
  }

  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${G_COLOR};
  }

  .fill {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #111111;
  }

  .tit {
    color: #000;
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 22px;
    font-weight: 400;
    line-height: 23px; /* 104.545% */

    margin-bottom: 14px;
  }

  .txt {
    color: ${B_COLOR};
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .forgot {
    color: ${TEXT_GRAY};
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }

  .simple-keyboard {
    .hg-rows {
      .hg-row {
        display: flex;
        justify-content: flex-end;
        .hg-button {
          display: flex;
          font-size: 36px;
          font-weight: 700;
          height: 9.5vh;
          flex: 0 1 33.33%;
          justify-content: center;
          align-items: center;
          &:active {
            border-radius: 20px;
            background: rgba(217, 217, 217, 0.2);
          }
        }
      }
    }
  }
`;

const PasswordBoardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0 60px;
  position: relative;
`;

const LinkWrapper = styled.div`
  position: absolute;
  top: 48px;

  color: ${TEXT_GRAY};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
`;
