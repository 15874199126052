import styled from 'styled-components';
import { useSelector } from 'react-redux';

import formatAmount from '@/utils/formatAmount';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, PRI_COLOR, TEXT_GRAY } from '@/constants';

/** 스왑결과 팝업
 * */
export default function SwapResult({ swapInfo }) {
  const { t } = useTranslate();
  const { locale } = useSelector((state) => state.user);
  const swap = useSelector((state) => state.swap);

  const tmopName = locale === 'KOREAN' ? swap.TMOP_INFO.coin.name : swap.TMOP_INFO.coin.name_en;
  const exportCoinName =
    locale === 'KOREAN' ? swapInfo.exportCoin.name : swapInfo.exportCoin.name_en;
  const importCoinName =
    locale === 'KOREAN' ? swapInfo.importCoin.name : swapInfo.importCoin.name_en;

  return (
    <Container>
      <Row>
        <dt>{exportCoinName}</dt>
        <dd className="highlight">{`- ${formatAmount(swapInfo.export_amount)} ${
          swapInfo.exportCoin.unit
        }`}</dd>
      </Row>
      <Row>
        <dt>{importCoinName}</dt>
        <dd>{`+ ${formatAmount(swapInfo.import_amount)} ${swapInfo.importCoin.unit}`}</dd>
      </Row>
      {swapInfo.import_eth_amount > 0 && (
        <>
          <Row>
            <dt>{t('swap.result.method')}</dt>
            <dd>{t('swap.result.method.eth')}</dd>
          </Row>
          <Row>
            <dt>{t('swap.resultEth')}</dt>
            <dd>{`${swapInfo.import_eth_amount} ETH`}</dd>
          </Row>
        </>
      )}
      {swapInfo.reward > 0 && (
        <Row>
          <dt>
            {t('swap.info.reward')}
            <span className="name"> ({tmopName})</span>
          </dt>
          <dd>{`${formatAmount(swapInfo.reward)} P`}</dd>
        </Row>
      )}
      <Row>
        <dt>
          {t('swap.resultFee')}
          <span className="name"> ({importCoinName})</span>
        </dt>
        <dd>{`${formatAmount(swapInfo.fee)} ${swapInfo.importCoin.unit}`}</dd>
      </Row>
    </Container>
  );
}

const Container = styled.dl`
  width: 100%;
  margin: 60px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  dt {
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 400;
  }

  dd {
    color: ${B_COLOR};
    text-align: right;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 400;
  }

  .highlight {
    color: ${PRI_COLOR};
    font-weight: 700;
  }
`;
