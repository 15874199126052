import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import QrGenerator from '@views/pages/token/components/QrGenerator';
import Spinner from '@/views/shared/spinner/Spinner';
import { MY_PERSONAL_INFO } from '@/graphql/Users';
import { pagePaths } from '@/navigation/Pages';
import formatAmount from '@/utils/formatAmount';
import { useTranslate } from '@/hooks/useTranslate';

export default function ReceiveToken() {
  const { state } = useLocation();
  const { loading, error, data } = useQuery(MY_PERSONAL_INFO);
  const { t } = useTranslate();
  const navigate = useNavigate();

  const selectedCoinWallet = data?.currentUser.userCoinWallets.find(
    (wallet) => wallet.coin_id === Number(state.coinId),
  );

  if (loading) return <Spinner isLoading={loading} />;
  if (error) {
    navigate(pagePaths.ERROR, { state: { error: error.message } });
    return null;
  }

  return (
    <Container>
      <QrGenerator address={selectedCoinWallet?.wallet_address} acronym={state.coinUnit} />
    </Container>
  );
}

const Container = styled.div`
  padding: 40px 50px;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
`;
