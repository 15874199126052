import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Decimal } from 'decimal.js';
import { useSelector } from 'react-redux';

import SendForm from '@views/pages/token/components/SendForm';
import FormButton from '@views/shared/forms/FormButton';
import { Icons } from '@assets/images';
import { modals } from '@views/shared/modal/Modals';
import { useLazyQuery } from '@apollo/client';
import useModals from '@/hooks/useModal';
import formatAmount from '@/utils/formatAmount';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { GET_BALANCE } from '@/graphql/Coins';
import { B_COLOR, G_COLOR, W_COLOR } from '@/constants';
import { shortenAddress } from '@/utils';

export default function SendToken() {
  const user = useSelector((state) => state.user);
  const sendFormRef = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const { openModal } = useModals();
  const { t } = useTranslate();
  const [amount, setAmount] = useState(state?.amount || '');
  const [convertedAmount, setConvertedAmount] = useState('');
  const [getBalance, { data: ethBalance }] = useLazyQuery(GET_BALANCE);
  const coin = useSelector((state) => state.wallet.coin);
  const { locale } = useSelector((state) => state.user);
  const coinName = useMemo(() => {
    if (locale === 'KOREAN') {
      return coin.name;
    }
    return coin.name_en;
  }, [coin.name, coin.name_en, locale]);

  const setCurrencyRate = (value) => {
    if (!value) {
      setConvertedAmount('');
      return;
    }

    const symbol = user.locale === 'KOREAN' ? 'KRW' : 'USD';
    const price = state.coinPrice.find((price) => price.symbol === symbol);
    const rate = price ? price.price : 0;
    const result = new Decimal(value).times(rate).toDecimalPlaces(4, Decimal.ROUND_FLOOR);
    setConvertedAmount(result.toString());
  };

  const handleScanPage = () => {
    window.getQrCode();
  };

  const handleSendCheck = () => {
    const targetAddress = document.querySelector('#receiverAddress').value;
    if (!user.pin) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('token.send.alertPopupNoPin'),
        callback: () => {
          navigate(pagePaths.SETTINGS_SECURITY);
        },
      });
      return;
    }

    if (!amount) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('token.send.alertPopupNoAmount'),
      });
      return;
    }
    if (Number(amount) === 0) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('token.send.alertPopupNoZeroAmount'),
      });
      return;
    }
    if (!targetAddress) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('token.send.alertPopupNoAddress'),
      });
      return;
    }
    if (!/^(0x)?[0-9a-f]{40}$/i.test(targetAddress)) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('token.send.alertPopupInvalidAddress'),
      });
      return;
    }
    if (state.coinId === '1' && amount < state.coinFee) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('token.send.alertPopupMinAmount'),
      });
      return;
    }

    const myEth = ethBalance?.getBalance.amount || 0;
    if (state.coinId === '2' && myEth < state.coinFee) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('token.send.alertPopupMinFee'),
      });
      return;
    }
    if (
      (state.coinId === '1' && amount > state.coinBalance - state.coinFee) ||
      (state.coinId === '2' && amount > state.coinBalance)
    ) {
      sendFormRef.current.clearAmount();
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('token.send.alertPopupMaxAmount'),
      });
      return;
    }
    openModal(modals.sendConfirm, {
      title: `${formatAmount(amount)} ${state.coinUnit} ${t('token.send.alertPopupSendConfirm')}`,
      target: `${t('token.send.alertPopupAddress')}: ${shortenAddress(targetAddress)}`,
      fee: `${t('token.send.alertPopupFee')}: ${formatAmount(state.coinFee)} ${state.coinFeeUnit}`,
      logo: coin.image.url || Icons.defaultLogo2,
      onSubmit: () => {
        navigate(pagePaths.TOKEN_CHECK_PIN, {
          state: {
            target: targetAddress,
            tokenId: state.coinId,
            tokenName: state.coinUnit,
            amount,
            fee: state.coinFee,
          },
        });
      },
    });
  };

  useEffect(() => {
    getBalance({
      variables: {
        coin_id: 2,
      },
    });
  }, [getBalance]);

  // TODO: 원화변경로직 중복인거 같은데 확인필요
  useEffect(() => {
    setCurrencyRate(amount);
  }, [amount]);

  return (
    <Container>
      <Header>{t('header.send.title')}</Header>
      <Title>
        <span className="bold">{`${coinName}(${coin.acronym})`}</span>
        {locale === 'KOREAN' ? ' 을(를)' : ''}
      </Title>
      <Title>{t('token.send.confirmation')}</Title>

      <Info>
        <div>
          <span>
            {t('token.send.available')} {coinName}
          </span>
          <span className="amount">
            {formatAmount(state.coinBalance)} {state.coinUnit}
          </span>
        </div>
        <div>
          <span>{t('token.send.fee')}</span>
          <span className="amount">
            {formatAmount(state.coinFee)} {state.coinFeeUnit}
          </span>
        </div>
      </Info>

      <Form>
        <div className="box">
          <SendForm
            ref={sendFormRef}
            coinId={state.coinId}
            balance={state.coinBalance}
            fee={state.coinFee}
            convertedAmount={convertedAmount}
            userLocale={user.locale}
            setAmount={setAmount}
            setCurrencyRate={setCurrencyRate}
          />
          <Receiver>
            <input
              id="receiverAddress" // id 유지해야함 qr복사한값이 여기로 들어옴
              type="text"
              placeholder={t('token.send.addressPlaceholder')}
              autoComplete="off"
            />

            <img src={Icons.qrscanBtn} alt="" onClick={handleScanPage} />
          </Receiver>
        </div>
        <div className="btn-wrapper">
          <FormButton text={t('common.confirm')} color="black" onClick={handleSendCheck} />
        </div>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 16px 15px 42px;
  width: 100%;
`;

const Header = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 22px;
  font-weight: 400;
  line-height: 23px;

  margin-bottom: 50px;
`;

const Title = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;

  .bold {
    font-size: 20px;
    font-weight: 700;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  color: ${B_COLOR};
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;

  div {
    display: flex;
    justify-content: space-between;
  }

  .amount {
    text-align: right;
    font-size: 20px;
    font-weight: 500;
  }

  margin-top: 20px;
  margin-bottom: 32px;
`;

const Receiver = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-top: 30px;

  input {
    height: 60px;
    width: calc(100% - 73px);

    border-radius: 15px;
    background: ${W_COLOR};
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
    border: 0;
    padding-left: 24px;

    font-family: 'Spoqa Han Sans Neo';
    font-size: 17px;
    font-weight: 400;
  }

  input::placeholder {
    color: ${G_COLOR};
  }
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .box {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .btn-wrapper {
    margin-top: 24%;
    padding: 0 30px;
    width: 100%;
    max-width: 440px;
  }
`;
