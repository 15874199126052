import React from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';
import { useSelector } from 'react-redux';
import { useTranslate } from '@/hooks/useTranslate';
import { TEXT_GRAY } from '@/constants';

function SwapListSelectBox({ handleSelectBox, optionList, type }) {
  const { t } = useTranslate();
  const { locale } = useSelector((state) => state.user);

  function getOptionList(_optionList, _type) {
    // 활성화 된 리스트체크
    const filteredList = _optionList.filter((el) => el.is_active > 0);
    switch (_type) {
      case 'swapList':
        return filteredList.map((item, index) => (
          <option key={`${item}${index}`} value={item.id}>
            {`${item.exportCoin.name} > ${item.importCoin.name}`}
          </option>
        ));
      case 'rewardList':
        return filteredList.map((item, index) => (
          <option key={`${item}${index}`} value={item.id}>
            {locale === 'ENGLISH' ? item.coin.name_en : item.coin.name}
          </option>
        ));
      default:
    }
  }

  return (
    <SelectContainer className="select">
      <select onChange={(e) => handleSelectBox(e)}>
        <option value="0" key={0}>
          {t('swap.list.filterAll')}
        </option>
        {optionList && getOptionList(optionList, type)}
      </select>
      <ChevronDown size={16} />
    </SelectContainer>
  );
}

export default SwapListSelectBox;

const SelectContainer = styled('div')`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  position: relative;
  border: 1px solid ${TEXT_GRAY};
  border-radius: 7px;
  color: ${TEXT_GRAY};
  padding: 0 10px;

  select {
    border: 0;
    background: none;
    font-size: 15px;
    color: ${TEXT_GRAY};
    font-weight: 500;
    height: 35px;
    text-align: center;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-right: 5px;

    option {
      text-align-last: center;
      text-align: center;
      -ms-text-align-last: center;
      -moz-text-align-last: center;
    }
  }
`;
