import { gql } from '@apollo/client';

/*  MY_COIN_SWAP_LIST
 * 스왑 이력 정보 목록을 가져온다.
 * $coin_swap_id : 코인 스왑 id
 * $page 페이징 넘버링
 * $perPage 페이지당 정보 개수
 */

// where: {
//   AND: [
//     {
//       OR: [
//         { column: SUBJECT, operator: LIKE, value: $keyword }
//         { column: CONTENT, operator: LIKE, value: $keyword }
//       ]
//     }
//   ]
// }
const MY_COIN_SWAP_LIST = gql`
  query myCoinSwaps($coin_swap_id: Int!, $page: Int!, $created_at: Mixed) {
    myCoinSwaps(
      coin_swap_id: $coin_swap_id
      where: { column: CREATED_AT, operator: GTE, value: $created_at }
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: 15
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
        currentPage
      }
      data {
        id
        type
        coin_swap_id
        exportCoin {
          id
          name
          name_en
          symbol
          acronym
          unit
          image {
            url
          }
        }
        export_amount
        importCoin {
          id
          name
          name_en
          symbol
          acronym
          unit
          image {
            url
          }
        }
        import_amount
        fee
        reward
        status
        swap_hash
        ip
        created_at
        updated_at
      }
    }
  }
`;

/* DO_SWAP
 * 스왑을 실행 하는 쿼리 (mutation)
 * $send_coin_id : ID! - 스왑하는 코인(토큰)id
 * $receive_coin_id : ID!- 스왑되는(받는) 코인(토큰)id
 * $send_amount :Float! - 보내는 코인의 양
 * */
const DO_SWAP = gql`
  mutation swapAmount($id: ID!, $amount: Float!, $pin: String, $isSwapWithEth: Boolean) {
    swapAmount(
      input: { id: $id, amount: $amount, pin: $pin, is_import_eth_amount: $isSwapWithEth }
    ) {
      id
      user_id
      coin_swap_id
      type
      exportCoin {
        id
        name
        name_en
        symbol
        acronym
        unit
      }
      export_amount
      importCoin {
        id
        name
        name_en
        symbol
        acronym
        unit
      }
      import_amount
      fee
      reward
      import_eth_amount
      status
      swap_hash
    }
  }
`;

// 인증 이메일을 보낸다.
const VERIFY_SWAP_EMAIL = gql`
  mutation {
    sendSwapVerifyEmail {
      status
      message
    }
  }
`;
/*
 * 인증 번호를 체크한다.
 * $certification_number - 인증번호
 */
const CHECK_SWAP_VERIFY = gql`
  mutation ($certification_number: String!) {
    checkSwapVerify(input: { certification_number: $certification_number }) {
      status
      message
    }
  }
`;

/* 스왑건에 대한 환불을 진행한다
 * param
 * id : 내역 id
 *
 * */
const DO_REFUND = gql`
  mutation swapRefund($id: ID!) {
    swapRefund(id: $id) {
      status
      message
    }
  }
`;

/* 베이스가되는 티모포인트의 정보를 가져온다. */
const GET_DEFAULT_COIN_INFO = gql`
  query getBalanceByDefaultCoin {
    getBalanceByDefaultCoin {
      coin {
        id
        name
        name_en
        symbol
        acronym
        unit
        created_at
        image {
          url
        }
      }
      amount
      balanced_at
    }
  }
`;

/* COINS_LIST_ALL
 * 데이터 가공을 위한 스왑 목록을 가져온다
 * 모든 내보내기 목록, 가져오기 목록을 가져옴
 */
const COINS_LIST_ALL = gql`
  query coinSwaps {
    exportCoinSwaps {
      id
      type
      coin_id
      ratio
      fee_rate
      fee
      reward
      reward_rate
      minimum_amount
      is_integer
      isGetBalance
      exportCoin {
        id
        name
        name_en
        symbol
        acronym
        unit
        image {
          url
        }
      }
      importCoin {
        id
        name
        name_en
        symbol
        acronym
        unit
        image {
          url
        }
      }
    }

    importCoinSwaps {
      id
      type
      coin_id
      ratio
      fee_rate
      fee
      reward
      reward_rate
      minimum_amount
      is_integer
      isGetBalance
      exportCoin {
        id
        name
        name_en
        symbol
        acronym
        unit
        image {
          url
        }
      }
      importCoin {
        id
        name
        name_en
        symbol
        acronym
        unit
        image {
          url
        }
      }
    }
  }
`;

const CHECK_SWAP = gql`
  query checkSwapAmount($swapId: ID!, $amount: Float!) {
    checkSwapAmount(input: { id: $swapId, amount: $amount }) {
      skip
      swapAmount {
        import_amount
        fee
        reward
      }
      swapAmountIncludeImportEthAmount {
        import_amount
        fee
        reward
        import_eth_amount
      }
    }
  }
`;

const COIN_SWAP_COMMENT = gql`
  query coinSwapComment($id: ID!) {
    coinSwapComment(id: $id) {
      comment
    }
  }
`;

const MY_COIN_REWARD_LIST = gql`
  query myCoinRewards($coin_reward_id: Int!, $page: Int!) {
    myCoinRewards(
      coin_reward_id: $coin_reward_id
      where: {}
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: 20
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
        currentPage
      }
      data {
        id
        coin_reward_id
        coinReward {
          minimum_amount
          reward_rate
          reward
          coin {
            id
            name
            name_en
            symbol
            unit
            image {
              url
            }
          }
        }
        amount
        status
        reward_hash
        ip
        created_at
        updated_at
      }
    }
  }
`;

const MY_REWARD_LIST_BY_MONTH = gql`
  query myUserRewardsByMonth($year: Int, $month: Int) {
    myUserRewardsByMonth(year: $year, month: $month) {
      next_year
      next_month
      data {
        id
        user_id
        user_coin_swap_id
        user_coin_reward_id
        event_reward_id
        type
        reward
        status
        created_at
        updated_at
      }
    }
  }
`;

export {
  MY_COIN_SWAP_LIST,
  DO_SWAP,
  VERIFY_SWAP_EMAIL,
  CHECK_SWAP_VERIFY,
  CHECK_SWAP,
  DO_REFUND,
  GET_DEFAULT_COIN_INFO,
  COINS_LIST_ALL,
  COIN_SWAP_COMMENT,
  MY_COIN_REWARD_LIST,
  MY_REWARD_LIST_BY_MONTH,
};
