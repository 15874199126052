export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const EXPIRED_AT = 'EXPIRED_AT';
export const SYSTEM_CHECK = 'isSystemChecking';
export const LATEST_UPDATE = 'latestUpdateTime';
export const UPDATE_CHECK_TIME = 1800000;
export const TUTORIAL_AT = 'TUTORIAL_AT';

export const POPUP_INFO = 'popup_info';

export const PRI_COLOR = '#A855F7';
export const B_COLOR = '#111';
export const W_COLOR = '#FFF';
export const G_COLOR = '#d9d9d9';
export const RED_COLOR = '#E90000';
export const BG_COLOR = '#fcfcfc';
export const LIGHT_COLOR = '#EEEDF4';
export const TEXT_COLOR = '#060A28';
export const TEXT_GRAY = '#7F7F7F';
