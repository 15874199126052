import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import FormButton from './FormButton';
import { B_COLOR } from '@/constants';
import { useTranslate } from '@/hooks/useTranslate';

const MnemonicFiled = ({ onSubmit }) => {
  const { t } = useTranslate();
  const { register, handleSubmit, setValue, watch } = useForm();
  const btnDisable =
    watch(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']).filter((el) => el)
      .length !== 12;

  const spreadText = (e) => {
    // 복사한 텍스트를 공백 기준으로 배열로 자름
    const copyText = e.target.value.split(' ');
    if (copyText.length < 2) {
      return;
    }

    // 카피한 값을 각각 요소를 value값에 셋팅한다.
    setValue('1', copyText[0]);
    setValue('2', copyText[1]);
    setValue('3', copyText[2]);
    setValue('4', copyText[3]);
    setValue('5', copyText[4]);
    setValue('6', copyText[5]);
    setValue('7', copyText[6]);
    setValue('8', copyText[7]);
    setValue('9', copyText[8]);
    setValue('10', copyText[9]);
    setValue('11', copyText[10]);
    setValue('12', copyText[11]);
  };

  //   const clearInput = () => {
  //     const inputs = document.querySelectorAll('.recover');
  //     inputs.forEach((input) => {
  //       input.value = '';
  //     });
  //   };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper>
        <input
          type="text"
          className="recover"
          {...register('1')}
          onChange={spreadText}
          placeholder="1."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('2')}
          placeholder="2."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('3')}
          placeholder="3."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('4')}
          placeholder="4."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('5')}
          placeholder="5."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('6')}
          placeholder="6."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('7')}
          placeholder="7."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('8')}
          placeholder="8."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('9')}
          placeholder="9."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('10')}
          placeholder="10."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('11')}
          placeholder="11."
          autoComplete="off"
        />
        <input
          type="text"
          className="recover"
          {...register('12')}
          placeholder="12."
          autoComplete="off"
        />
      </InputWrapper>
      <div className="btn-wrapper">
        {/* <FormButton
                text={t('common.inputReset')}
                rounded
                color="second"
                onClick={clearInput}
              /> */}
        <FormButton type="submit" text={t('common.confirm')} color="black" disabled={btnDisable} />
      </div>
    </Form>
  );
};

export default MnemonicFiled;

const Form = styled.form`
  margin-top: 58px;

  .btn-wrapper {
    margin-top: 64px;
    padding: 0 30px;
  }
`;

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 13px;

  input {
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
    border: none;
    height: 50px;

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 700;
    line-height: 23px;
  }
`;
