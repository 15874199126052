import { useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

export default function DeleteAccount() {
  const [step, setStep] = useState(1);

  const handleStep = (_value) => {
    setStep(_value);
  };

  return (
    <>
      {step === 1 && <Step1 onChangeStep={handleStep} />}
      {step === 2 && <Step2 onChangeStep={handleStep} />}
      {step === 3 && <Step3 />}
    </>
  );
}
