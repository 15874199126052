import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FormButton from '@views/shared/forms/FormButton';
import { modals } from '@views/shared/modal/Modals';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useTranslate } from '@/hooks/useTranslate';
import { pagePaths } from '@/navigation/Pages';
import useModals from '@/hooks/useModal';
import { B_COLOR, G_COLOR } from '@/constants';
import { CREATE_WALLET } from '@/graphql/Coins';
import { CHECK_WALLET, REISSUE_WALLET } from '@/graphql/Users';

function WalletCodeConfirm({ userId, backupCodeText, hiddenIdx, onToggleClick, from, pin }) {
  const { t } = useTranslate();
  const backupCodeArray = backupCodeText.split(' ');

  const [hiddenTexts, setHiddenTexts] = useState(
    Array.from({ length: backupCodeArray.length }, () => ''),
  );

  const { openModal } = useModals();
  const navigate = useNavigate();

  const [createWallet, { loading: crateWalletLoading }] = useMutation(CREATE_WALLET, {
    refetchQueries: [{ query: CHECK_WALLET }],
  });

  const [reissuWallet, { loading: reissueWalletLoading }] = useMutation(REISSUE_WALLET, {
    refetchQueries: [{ query: CHECK_WALLET }],
  });

  const onClickConfirm = () => {
    const words = document.querySelectorAll('.word');
    const backupCode = Array.from(words)
      .map((word) => word.value.trim())
      .join(' ');

    if (backupCode.length === 0 || Array.from(words).some((word) => !word.value.trim())) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('auth.backupWallet.alertPopupInputError'),
      });
      return;
    }

    if (backupCode === backupCodeText) {
      // 지갑생성 버튼을 통해서 진입한 경우
      if (from === 'create_wallet') {
        createWallet({
          variables: { syntax: backupCode, pin },
          onCompleted: ({ createDefaultWallet }) => {
            if (createDefaultWallet.status === 'success') {
              navigate(pagePaths.CREATE_SUCCESS_WALLET, {
                state: {
                  link: pagePaths.WALLET,
                },
              });
            } else {
              openModal(modals.dialog, {
                title: t('common.info'),
                message: createDefaultWallet.message,
                callback: () => navigate(pagePaths.WALLET, { replace: true }),
              });
            }
          },
        });

        // 지갑 재발급을 통해서 진입한 경우
      } else if (from === 'reissue_wallet') {
        reissuWallet({
          variables: { syntax: backupCode, pin },
          onCompleted: ({ createEthereumUserCoinWallet }) => {
            if (createEthereumUserCoinWallet.status === 'success') {
              openModal(modals.dialog, {
                title: t('common.info'),
                message: t('settings.security.reissueWallet.success'),
                callback: () => navigate(pagePaths.SETTINGS_SECURITY, { replace: true }),
              });
            } else {
              openModal(modals.dialog, {
                title: t('common.info'),
                message: createEthereumUserCoinWallet.message,
                callback: () => navigate(pagePaths.WALLET, { replace: true }),
              });
            }
          },
        });
      } else {
        navigate(pagePaths.SWAP);
      }
    } else {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('auth.backupWallet.alertPopupCodeError'),
      });
    }
  };

  return (
    <>
      <Header>
        <div className="step">step 3</div>
        <div className="title">{t('auth.backupWallet.step2.title')}</div>
        <div className="subtitle">{t('auth.backupWallet.step2.subtitle')}</div>
      </Header>
      <InputForm>
        {backupCodeArray.map((word, i) => {
          if (hiddenIdx.includes(i)) {
            return (
              <input
                type="text"
                className="word"
                placeholder={i + 1}
                autoComplete="off"
                value={hiddenTexts[i]}
                onChange={(e) => {
                  if (e.target.value.length > 1) {
                    const newHiddenTexts = [...hiddenTexts];
                    const word = e.target.value.split(' ')[i];
                    newHiddenTexts[i] = word;
                    setHiddenTexts(newHiddenTexts);
                  } else {
                    const newHiddenTexts = [...hiddenTexts];
                    newHiddenTexts[i] = e.target.value;
                    setHiddenTexts(newHiddenTexts);
                  }
                }}
              />
            );
          }
          return (
            <input
              type="text"
              className="word"
              placeholder={i + 1}
              autoComplete="off"
              value={word}
            />
          );
        })}
      </InputForm>
      <BtnWrapper>
        <FormButton
          text={t('common.confirm')}
          onClick={onClickConfirm}
          color="black"
          flex={1}
          disabled={crateWalletLoading || reissueWalletLoading}
        />
      </BtnWrapper>
    </>
  );
}

export default WalletCodeConfirm;

const Header = styled.div`
  color: ${B_COLOR};
  width: 100%;

  .step {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 700;

    margin-bottom: 10px;
  }

  .title {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 400;

    margin-bottom: 10px;
  }

  .subtitle {
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
  }

  margin-bottom: 56px;
`;

const InputForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 45px;

  input {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    width: calc(50% - 12px);
    border-radius: 15px;
    border: none;
    background: white;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
  }

  input::placeholder {
    color: ${G_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
  }
`;

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 0 24px;
  flex: 1;
`;
