import { Images } from '@assets/images';
import ReceiveToken from '@views/pages/token/receiveToken';
import Login from '@views/pages/auth/Login';
import Logout from '@views/pages/auth/Logout';
import Signup from '@views/pages/auth/Signup';
import Error from '@views/pages/error/Error';
import Conditions from '@views/pages/settings/termsAndPrivacy/privacy';
import Terms from '@views/pages/settings/termsAndPrivacy/terms';
import TermsAndCondition from '@views/pages/settings/termsAndPrivacy';
import Personal from '@views/pages/settings/personal';
import Security from '@views/pages/settings/security/Security';
import ChangePassword from '@views/pages/settings/personal/changePassword';
import ChangePIN from '@views/pages/settings/security/pin/ChangePIN';
import Welcome from '@views/Welcome';
import EmailCertification from '@views/pages/auth/EmailCertification';
import ScanQRCode from '@views/pages/token/scanQRCode';
import SendComplete from '@views/pages/token/sendToken/SendComplete';
import Test from '@views/pages/test';
import RestorePKey from '@views/pages/settings/security/pkey/RestorePKey';
import RegisterPKey from '@views/pages/settings/security/pkey/RegisterPKey';
import EmailVerify from '@views/pages/web/EmailVerify';
import SwapSuccess from '@views/pages/token/components/SwapSuccess';
import FindPassword from '@views/pages/auth/FindPassword';
import SettingPassword from '@views/pages/web/SettingPassword';
import RegisterPIN from '@views/pages/settings/security/pin/RegisterPIN';
import VerifyInputForm from '@views/pages/token/swapToken/components/verifyInputForm';
import BackupWallet from '@views/pages/auth/BackupWallet';
import Language from '@views/Language';
import Tutorial from '@/views/pages/tutorial/Tutorial';
import SwapHistory from '@/views/pages/swap/swapHistory/SwapHistory';
import SendToken from '@/views/pages/token/sendToken/SendToken';
import Token from '@/views/pages/token/Token';
import FAQs from '@/views/pages/settings/faqs/Faq';
import ExternalServiceLink from '@/views/pages/wallet/externalServiceLink/ExternalServiceLink';
import BoardDetail from '@/views/pages/board/detail/BoardDetail';
import SwapForm from '@/views/pages/swap/swapForm/SwapForm';
import Transaction from '@/views/pages/transaction/Transaction';
import Board from '@/views/pages/board/Board';
import Settings from '@/views/pages/settings/Settings';
import Wallet from '@/views/pages/wallet/Wallet';
import SwapPoint from '@/views/pages/swap/SwapPoint';
import WalletReissueEmailVerify from '@/views/pages/settings/security/WalletReissueEmailVerify';
import RewardList from '@/views/pages/swap/rewardList';
import DeleteAccount from '@/views/pages/settings/personal/deleteAccount/DeleteAccount';
import ConnectList from '@/views/pages/settings/personal/ConnectList';
import FaqDetail from '@/views/pages/settings/faqs/FaqDetail';
import CreateSuccessWallet from '@/views/pages/wallet/CreateSuccessWallet';
import TokenCheckPin from '@/views/pages/token/sendToken/checkPIN/TokenCheckPin';
import SwapCheckPin from '@/views/pages/token/sendToken/checkPIN/SwapCheckPin';
import ResetPinByMnemonic from '@/views/pages/settings/security/pin/ResetPinByMnemonic';
import WalletCheckpin from '@/views/pages/wallet/components/WalletCheckpin';
import Point from '@/views/pages/point/Point';
import CheckIn from '@/views/pages/test/CheckIn';
import PointHistory from '@/views/pages/point/PointHistory';

// *는 안쓰는건데 테스트용

export const pagePaths = {
  WALLET: '/wallet',
  CREATE_WALLET_CHECK_PIN: '/wallet/checkpin', // 지갑생성이전 pin 체크
  REISSUE_WALLET_CHECK_PIN: '/setting/reissue/checkpin', // 지갑 재발급 이전 pin 체크
  EXTERNAL_SERVICE_LINK: '/swap/link', // 연동하기 페이지
  TOKEN: '/wallet/token/:token', // 토큰 전송, 받기
  TOKEN_SEND: '/wallet/token/:token/send', // 토큰 전송
  TOKEN_SEND_COMPLETE: '/wallet/token/:token/send_complete',
  TOKEN_RECEIVE: '/wallet/:token/receive', // 토큰 받기
  TOKEN_SCAN: '/wallet/token/:token/scan', // * 큐알 리더 화면(미사용)
  TOKEN_CHECK_PIN: '/token/check_pin',
  SWAP_CHECK_PIN: '/swap/check_pin',
  TRANSACTION: '/transaction', // 토큰 전송내역
  BOARD: '/board', // 공지사항
  BOARD_DETAIL: '/board/:id', // 공지사항 세부
  FAQ_DETAIL: '/settings/faqs/:id', // Faq 세부
  SETTINGS: '/settings',
  SETTINGS_PERSONAL: '/settings/personal',
  SETTINGS_CHANGE_PASSWORD: '/settings/personal/change_pw',
  SETTINGS_DELETE_ACCOUNT: '/settings/personal/delete_account',
  SETTINGS_SECURITY: '/settings/security',
  SETTINGS_REGISTER_PIN: '/settings/security/register_pin',
  SETTINGS_CHANGE_PIN: '/settings/security/change_pin',
  SETTINGS_RESET_PIN_BY_MNEMONIC: '/settings/security/change_pin/check_mnemonic',
  SETTINGS_RESTORE_PKEY: '/settings/security/restore_pkey',
  SETTINGS_REGISTER_PKEY: '/settings/security/register_pkey',
  SETTINGS_FAQS: '/settings/faqs',
  SETTINGS_TERMS_CONDITION: '/settings/terms_condition',
  SETTINGS_TERMS: '/settings/terms_condition/terms',
  SETTINGS_CONDITION: '/settings/terms_condition/privacy',
  SETTINGS_CONNECT_LIST: '/settings/connected_service',
  LOGIN: '/auth/login',
  LOGOUT: '/auth/logout',
  SIGNUP: '/auth/signup',
  WALLET_BACKUP: '/auth/backup', // 니모닉 확인
  CREATE_SUCCESS_WALLET: '/auth/created/wallet', // 지갑생성 완료
  FIND_PASSWORD: '/auth/find_password',
  EMAIL_CERTIFICATION: '/auth/email_certification', // 가입회원 메일 인증 안내 페이지
  TUTORIAL: '/tutorial', // * 가이드
  WELCOME: '/',
  LANGUAGE: '/language',
  VERIFY_EMAIL_SWAP: '/swap/certification',
  VERIFY_EMAIL_WALLET_REISSUE: '/setting/security/reissue_wallet/certification',
  SWAP: '/swap',
  SWAP_FORM: '/swap/swapForm',
  POINT: '/point',
  SWAP_HISTORY: '/swap/history', // 포인트 전환 내역
  POINT_HISTORY: '/point/history', // 포인트 리워드 내역
  REWARD_HISTORY: '/swap/rewardList', // 리워드 내역
  SWAP_SUCCESS: '/token/components/SwapSuccess', // 스왑 결과
  EMAIL_VERIFY: '/email-verify',
  FORGET_PASSWORD: '/forget-password', // 비밀번호 재설정 페이지 이메일 >
  TEST: '/test',
  CHECKIN: '/point/test',
  ERROR: '/error',
};
export const DEFAULT_PAGE = pagePaths.POINT;

export const pageTitles = [
  { path: pagePaths.WALLET, page: 'wallet' },
  { path: pagePaths.EXTERNAL_SERVICE_LINK, page: 'link' },
  { path: pagePaths.TOKEN_SEND, page: 'send' },
  { path: pagePaths.TOKEN_RECEIVE, page: 'receive' },
  { path: pagePaths.TOKEN_SCAN, page: 'scan' },
  { path: pagePaths.SWAP, page: 'swap' },
  { path: pagePaths.SWAP_FORM, page: 'swap' },
  { path: pagePaths.POINT, page: 'point' },
  { path: pagePaths.SWAP_HISTORY, page: 'swapList' },
  { path: pagePaths.POINT_HISTORY, page: 'pointList' },
  { path: pagePaths.REWARD_HISTORY, page: 'rewardList' },
  { path: pagePaths.VERIFY_EMAIL_SWAP, page: 'swapCertification' },
  { path: pagePaths.TRANSACTION, page: 'transaction' },
  { path: pagePaths.BOARD, page: 'board' },
  { path: pagePaths.SETTINGS, page: 'settings' },
  { path: pagePaths.SETTINGS_PERSONAL, page: 'personal' },
  { path: pagePaths.SETTINGS_CHANGE_PASSWORD, page: 'changePassword' },
  { path: pagePaths.SETTINGS_CONNECT_LIST, page: 'connectList' },
  { path: pagePaths.SETTINGS_DELETE_ACCOUNT, page: 'deleteAccount' },
  { path: pagePaths.SETTINGS_SECURITY, page: 'security' },
  { path: pagePaths.SETTINGS_REGISTER_PIN, page: 'registerPin' },
  { path: pagePaths.SETTINGS_CHANGE_PIN, page: 'changePin' },
  { path: pagePaths.VERIFY_EMAIL_WALLET_REISSUE, page: 'reissueWalletCertification' },
  { path: pagePaths.SETTINGS_FAQS, page: 'faqs' },
  { path: pagePaths.SETTINGS_TERMS_CONDITION, page: 'termsCondition' },
  { path: pagePaths.SETTINGS_TERMS, page: 'terms' },
  { path: pagePaths.SETTINGS_CONDITION, page: 'condition' },
  { path: pagePaths.LOGIN, page: 'login' },
  { path: pagePaths.SIGNUP, page: 'signup' },
  { path: pagePaths.FIND_PASSWORD, page: 'findPassword' },
  { path: pagePaths.WALLET_BACKUP, page: 'backup' },
];

export const defaultPages = [
  { path: pagePaths.WELCOME, component: <Welcome /> },
  { path: pagePaths.LANGUAGE, component: <Language /> },
  { path: pagePaths.LOGIN, component: <Login /> },
  { path: pagePaths.SIGNUP, component: <Signup /> },
  { path: pagePaths.LOGOUT, component: <Logout /> },
  { path: pagePaths.WALLET_BACKUP, component: <BackupWallet /> },
  { path: pagePaths.EMAIL_CERTIFICATION, component: <EmailCertification /> },
  { path: pagePaths.CREATE_SUCCESS_WALLET, component: <CreateSuccessWallet /> },
  { path: pagePaths.TUTORIAL, component: <Tutorial /> },
  { path: pagePaths.TOKEN_SEND_COMPLETE, component: <SendComplete /> },
  { path: pagePaths.SWAP_SUCCESS, component: <SwapSuccess /> },
  { path: pagePaths.EMAIL_VERIFY, component: <EmailVerify /> },
  { path: pagePaths.FORGET_PASSWORD, component: <SettingPassword /> },
  { path: pagePaths.SETTINGS_RESET_PIN_BY_MNEMONIC, component: <ResetPinByMnemonic /> },
  { path: pagePaths.SETTINGS_RESTORE_PKEY, component: <RestorePKey /> },
  { path: pagePaths.SETTINGS_REGISTER_PIN, component: <RegisterPIN /> },
  { path: pagePaths.SETTINGS_CHANGE_PIN, component: <ChangePIN /> },
  { path: pagePaths.VERIFY_EMAIL_SWAP, component: <VerifyInputForm /> },
  { path: pagePaths.VERIFY_EMAIL_WALLET_REISSUE, component: <WalletReissueEmailVerify /> },
  { path: pagePaths.TOKEN_CHECK_PIN, component: <TokenCheckPin /> },
  { path: pagePaths.SWAP_CHECK_PIN, component: <SwapCheckPin /> },
  { path: '*', component: <Error /> },
];

export const menuPages = [
  { path: pagePaths.SETTINGS_REGISTER_PKEY, component: <RegisterPKey /> },
  { path: pagePaths.BOARD_DETAIL, component: <BoardDetail /> },
  { path: pagePaths.FAQ_DETAIL, component: <FaqDetail /> },
  { path: pagePaths.SETTINGS_FAQS, component: <FAQs /> },
  { path: pagePaths.TOKEN, component: <Token /> },
  { path: pagePaths.TOKEN_SEND, component: <SendToken /> },
  { path: pagePaths.TOKEN_RECEIVE, component: <ReceiveToken /> },
  { path: pagePaths.SETTINGS_TERMS, component: <Terms /> },
  { path: pagePaths.SETTINGS_CONDITION, component: <Conditions /> },
  { path: pagePaths.CREATE_WALLET_CHECK_PIN, component: <WalletCheckpin /> },
  { path: pagePaths.REISSUE_WALLET_CHECK_PIN, component: <WalletCheckpin /> },
  { path: pagePaths.SWAP_FORM, component: <SwapForm /> },
  { path: pagePaths.CHECKIN, component: <CheckIn /> },
];

export const headerPages = [
  { path: pagePaths.FIND_PASSWORD, component: <FindPassword /> },
  { path: pagePaths.TOKEN_SCAN, component: <ScanQRCode /> },
  { path: pagePaths.SETTINGS_CHANGE_PASSWORD, component: <ChangePassword /> },
  { path: pagePaths.SETTINGS_DELETE_ACCOUNT, component: <DeleteAccount /> },

  { path: pagePaths.TEST, component: <Test /> },
];

export const headerMenuPages = [
  // { path: pagePaths.WALLET, component: <Wallet /> }, TODO: 지갑페이지 임시 주석처리
  { path: pagePaths.SWAP, component: <SwapPoint /> },
  { path: pagePaths.POINT, component: <Point /> },
  { path: pagePaths.SWAP_HISTORY, component: <SwapHistory /> },
  { path: pagePaths.POINT_HISTORY, component: <PointHistory /> },
  { path: pagePaths.REWARD_HISTORY, component: <RewardList /> },
  { path: pagePaths.TRANSACTION, component: <Transaction /> },
  { path: pagePaths.BOARD, component: <Board /> },
  { path: pagePaths.SETTINGS, component: <Settings /> },
  { path: pagePaths.SETTINGS_CONNECT_LIST, component: <ConnectList /> },
  { path: pagePaths.SETTINGS_PERSONAL, component: <Personal /> },
  { path: pagePaths.SETTINGS_SECURITY, component: <Security /> },
  { path: pagePaths.SETTINGS_TERMS_CONDITION, component: <TermsAndCondition /> },
  { path: pagePaths.EXTERNAL_SERVICE_LINK, component: <ExternalServiceLink /> },
];

export const allowedPathnames = [
  pagePaths.WELCOME,
  pagePaths.WALLET_BACKUP,
  pagePaths.FIND_PASSWORD,
  pagePaths.EMAIL_CERTIFICATION,
  pagePaths.SIGNUP,
  pagePaths.LOGIN,
  pagePaths.LOGOUT,
  pagePaths.LANGUAGE,
  pagePaths.FORGET_PASSWORD,
  pagePaths.EMAIL_VERIFY,
];

export const mainPathnames = [
  pagePaths.SWAP,
  pagePaths.WALLET,
  pagePaths.TRANSACTION,
  pagePaths.BOARD,
  pagePaths.LOGIN,
  pagePaths.LANGUAGE,
];
