import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pagePaths } from '@/navigation/Pages';
import { getTutorialInfo } from './HandleToken';

// 외부에서 들어오 페이지와 튜토리얼 페이지 제외하고 튜토리얼을 본적없는 경우 리다이렉트
const TutorialHandler = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      pathname !== pagePaths.TUTORIAL &&
      pathname !== pagePaths.EMAIL_VERIFY &&
      pathname !== pagePaths.FORGET_PASSWORD &&
      pathname !== pagePaths.SETTINGS_TERMS &&
      pathname !== pagePaths.SETTINGS_CONDITION &&
      !getTutorialInfo()
    ) {
      navigate(pagePaths.TUTORIAL);
    }
  }, [navigate, pathname]);
};

export default TutorialHandler;
