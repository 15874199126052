import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';

import NoResult from '@views/shared/noResult';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { useTranslate } from '@/hooks/useTranslate';
import { removeHtmlTags } from '@/utils';
import { Observer } from '@/views/shared';
import { Images } from '@/assets/images';
import { B_COLOR, PRI_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';
import PartialLoading from '@/views/shared/spinner/PartialLoading';

export default function ArticleList({ getMoreNotice, initLoading }) {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const [ref, inView] = useInView();
  const { notices } = useSelector((state) => state.board);
  const { locale } = useSelector((state) => state.user);
  const {
    keyword,
    pageInfo: { hasMorePages, perPage },
  } = useSelector((state) => state.board);
  const currentPage = notices[keyword] ? Math.ceil(notices[keyword].length / perPage) : 1;

  const onNavigateClick = (_item) => {
    navigate(`/board/${_item.id}`, {
      state: {
        ..._item,
      },
    });
  };

  useEffect(() => {
    if (inView && hasMorePages) {
      getMoreNotice({
        variables: {
          locale,
          keyword,
          page: currentPage + 1,
        },
      });
    }
    // 의존성에 currentPage 추가시 notices length 조정에 의한 중복 호출 버그로 인한 주석처리
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMoreNotice, hasMorePages, inView, keyword, locale]);

  if (initLoading) {
    return <PartialLoading />;
  }

  return (
    <Container className="article">
      {notices[keyword]?.length > 0 ? (
        <>
          {notices[keyword].map((item) => (
            <Item key={item.id} onClick={() => onNavigateClick(item)}>
              <img src={item.thumbnail?.url || Images.sampleImg1} alt="" />

              <ContentWrapper>
                <div
                  style={{
                    height: '14.5px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <span className="type">{item.type}</span>
                  <span className="date">{moment(item.created_at).format('YYYY-MM-DD')}</span>
                </div>
                <div className="tit">{item.subject}</div>
                <div className="txt">{removeHtmlTags(item.content)}</div>
              </ContentWrapper>
            </Item>
          ))}
          <Observer ref={ref} />
        </>
      ) : (
        <NoResultWrapper>
          <NoResult title={t('common.noArticle')} text={t('board.noSearchResult')} />
        </NoResultWrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;

const Item = styled.div`
  height: 120px;
  display: flex;

  border-radius: 15px;
  background: ${W_COLOR};
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

  img {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 120px;
    height: 100%;
    object-fit: cover;
  }
`;

const ContentWrapper = styled.div`
  padding: 14px 15px;
  display: flex;
  flex-direction: column;
  flex: 1;

  .type {
    color: ${PRI_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 12px;
    font-weight: 400;
    margin-right: 7px;
  }

  .date {
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 12px;
    font-weight: 400;
  }

  .tit {
    margin-top: 8px;
    margin-bottom: 7px;

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .txt {
    display: -webkit-box;
    -webkit-line-clamp: 1; /* 원하는 줄 수로 설정 */
    -webkit-box-orient: vertical;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-weight: 400;
  }
`;

const NoResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;
