import React from 'react';
import styled from 'styled-components';
import FormButton from '@views/shared/forms/FormButton';
import { Icons } from '@/assets/images';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR } from '@/constants';
import useAuth from '@/hooks/useAuth';

const Step3 = () => {
  const { t } = useTranslate();
  const { expiredLogin } = useAuth();

  return (
    <Container>
      <Content>
        <img src={Icons.successCheckBox} alt="" />
        <div className="title">{t('settings.personalData.deleteAccount.complete.title')}</div>
        <div className="desc">{t('settings.personalData.deleteAccount.complete.desc')}</div>
      </Content>
      <div className="btn-wrapper">
        <FormButton color="black" text={t('common.confirm')} onClick={() => expiredLogin()} />
      </div>
    </Container>
  );
};

export default Step3;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 30px 15px 15%;

  img {
    margin: 52px 0;
  }

  .btn-wrapper {
    width: 100%;
    padding: 0 30px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 400;
    line-height: 30px; /* 125% */

    margin-bottom: 20px;
  }

  .desc {
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
  }
`;
