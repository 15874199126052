import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeTokenInfos } from '@services/HandleToken';
import { resetUser } from '@/redux/reducer/userSlice';
import { resetSwapSlice } from '@/redux/reducer/swapSlice';
import { resetTrancSlice } from '@/redux/reducer/trancSlice';
import { resetWalletSlice } from '@/redux/reducer/walletSlice';
import { pagePaths } from '@/navigation/Pages';

export default function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 로그인 만료 처리
  const expiredLogin = () => {
    removeTokenInfos();

    dispatch(resetUser());
    dispatch(resetWalletSlice());
    dispatch(resetTrancSlice());
    dispatch(resetSwapSlice());

    navigate(pagePaths.WELCOME);
    window.location.reload();
  };

  return { expiredLogin };
}
