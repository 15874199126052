import React from 'react';
import styled from 'styled-components';
import { Images } from '@/assets/images';

const PartialLoading = () => (
  <Container>
    <img src={Images.partialLoading} alt="" />
  </Container>
);

export default PartialLoading;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex: 1;

  img {
    width: 50px;
    height: 50px;
  }
`;
