import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FormButton from '@/views/shared/forms/FormButton';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { Images } from '@/assets/images';
import { B_COLOR, TEXT_GRAY } from '@/constants';
import useModals from '@/hooks/useModal';
import { modals } from '@/views/shared/modal/Modals';

const CreateWallet = () => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { pin } = useSelector((state) => state.user);
  const { openModal } = useModals();

  // 핀번호 등록한 경우 핀번호 확인이후 복구구문 생성
  const onClickCreateWallet = () => {
    if (pin) {
      navigate(pagePaths.CREATE_WALLET_CHECK_PIN, { state: { from: 'create_wallet' } });
    } else {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('wallet.create.withPin'),
        callback: () => {
          navigate(pagePaths.SETTINGS_SECURITY);
        },
      });
    }
  };

  return (
    <Container>
      <img src={Images.noWallet} alt="no-wallet" />
      <div className="title">{t('wallet.noWallet.title')}</div>
      <div className="subtitle">{t('wallet.noWallet.subtitle')}</div>
      <BtnWrapper>
        <FormButton
          text={t('wallet.create')}
          color="black"
          onClick={onClickCreateWallet}
          flex={1}
        />
      </BtnWrapper>
    </Container>
  );
};

export default CreateWallet;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  .title {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;

    margin-bottom: 8px;
  }

  .subtitle {
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;

    margin-bottom: 45px;
  }

  button {
    max-width: 320px;
  }
`;

const BtnWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  text-align: center;
`;
