import React, { useMemo, useState } from 'react';

import Modals from '@views/shared/modal/Modals';
import { ModalsDispatchContext, ModalsStateContext } from './ModalsContext';

export default function ModalsProvider({ children }) {
  const [openedModals, setOpenedModals] = useState([]);

  const open = (Component, props) => {
    setOpenedModals((modals) => [...modals, { Component, props }]);
  };

  const close = (Component) => {
    setOpenedModals((modals) => modals.filter((modal) => modal.Component !== Component));
  };

  const dispatch = useMemo(() => ({ open, close }), []);

  return (
    <ModalsStateContext.Provider value={openedModals}>
      <ModalsDispatchContext.Provider value={dispatch}>
        <Modals />
        {children}
      </ModalsDispatchContext.Provider>
    </ModalsStateContext.Provider>
  );
}
