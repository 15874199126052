import { gql } from '@apollo/client';

const GET_DEFAULT_COIN_INFO = gql`
  query getBalanceByDefaultCoin {
    getBalanceByDefaultCoin {
      coin {
        id
        name
        name_en
        symbol
        acronym
        unit
        created_at
        image {
          url
        }
      }
      amount
      balanced_at
    }
  }
`;

const COINS = gql`
  query {
    coins(orderBy: [{ column: ORDER_BY_NO, order: ASC }], first: 20, page: 1) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
      }
      data {
        id
        name
        name_en
        symbol
        acronym
        order_by_no
        created_at
        is_swap_export
        is_swap_import
        is_transaction
        coinNetworkInfo {
          network_fee
          gas_price
        }
        coinCurrencyPrices {
          symbol
          price
        }
        image {
          url
        }
      }
    }
  }
`;

const TOKEN_COINS = gql`
  query tokenCoins {
    tokenCoins {
      id
      name
      name_en
      symbol
      acronym
      unit
      is_token
      is_swap_import
      is_swap_export
      is_transaction
      is_private_key
      is_active
      order_by_no
      created_at
      updated_at
      image {
        url
      }
      coinCurrencyPrices {
        symbol
        price
      }
      coinNetworkInfo {
        network
        network_fee
        gas_price
        gas_limit
      }
    }
  }
`;

const COIN_LIST = gql`
  query {
    coins(orderBy: [{ column: CREATED_AT, order: ASC }]) {
      data {
        name
        id
      }
    }
  }
`;

const GET_BALANCE = gql`
  query getBalance($coin_id: ID!) {
    getBalance(coin_id: $coin_id) {
      coin {
        id
        name
        name_en
        symbol
        acronym
        created_at
        coinCurrencyPrices {
          symbol
          price
        }
        image {
          url
        }
      }
      amount
      balanced_at
    }
  }
`;

const MY_COIN_TRANSFERS = gql`
  query {
    myCoinTransactions(orderBy: [{ column: CREATED_AT, order: DESC }], first: 20, page: 1) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
      }
      data {
        id
        coin_id
        amount
        txhash
        from_wallet_address
        to_wallet_address
        ip
        created_at
        updated_at
        coin {
          id
          name
          name_en
          symbol
          acronym
          created_at
          image {
            url
          }
        }
      }
    }
  }
`;

const MY_COIN_TRANSFERS_BY_COIN_ID = gql`
  query ($coin_id: Int!, $page: Int!) {
    myCoinTransactionsByCoinId(
      coin_id: $coin_id
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: 10
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
        currentPage
      }
      data {
        id
        type
        coin_id
        amount
        txhash
        from_wallet_address
        to_wallet_address
        network_fee
        ip
        created_at
        updated_at
        coin {
          id
          name
          name_en
          symbol
          acronym
          created_at
          image {
            url
          }
        }
      }
    }
  }
`;

const MY_COIN_SWAPS_BY_COIN_ID = gql`
  query ($coin_id: Int!, $page: Int!) {
    myCoinSwapsByCoinId(
      coin_id: $coin_id
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: 10
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
        currentPage
      }
      data {
        id
        coin_swap_id
        type
        exportCoin {
          id
          name
          symbol
          unit
          image {
            url
          }
        }
        export_amount
        importCoin {
          id
          name
          symbol
          unit
          image {
            url
          }
        }
        import_amount
        fee
        reward
        status
        swap_hash
        ip
        created_at
        updated_at
      }
    }
  }
`;

/** 모든 스왑 페어를 조회
 * */

const COIN_SWAP_IDS = gql`
  {
    coinSwaps(orderBy: [{ column: CREATED_AT, order: DESC }]) {
      data {
        id
        type
        coin_id
        ratio
        fee_rate
        fee
        reward
        reward_rate
        minimum_amount
        is_active
        exportCoin {
          id
          name
          symbol
          acronym
          unit
          image {
            url
          }
        }
        importCoin {
          id
          name
          symbol
          acronym
          unit
          image {
            url
          }
        }
      }
    }
  }
`;

const COIN_REWARD_IDS = gql`
  query coinRewards {
    coinRewards(orderBy: [{ column: CREATED_AT, order: DESC }]) {
      data {
        id
        is_active
        coin_id
        coin {
          name
          name_en
          image {
            url
          }
        }
      }
    }
  }
`;

// 보유한 코인 확인
const MY_COIN_WALLETS_AS_AMOUNT_GREATER_THEN_ZERO = gql`
  query {
    myCoinWalletsAsAmountGreaterThenZero {
      amount
      coin {
        id
      }
    }
  }
`;

const CREATE_SYNTAX = gql`
  mutation {
    createPrivateKeyDecryptionSyntax {
      data {
        privateKeyDecryptionSyntax
      }
      status
      message
    }
  }
`;

const CREATE_WALLET = gql`
  mutation createDefaultWallet($syntax: String!, $pin: String!) {
    createDefaultWallet(input: { private_key_decryption_syntax: $syntax, pin: $pin }) {
      data {
        EthereumPrivateKey
        privateKeyDecryptionSyntax
      }
      status
      message
    }
  }
`;

const MY_WALLETS = gql`
  query {
    myCoinWallets {
      coin_id
      created_at
      wallet_address
      coin {
        name
        name_en
        image {
          url
          id
        }
      }
    }
  }
`;

const MY_PARTNER_WALLETS = gql`
  query myCoinWalletsByOnlyPartner {
    myCoinWalletsByOnlyPartner {
      id
      coin_id
      user_id
      amount
      wallet_address
      hash
      created_at
      balanced_at
      coin {
        id
        name
        name_en
        symbol
        acronym
        unit
        is_unlink
        image {
          url
          id
        }
      }
    }
  }
`;

const DELETE_COIN_WALLET = gql`
  mutation ($id: ID!) {
    deleteUserCoinWallet(id: $id) {
      status
      message
    }
  }
`;

export {
  COINS,
  TOKEN_COINS,
  COIN_LIST,
  GET_BALANCE,
  MY_COIN_TRANSFERS,
  MY_COIN_TRANSFERS_BY_COIN_ID,
  MY_COIN_SWAPS_BY_COIN_ID,
  COIN_SWAP_IDS,
  MY_COIN_WALLETS_AS_AMOUNT_GREATER_THEN_ZERO,
  COIN_REWARD_IDS,
  CREATE_SYNTAX,
  CREATE_WALLET,
  MY_WALLETS,
  DELETE_COIN_WALLET,
  GET_DEFAULT_COIN_INFO,
  MY_PARTNER_WALLETS,
};
