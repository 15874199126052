import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getQrCode, RgetQrCode} from '@/common';
import userSlice from '@/redux/reducer/userSlice';

export default function TestPage(){
    const [text,setText]  = useState();
    const [text2,setText2]  = useState();
    const user = useSelector(state => (state.user));
    const dispatch = useDispatch();

    const changeText =()=>{
        setText(user.privateKey);
    };
    const onChangeValue =(e)=>{
        setText2(e.target.value);
    };

    const verifyPrivateKey = (key) => {
        const regex = /^(0x)?[0-9a-f]{64}$/i;
        return regex.test(key.trim());
    };

    const generateRandomString=(length)=>{
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
        const charactersLength = characters.length;
        // eslint-disable-next-line
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    const hasPrivateKeyToTrueString=()=>{
        window.hasPrivateKey = 'true';
    };

    useEffect(()=>{
        const stringlengh64 = generateRandomString(64);
        setText2(`0x${stringlengh64}`);
        console.info('0x8b5bcf31f1f53c9cabd6eb890b18fdac19f368c184bd67de9c43634fa6438bb9'.length);
    },[]);
    
    // const boolean = verifyPrivateKey(text2);
    return(
        <div>
            <div>
                <h1>테스트 페이지</h1>
                <textarea name="" id="" value={text || ''} cols="30" rows="10" onChange={()=>{}} />
                <button type="button" onClick={window.getQrCode}>getQrCode</button>
                <button type="button" onClick={window.RgetQrCode}>RgetQrCode</button><br/>
                <button type="button" onClick={window.getDataSave}>getDataSave</button>
                <button type="button" onClick={()=>{setText(window.getDataSave);}}>getDataSave</button><br/>
                <button type="button" onClick={window.RsetDataSave}>RsetDataSave</button>
                <button type="button" onClick={window.RgetDataSave}>RgetDataSave</button><br/>
                <button type="button" onClick={changeText}>showData</button>
            </div>
            <div>
                <input
                    type="text"
                    name="test"
                    placeholder="앱 함수 set"
                    value={text2 || ''}
                    onChange={(e)=> {
                        onChangeValue(e);
                    }}
                />
                <button type="button"
                        onClick ={()=>{
                                        window.setDataSave(text2);
                                        dispatch(userSlice.actions.setHasPrivateKeyTrue);
                                    }}

                > 앱함수 - setDataSave </button> <br/>
                <button
                    type="button"
                    onClick={window.getDataSave}
                >setDataSave한거=======getDataSave하기</button>
                <button
                    type="button"
                    onClick={()=>{
                        hasPrivateKeyToTrueString();
                    }}
                >
                   window.hasPrivateKey 'true'
                </button>
            <div>window.AppPrivateKey : {window.AppPrivateKey}</div>
            <div>window.hasPrivateKey : {window.hasPrivateKey}</div>
            <div>localStorage privateKey : {localStorage.getItem('privateKey')}</div>
            <div>user.private_key_decryption_syntax : {user.private_key_decryption_syntax}</div>
            </div>
        </div>
    );
};

