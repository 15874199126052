import { gql } from '@apollo/client';

const WITHDRAW_AMOUNT = gql`
  mutation withdrawAmount(
    $coin_id: Int!
    $amount: Float!
    $to_wallet_address: String!
    $pin: String
  ) {
    withdrawAmount(
      input: {
        coin_id: $coin_id
        amount: $amount
        to_wallet_address: $to_wallet_address
        pin: $pin
      }
    ) {
      status
      message
    }
  }
`;

export { WITHDRAW_AMOUNT };
