import styled from 'styled-components';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Icons } from '@assets/images';
import { pageTitles } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR } from '@/constants';

export default function Header() {
  const { coinId, coinUnit } = useSelector((state) => state.wallet.coin) || {};
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const [title, setTitle] = useState('');
  const boardMatch = useMatch('/board/:id');
  const tokenMatch = useMatch('/token/:token/*');
  const pinMatch = pathname.endsWith('check_pin');
  const preventBackMatch = pathname.endsWith('/swap');

  const handleClick = () => {
    const pathArray = pathname.split('/');
    const prevPath = pathArray[pathArray.length - 1];
    const isSendPath = prevPath === 'send';
    const isCoinUnitPath = prevPath === coinUnit;

    switch (true) {
      case isSendPath:
        navigate(`/wallet/token/${coinUnit}`, { state: { coinId, coinUnit } });
        break;
      case isCoinUnitPath:
        navigate('/wallet');
        break;
      default:
        navigate(-1);
        break;
    }
  };

  useEffect(() => {
    const getPageTitle = () => {
      if (pinMatch) {
        return t('header.sendPin.title');
      }
      const { page } = pageTitles.find((p) => p.path === pathname) || {};
      if (boardMatch) {
        return t('header.board.title');
      }
      if (tokenMatch) {
        if (tokenMatch.params.token !== ':token') {
          return tokenMatch.params.token;
        }
        return t('header.swap.title');
      }
      if (page) {
        return t(`header.${page}.title`);
      }
      return '';
    };

    const title = getPageTitle();
    setTitle(title);
  }, [pathname]);

  return (
    <Container>
      <h1>{title}</h1>
      {/* <Back
        style={{ display: preventBackMatch ? 'none' : 'block' }}
        type="button"
        onClick={handleClick}
        img={Icons.backWhite}
      /> */}
    </Container>
  );
}

const Container = styled.div`
  padding: 8px 15px;

  h1 {
    display: flex;
    padding: 8px 0;
    align-items: center;
    align-self: stretch;
    color: ${B_COLOR};
    font-family: Spoqa Han Sans Neo;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
  }
`;

const Back = styled.button`
  position: absolute;
  width: 26px;
  height: 26px;
  left: 30px;
  top: 50%;
  margin-top: -13px;
  background: transparent url(${(props) => props.img}) no-repeat center;
  text-indent: -9999px;
  border: 0;
`;
