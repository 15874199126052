import React from 'react';
import styled from 'styled-components';
import { PRI_COLOR, W_COLOR } from '@/constants';

const CustomInput = ({ register, name, options, ...props }) => {
  if (register) {
    return <StyledInput {...props} {...register(name, options)} />;
  }
  return <StyledInput {...props} />;
};

export default CustomInput;

const StyledInput = styled.input`
  background: ${W_COLOR};
  border-radius: 15px;
  border: none;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  height: 60px;
  padding: 0 25px;

  &:focus {
    border: 1px solid ${PRI_COLOR};
  }
`;
