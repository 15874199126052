import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CheckPIN from './CheckPin';
import { DO_SWAP } from '@/graphql/Swap';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { CHECK_PIN } from '@/graphql/Users';
import useModals from '@/hooks/useModal';
import { modals } from '@/views/shared/modal/Modals';
import Spinner from '@/views/shared/spinner/Spinner';

const SwapCheckPin = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const swap = useSelector((state) => state.swap);
  const { t } = useTranslate();
  const { openModal } = useModals();
  const pinRef = useRef(null);

  const [doSwap, { loading: swapLoading }] = useMutation(DO_SWAP, {
    onCompleted: ({ swapAmount }) => {
      navigate(pagePaths.SWAP_SUCCESS, { state: { swapInfo: swapAmount } });
    },
    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: error.message } });
    },
  });

  const [checkPIN, { loading: checkLoading, error }] = useMutation(CHECK_PIN);

  const callback = (_pin) => {
    checkPIN({
      variables: { pin: _pin },
      onCompleted: (data) => {
        if (data.checkPin.status === 'success') {
          doSwap({
            variables: {
              id: parseInt(swap.selectedSwapInfo.id, 10),
              amount: parseInt(swap.exportAmount, 10),
              isSwapWithEth: state.isSwapWithEth,
              pin: _pin,
            },
          });
        } else {
          // pinRef.current.clearPin();
          openModal(modals.dialog, {
            title: swap.swapType === 'import' ? t('swap.import.error') : t('swap.export.error'),
            message: data.checkPin.message,
          });
        }
      },
    });
  };

  if (checkLoading || swapLoading) return <Spinner isLoading />;

  return <CheckPIN callback={callback} ref={pinRef} />;
};

export default SwapCheckPin;
