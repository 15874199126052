import styled from 'styled-components';
import _ from 'lodash';

export default function TabContents({ tabs, active, className, children }) {
  return (
    <Container>
      {_.map(tabs, (item) => (
        <TabContentItem key={item.key} isActive={active === item.key}>
          {children ? children(item) : item.component}
        </TabContentItem>
      ))}
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
`;

const TabContentItem = styled.div`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;
