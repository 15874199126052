import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { B_COLOR, G_COLOR, W_COLOR } from '@/constants';

export default function FormButton({
  icon,
  link,
  state,
  text,
  flex,
  onClick,
  rounded,
  color,
  type,
  disabled,
}) {
  return (
    <Container flex={flex}>
      {link && !disabled ? (
        <AnchorButton to={link} state={state} rounded={rounded?.toString()} color={color}>
          {text}
          {icon}
        </AnchorButton>
      ) : (
        <Button
          disabled={disabled}
          type={type || 'button'}
          rounded={rounded?.toString()}
          color={color}
          onClick={onClick}
        >
          {text}
          {icon}
        </Button>
      )}
    </Container>
  );
}

const Container = styled.div`
  flex: ${(props) => props.flex || 0};
  display: flex;
  justify-content: center;
`;

const buttonStyles = () => css`
  position: relative;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 60px;
  line-height: 60px;
  color: ${W_COLOR};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) =>
    (props.color === 'black' && 'transparent') ||
    (props.color === 'gray' && 'transparent') ||
    (props.color === 'white' && B_COLOR)};
  border-radius: ${(props) => (props.rounded ? '30px' : '15px')};
  background: ${(props) =>
    (props.color === 'black' && B_COLOR) ||
    (props.color === 'gray' && G_COLOR) ||
    (props.color === 'gray' && W_COLOR)};
  color: ${(props) =>
    (props.color === 'black' && W_COLOR) ||
    (props.color === 'gray' && B_COLOR) ||
    (props.color === 'white' && B_COLOR)};

  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 17px;
  font-weight: 400;
  img,
  svg {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  :disabled {
    background: ${G_COLOR};
    color: ${B_COLOR};
    font-size: 17px;
    font-weight: 400;
  }
`;

const AnchorButton = styled(Link)`
  ${buttonStyles};
`;

const Button = styled.button`
  ${buttonStyles};
`;
