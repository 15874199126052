import React from 'react';
import styled from 'styled-components';
import { B_COLOR, TEXT_GRAY } from '@/constants';

const CustomItem = ({ children, imgSrc, title, subtitle, onClick }) => (
  <Container onClick={onClick}>
    <img src={imgSrc} alt="" />
    <Content className="content">
      <p className="title">{title}</p>
      {subtitle && <p className="subtitle">{subtitle}</p>}
    </Content>
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </Container>
);

export default CustomItem;

const Container = styled.li`
  display: flex;

  & .icon {
    width: 45px;
    height: 45px;
  }
`;

const Content = styled.div`
  flex: 1;
  margin-left: 20px;
  margin-right: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 500;
  }

  .subtitle {
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
  }
`;

const ChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
`;
