import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import { MonthTitle, NoResultWrapper, Wrapper } from './SwapItemList';
import { MY_COIN_REWARD_LIST } from '@/graphql/Swap';
import { getTimestampFromDate } from '@/utils/time';
import { groupByMonthSwapList } from '@/utils';
import { Observer } from '@/views/shared';
import SwapListItem from './SwapItem';
import { useTranslate } from '@/hooks/useTranslate';
import NoResult from '@/views/shared/noResult';
import RewardItem from './RewardItem';
import PartialLoading from '@/views/shared/spinner/PartialLoading';

const RewardItemList = () => {
  const { activeDateCategory } = useSelector((state) => state.swapHistory);
  const [ref, inView] = useInView();
  const { t } = useTranslate();

  const searchDate = getTimestampFromDate(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    new Date().getDate(),
    Number(activeDateCategory),
  );
  const { data, loading, fetchMore } = useQuery(MY_COIN_REWARD_LIST, {
    variables: {
      coin_reward_id: 0,
      page: 1,
      created_at: searchDate,
    },
    fetchPolicy: 'cache-and-network',
  });

  const list = useMemo(() => {
    if (!data) return [];
    return groupByMonthSwapList(data.myCoinRewards.data);
  }, [data]);

  const getContent = () => {
    // 초기로딩
    if (!data && loading) {
      return <PartialLoading />;
    }

    // 데이터 있는경우
    if (Object.keys(list).length > 0) {
      return (
        <ul>
          {Object.keys(list).map((date) => (
            <div key={date}>
              <MonthTitle>{date}</MonthTitle>
              {list[date].map((item, index) => (
                <RewardItem key={`${item.id}-${index}`} item={item} />
              ))}
            </div>
          ))}
          <Observer ref={ref} />
        </ul>
      );
    }

    // 없는경우
    return (
      <NoResultWrapper>
        <NoResult title={t('common.noUsed')} text={t('common.noUsed.walletText')} />
      </NoResultWrapper>
    );
  };

  useEffect(() => {
    if (inView && data && !loading) {
      const pageInfo = data.myCoinRewards.paginatorInfo;
      const nextPage = pageInfo.currentPage + 1;

      if (nextPage > pageInfo.lastPage) return;

      fetchMore({
        variables: {
          coin_swap_id: 0,
          page: nextPage,
          created_at: searchDate,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const latestPage = prev.myCoinRewards.paginatorInfo.currentPage;
          const updatePage = fetchMoreResult.myCoinRewards.paginatorInfo.currentPage;

          if (!fetchMoreResult) return prev;
          if (latestPage >= updatePage) return prev; // 중복된 페이지 데이터 방지

          return {
            ...prev,
            myCoinRewards: {
              ...prev.myCoinRewards,
              paginatorInfo: fetchMoreResult.myCoinRewards.paginatorInfo,
              data: [...prev.myCoinRewards.data, ...fetchMoreResult.myCoinRewards.data],
            },
          };
        },
      });
    }
  }, [data, fetchMore, inView, loading, searchDate]);

  return <Wrapper>{getContent()}</Wrapper>;
};

export default RewardItemList;
