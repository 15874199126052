import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import NoResult from '@views/shared/noResult';
import ModalText from '@views/shared/modal/ModalText';
import { SITE_GUIDE, SITE_GUIDE_LATEST_BY_CATEGORY } from '@/graphql/SiteGuide';
import { pagePaths } from '@/navigation/Pages';
import { TEXT_GRAY } from '@/constants';
import Spinner from '../spinner/Spinner';
import { getLocale, initializeLocale } from '@/utils/locale';

export default function TermsText() {
  const [showPrevContent, setShowPrevContent] = useState(false);
  const [prevContentId, setPrevContentId] = useState(null);
  const navigate = useNavigate();
  const locale = getLocale() || initializeLocale();

  const { data: latestData, loading: latestLoading } = useQuery(SITE_GUIDE_LATEST_BY_CATEGORY, {
    variables: {
      category: 'TERMS',
      locale,
    },
    onCompleted: (data) => {
      setPrevContentId(data.siteGuideLatestByCategory.previous?.id);
    },
    onError: (error) => {
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      navigate(pagePaths.ERROR, { state: { error: errorMsg.join(', ') } });
    },
  });

  const [siteGuide, { loading, data }] = useLazyQuery(SITE_GUIDE, {
    variables: {
      id: prevContentId,
    },
    onError: (error) => {
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      navigate(pagePaths.ERROR, { state: { error: errorMsg.join(', ') } });
    },
  });

  const handleShowPrevContent = () => {
    setShowPrevContent(!showPrevContent);
  };

  useEffect(() => {
    if (prevContentId) {
      siteGuide({
        variables: {
          id: prevContentId,
        },
      });
    }
  }, [prevContentId]);

  if (latestLoading || loading) return <Spinner isLoading={latestLoading || loading} />;

  return (
    <Container>
      {latestData?.siteGuideLatestByCategory?.previous && (
        <div className="prev">
          <button type="button" onClick={handleShowPrevContent}>
            {showPrevContent
              ? ModalText.siteGuide.showLatest[localStorage.getItem('locale')]
              : ModalText.siteGuide.showPrev[localStorage.getItem('locale')]}
          </button>
        </div>
      )}
      {!showPrevContent ? (
        latestData && latestData.siteGuideLatestByCategory ? (
          <>
            <p className="title">{latestData.siteGuideLatestByCategory.subject}</p>
            <p className="update">
              {ModalText.siteGuide.updatedAt[localStorage.getItem('locale')]} :{' '}
              {latestData.siteGuideLatestByCategory.revisioned_at}
            </p>
            <div
              dangerouslySetInnerHTML={{ __html: latestData.siteGuideLatestByCategory.content }}
            />
          </>
        ) : (
          <NoresultWrapper>
            <NoResult
              title={ModalText.common.noArticle[localStorage.getItem('locale')]}
              text={ModalText.common.noArticle[localStorage.getItem('locale')]}
            />
          </NoresultWrapper>
        )
      ) : (
        <>
          <p className="title">{data.siteGuide.subject}</p>
          <p className="update">
            {ModalText.siteGuide.updatedAt[localStorage.getItem('locale')]} :{' '}
            {data.siteGuide.revisioned_at}
          </p>
          <div dangerouslySetInnerHTML={{ __html: data.siteGuide.content }} />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  margin-bottom: 30px;

  .prev {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    button {
      background: #e0e0e2;
      border-radius: 10px;
      border: 0;
      color: ${TEXT_GRAY};
      padding: 10px;
    }
  }
  .update {
    margin-bottom: 35px;
    color: #81819d;
  }
`;

const NoresultWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
