import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import { Decimal } from 'decimal.js';

import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, G_COLOR, LIGHT_COLOR, PRI_COLOR } from '@/constants';

function SendForm(
  { coinId, balance = 0, fee = 0, convertedAmount, userLocale, setAmount, setCurrencyRate },
  ref,
) {
  const [inputAmount, setInputAmount] = useState();
  const truncateFourDecimals = (value) => Math.floor(Number(value) * 10000) / 10000;
  const amountInput = useRef();
  const { t } = useTranslate();

  const clearAmount = () => {
    setAmount('');
    setInputAmount('');
  };

  useImperativeHandle(ref, () => ({
    clearAmount: () => clearAmount(),
  }));

  const formatAmount = (value) => {
    const amount = value.replace(/,/g, '');
    const parts = amount.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };
  const setSendAmount = (ratio) => {
    let value;
    if (coinId === '1' && ratio === 1) {
      let netBalance = balance - fee;
      if (netBalance < 0) {
        netBalance = 0;
      }
      value = truncateFourDecimals(netBalance).toString();
    } else if (ratio === 0) {
      setAmount(0);
      setInputAmount('');
      return;
    } else {
      value = new Decimal(balance).times(ratio).toDecimalPlaces(4, Decimal.ROUND_FLOOR).toString();
    }
    setAmount(value);
    setInputAmount(formatAmount(value));
    setCurrencyRate(value);
  };

  const handleInputChange = ({ target: { value } }) => {
    value = value.replace(/[^0-9.]/g, '');

    if (value.length > 1 && value[0] === '0' && value[1] !== '.') {
      value = value.slice(1);
    }

    const decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount > 1) {
      const lastIndex = value.lastIndexOf('.');
      value = value.substring(0, lastIndex) + value.substring(lastIndex + 1);
    }

    const decimalIndex = value.indexOf('.');
    if (decimalIndex !== -1) {
      const decimalPlaces = value.substring(decimalIndex + 1).length;
      if (decimalPlaces > 4) {
        value = value.substring(0, decimalIndex + 5);
      }
    }

    if (value === '.') {
      value = '0.';
    } else if (value.length > 1 && value[0] === '.' && value[1] !== '.') {
      value = `0${value}`;
    }
    setAmount(value);
    setInputAmount(formatAmount(value));
    setCurrencyRate(value);
  };

  return (
    <Container>
      <InputWrapper>
        {/* <span>{t('token.send.amount')}</span> */}
        <input
          type="text"
          inputMode="decimal"
          value={inputAmount}
          placeholder={t('token.send.amountPlaceholder')}
          autoComplete="off"
          ref={amountInput}
          onChange={handleInputChange}
        />
        <button className="init-btn" type="button" onClick={() => setSendAmount(0)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="10"
            viewBox="0 0 11 10"
            fill="none"
          >
            <path
              d="M6.49388 4.99795L10.2913 8.80011C10.568 9.07671 10.568 9.52209 10.2913 9.79401C10.0147 10.0706 9.56936 10.0706 9.29744 9.79401L5.49529 5.99185L1.69782 9.79401C1.42121 10.0706 0.98052 10.0706 0.703914 9.79401C0.427309 9.5174 0.427309 9.07671 0.703914 8.80011L4.50607 4.99795L0.703914 1.20048C0.427309 0.923874 0.427309 0.478493 0.703914 0.206575C0.839873 0.0706167 1.02271 0.000292778 1.20087 0.000292778C1.37902 0.000292778 1.56186 0.0706167 1.69782 0.206575L5.49998 4.00873L9.30213 0.206575C9.43809 0.0706167 9.62093 0.000292778 9.79909 0.000292778C9.97724 0.000292778 10.1601 0.0706167 10.296 0.206575C10.5726 0.483181 10.5726 0.928562 10.296 1.20048L6.49388 5.00264V4.99795Z"
              fill="#111111"
            />
          </svg>
        </button>

        {/* <div className="unit">
          {formatAmount(convertedAmount, userLocale)} {userLocale === 'KOREAN' ? '원' : 'USD'}
        </div> */}
      </InputWrapper>
      <ButtonWrapper>
        <button type="button" onClick={() => setSendAmount(0.1)}>
          10%
        </button>
        <button type="button" onClick={() => setSendAmount(0.25)}>
          25%
        </button>
        <button type="button" onClick={() => setSendAmount(0.5)}>
          50%
        </button>
        <button type="button" onClick={() => setSendAmount(1)}>
          {t('token.send.max')}
        </button>
      </ButtonWrapper>
    </Container>
  );
}

export default forwardRef(SendForm);

const Container = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:focus-within {
    border: 1px solid ${PRI_COLOR};
  }

  input {
    border: none;
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 500;
    padding: 0;

    max-width: calc(100% - 32px);
  }

  input::placeholder {
    color: ${G_COLOR};
  }

  .init-btn {
    background-color: ${LIGHT_COLOR};
    border-radius: 50%;
    width: 25px;
    height: 25px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 6px;
  padding: 0 12px;

  button {
    flex: 1;
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 12px;
    font-weight: 400;
    border-radius: 8px;
    background: ${LIGHT_COLOR};
    height: 30px;
  }
`;
