import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ChevronRight } from 'react-feather';
import { modals } from '@views/shared/modal/Modals';
import NoResult from '@views/shared/noResult';
import useModals from '@/hooks/useModal';
import { useObserver } from '@/hooks/UseObserverType';
import formatAmount from '@/utils/formatAmount';
import { useTranslate } from '@/hooks/useTranslate';
import { Observer } from '@/views/shared';

/** Trnclist.js
 *  전송 이력 컴포넌트
 * */
export default function Trnclist({ fetchMore, datalist }) {
  const { openModal } = useModals();
  const bottom = useRef(null);
  const transaction = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const handleClickDetail = (data) => {
    openModal(modals.transactionDetail, {
      data,
    });
  };

  let currentPages = 2;
  const handleFetch = () => {
    fetchMore(currentPages);
    currentPages += 1;
  };

  const onIntersect = ([entry]) => {
    if (entry.isIntersecting) {
      handleFetch();
    }
  };
  // 리스트 무한스크롤 구현
  useObserver({
    target: bottom,
    onIntersect,
    root: null,
    rootMargin: '20px',
  });

  return (
    <Container>
      {datalist.length > 0 ? (
        <ul>
          {datalist.map((item, index) => (
            <li
              key={`${item.index}-${index}`}
              onClick={() => {
                handleClickDetail(item.id);
              }}
            >
              <div className="box">
                <div className="img_wrap">
                  <img src={item.coin.image.url} alt="" />
                </div>
                <div className="txt_wrap">
                  <div className="send">
                    <div className="address">{item.from_wallet_address}</div>
                    <ChevronRight size={20} />
                    <div className="address">{item.to_wallet_address}</div>
                  </div>
                  <div className="info">
                    <div className="date">{item.created_at}</div>
                    <div className="amount">
                      {formatAmount(item.amount)} {item.coin.acronym}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <NoResultWrapper>
          <NoResult dark title={t('common.noUsed')} text={t('common.noUsed.walletText')} />
        </NoResultWrapper>
      )}
      <Observer ref={bottom} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const NoResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
