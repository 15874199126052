import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tab: '1',
  coin: {},
  coins: [],
  filterKeyword: '',
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletTab: (state, action) => {
      state.tab = action.payload;
    },
    setSendCoin: (state, action) => {
      state.coin = action.payload;
    },
    setCoins: (state, action) => {
      state.coins = action.payload;
    },
    setFilterKeyword: (state, action) => {
      state.filterKeyword = action.payload;
    },
    resetWalletSlice: (state) => initialState,
  },
});

export const { setWalletTab, setSendCoin, setCoins, resetWalletSlice, setFilterKeyword } =
  walletSlice.actions;
export default walletSlice;
