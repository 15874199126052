import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import PasswordInput from '@views/shared/forms/PasswordInput';
import FormButton from '@views/shared/forms/FormButton';
import { modals } from '@views/shared/modal/Modals';
import { useSelector } from 'react-redux';
import Spinner from '@/views/shared/spinner/Spinner';
import useModals from '@/hooks/useModal';
import { CHANGE_PASSWORD, FORGOT_PASSWORD } from '@/graphql/Users';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, RED_COLOR, TEXT_GRAY } from '@/constants';

export default function ChangePassword() {
  const { openModal } = useModals();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { email } = useSelector((state) => state.user);

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: (data) => {
      openModal(modals.dialog, {
        title: t('settings.personalData.changePassword'),
        message: data.changePassword.message,
      });
      navigate(-1);
    },
    onError: (error) => {
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      openModal(modals.dialog, {
        title: t('settings.personalData.alertPopupChangePasswordFailed'),
        message: errorMsg.join(', '),
      });
    },
  });

  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: data.forgotPassword.message,
      });
    },
    onError: (error) => {
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      openModal(modals.dialog, {
        title: t('common.info'),
        message: errorMsg.join(', '),
      });
    },
  });

  const onSubmit = ({ exPassword, password, passwordConfirm }) => {
    changePassword({
      variables: { old_password: exPassword, password, password_confirmation: passwordConfirm },
    });
  };

  const resetPassword = () => {
    forgotPassword({
      variables: {
        email,
      },
    });
  };

  if (loading) return <Spinner isLoading={loading} />;
  return (
    <Container>
      <span className="desc">{t('settings.personalData.changePassword.desc')}</span>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="form_box">
          <PasswordInput
            id="userExPw"
            name="exPassword"
            placeholder={t('settings.personalData.currentPasswordPlaceholder')}
            register={register}
            maxlength="20"
            options={{
              required: t('auth.signup.alertEssential'),
            }}
          />
          {errors.exPassword && <small role="alert">{errors.exPassword.message}</small>}
        </div>

        <div className="form_box">
          <PasswordInput
            id="userPw"
            name="password"
            placeholder={t('settings.personalData.newPassword')}
            register={register}
            maxlength="20"
            useToggle
            options={{
              required: t('auth.signup.alertEssential'),
              pattern: {
                value: /^(?=.*[a-zA-Z])((?=.*\d)(?=.*\W)).{6,20}$/,
                message: t('auth.signup.alertInvalidPassword'),
              },
              minLength: {
                value: 8,
                message: t('auth.signup.alertMinPassword'),
              },
              validate: (val) => {
                if (watch('exPassword') === val) {
                  return t('auth.signup.alertNoPreviousPassword');
                }
              },
            }}
          />
          {errors.password && <small role="alert">{errors.password.message}</small>}
        </div>

        <div className="form_box">
          <PasswordInput
            id="userPwCf"
            name="passwordConfirm"
            placeholder={t('settings.personalData.newRepeatPasswordPlaceholder')}
            register={register}
            maxlength="20"
            useToggle
            options={{
              required: t('auth.signup.alertEssential'),
              validate: (val) => {
                if (watch('password') !== val) {
                  return t('auth.signup.alertMatchPassword');
                }
              },
            }}
          />
          {errors.passwordConfirm && <small role="alert">{errors.passwordConfirm.message}</small>}
        </div>

        <button className="reset-btn" type="button" onClick={resetPassword}>
          {t('auth.retry.setting.password.title')}
        </button>

        <div className="submit-btn-wrapper">
          <FormButton type="submit" text={t('common.confirm')} color="black" />
        </div>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .desc {
    white-space: pre-wrap;
    text-align: center;
    margin: 8vh 0 6vh;

    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; /* 164.286% */
  }

  .reset-btn {
    background: transparent;
    color: ${TEXT_GRAY};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;

    margin-top: 4px;
  }

  .submit-btn-wrapper {
    width: 100%;
    margin-top: 8vh;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  width: 100%;
  max-width: 500px;

  & > .form_box {
    width: 100%;
  }

  small {
    color: ${RED_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 12px;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
    margin-left: 12px;
  }
`;
