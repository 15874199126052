import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import FormButton from '@views/shared/forms/FormButton';
import { useMutation } from '@apollo/client';
import { modals } from '@views/shared/modal/Modals';
import Spinner from '@/views/shared/spinner/Spinner';
import { pagePaths } from '@/navigation/Pages';
import useModals from '@/hooks/useModal';
import { FORGOT_PASSWORD } from '@/graphql/Users';
import { useTranslate } from '@/hooks/useTranslate';
import CustomInput from '@/views/shared/input/CustomInput';
import { B_COLOR, PRI_COLOR, TEXT_GRAY } from '@/constants';

export default function FindPassword() {
  const { openModal } = useModals();
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: data.forgotPassword.message,
      });
    },
    onError: (error) => {
      const errorObj = error.graphQLErrors[0]?.extensions;
      let errorMsg = [];
      if ('errors' in errorObj) {
        errorMsg = Object.values(errorObj.errors);
      }
      if ('validation' in errorObj) {
        Object.keys(errorObj.validation).forEach((key) => {
          errorMsg.push(...errorObj.validation[key]);
        });
      }
      openModal(modals.dialog, {
        title: t('common.info'),
        message: errorMsg.join(', '),
      });
    },
  });

  const onSubmit = ({ email }) => {
    forgotPassword({
      variables: {
        email,
      },
    });
  };

  if (loading) return <Spinner isLoading={loading} />;

  return (
    <Container>
      <Content>
        <div className="title">{t('auth.findPassword.title')}</div>
        <div className="subtitle">{t('auth.findPassword.text')}</div>
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <CustomInput
            id="userEmail"
            type="email"
            autoComplete="off"
            register={register}
            name="email"
            placeholder={t('auth.login.email.ph')}
            options={{
              required: t('auth.login.alertEssential'),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t('auth.login.alertEmailType'),
              },
            }}
          />

          {errors.email && <small role="alert">{errors.email.message}</small>}

          <FormButton type="submit" text={t('common.confirm')} color="black" />
        </Form>

        <NoAccount>
          {t('auth.findPassword.rememberPassword')}{' '}
          <Link to={pagePaths.LOGIN}>{t('auth.findPassword.login')}</Link>
        </NoAccount>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;
  font-family: 'Spoqa Han Sans Neo';
  color: ${B_COLOR};

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;

    margin-bottom: 20px;
  }

  .subtitle {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;

    margin-bottom: 110px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  margin-bottom: 30px;
  padding: 0 12px;
  min-width: 300px;
  max-width: 400px;

  button {
    margin-top: 20px;
  }

  small {
    display: block;
    font-size: 0.8em;
    color: #523ee8;
    margin-top: 0.5em;
    padding: 0 15px;
    &[role='alert'] {
      color: #db2b1d;
    }
  }
`;

const NoAccount = styled.div`
  color: ${TEXT_GRAY};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;

  a {
    color: ${PRI_COLOR};
    font-weight: 400;
    text-decoration-line: underline;
  }
`;
