import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Icons } from '@/assets/images';
import { B_COLOR, TEXT_GRAY } from '@/constants';

function LinkItem({ to, imgSrc, title, subTitle }) {
  return (
    <Container to={to}>
      <img src={imgSrc} alt="" className="icon" />
      <div className="content">
        <p className="title">{title}</p>
        <p className="subtitle">{subTitle}</p>
      </div>
      <img src={Icons.arrowRight} alt="" className="arrow" />
    </Container>
  );
}

export default LinkItem;

const Container = styled(Link)`
  display: flex;

  & .icon {
    width: 45px;
    height: 45px;
  }

  & .content {
    flex: 1;
    margin-left: 20px;
    margin-right: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .title {
      color: ${B_COLOR};
      font-family: 'Spoqa Han Sans Neo';
      font-size: 16px;
      font-weight: 500;
    }
    & .subtitle {
      color: ${TEXT_GRAY};
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: 400;
    }
  }

  & .arrow {
    width: 28px;
    height: 28px;
  }
`;
