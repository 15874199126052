function formatAmount(value, locale = 'ENGLISH') {
	if (value === null || value === undefined) {
		return '0';
	}

	const parsedValue = parseFloat(value);
	if (Number.isNaN(parsedValue)) {
		return '0';
	}

	let formattedValue;
	if (Math.floor(parsedValue) !== parsedValue) {
		// 소수점 이하 숫자가 있는 경우
		if (locale === 'KOREAN') {
			formattedValue = Math.floor(parsedValue).toLocaleString('en-US');
		} else {
			formattedValue = parsedValue.toLocaleString('en-US', {
				minimumFractionDigits: 0,
				maximumFractionDigits: 4,
			});
			formattedValue = formattedValue.replace(/\.?0*$/, ''); // 소수점 이하의 0 제거
		}
	} else {
		// 소수점 이하 숫자가 없는 경우
		formattedValue = parsedValue.toLocaleString('en-US');
	}

	return formattedValue;
}

export default formatAmount;