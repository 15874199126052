import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import CheckPIN from './CheckPin';
import { WITHDRAW_AMOUNT } from '@/graphql/Send';
import useModals from '@/hooks/useModal';
import { CHECK_PIN } from '@/graphql/Users';
import { modals } from '@/views/shared/modal/Modals';
import { useTranslate } from '@/hooks/useTranslate';
import { pagePaths } from '@/navigation/Pages';
import Spinner from '@/views/shared/spinner/Spinner';

const TokenCheckPin = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { openModal } = useModals();
  const pinRef = useRef(null);
  const { t } = useTranslate();

  const [withdrawAmount, { loading: withdrawLoading }] = useMutation(WITHDRAW_AMOUNT, {
    onCompleted: (data) => {
      if (data.withdrawAmount.status === 'success') {
        navigate(`/wallet/token/${state.tokenName}/send_complete`, { state });
      } else {
        openModal(modals.dialog, {
          title: t('common.info'),
          message: data.withdrawAmount.message,
          callback: () => {
            navigate(-1);
          },
        });
      }
    },
    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: error.message } });
    },
  });

  const [checkPIN, { loading: checkLoading, error: checkError }] = useMutation(CHECK_PIN);

  if (checkLoading || withdrawLoading) return <Spinner isLoading />;

  const callback = (_pin) => {
    checkPIN({
      variables: { pin: _pin },
      onCompleted: (data) => {
        if (data.checkPin.status === 'success') {
          withdrawAmount({
            variables: {
              coin_id: Number(state.tokenId),
              amount: Number(state.amount),
              to_wallet_address: state.target,
              pin: _pin,
            },
          });
        } else {
          // pinRef.current.clearPin();
          openModal(modals.dialog, {
            title: t('token.send.alertPinError'),
            message: data.checkPin.message,
          });
        }
      },
    });
  };

  return <CheckPIN callback={callback} ref={pinRef} />;
};

export default TokenCheckPin;
