/*
  coinSwap는 GraphQl API의 coinSwapsByCoinId로 검색한 json 데이터입니다.
  var amount = 0; // input으로 입력 받은 수량
  toFixed 반환값 string 이여서 이후에 string에 toFixed 시 typeError toFixed 이후 Number형으로 치환코드 추가
  https://fancplace.atlassian.net/wiki/spaces/FW1/pages/14680074
*/

export function calcSwapResult(coinSwap, amount) {
  const swap_ratio = coinSwap.ratio ? coinSwap.ratio : 1;

  let fee = 0;
  let reward = 0;
  let fee_rate = 0;
  let reward_rate = 0;
  let import_amount = 0;

  if (coinSwap.fee_rate > 0 || coinSwap.fee > 0) {
    if (coinSwap.fee_rate > 0) {
      fee_rate = coinSwap.fee_rate / 100; // 수수료률
      fee = (amount / swap_ratio) * fee_rate; // 수수료
    } else if (coinSwap.fee > 0) {
      fee = coinSwap.fee;
    }
    import_amount = amount / swap_ratio - fee;
    import_amount = Number(import_amount.toFixed(4));
  } else {
    import_amount = amount / swap_ratio;
    import_amount = Number(import_amount.toFixed(4));
  }

  if (coinSwap.reward_rate > 0 || coinSwap.reward > 0) {
    if (coinSwap.reward_rate > 0) {
      reward_rate = coinSwap.reward_rate / 100; //  리워드률
      reward = (amount / swap_ratio) * reward_rate;
    } else if (coinSwap.reward > 0) {
      reward = coinSwap.reward;
    }
    reward = Number(reward.toFixed(4));
  }

  if (coinSwap.is_integer === 1) {
    import_amount = Number(import_amount.toFixed(0));
    fee = Number(fee.toFixed(0));
    reward = Number(reward.toFixed(0));
  }

  return { fee, reward, import_amount };
}
