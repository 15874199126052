import { useContext } from 'react';
import loadable from '@loadable/component';
import { ModalsDispatchContext, ModalsStateContext } from '@views/shared/modal/ModalsContext';

export const modals = {
  dialog: loadable(() => import('./alert/dialog')),
  confirm: loadable(() => import('./alert/confirm')),
  transactionDetail: loadable(() => import('./transaction/detail')),
  transactionFilter: loadable(() => import('./transaction/filter')),
  sendConfirm: loadable(() => import('./send/confirm')),
  term: loadable(() => import('./signup/terms')),
  privacy: loadable(() => import('./signup/privacy')),
  tokenSrchFilter: loadable(() => import('./swap/filter')),
  tokenTransactionDetail: loadable(() => import('./transaction/tokenDetail')),
  tokenSwapDetail: loadable(() => import('./transaction/swapDetail')),
  tokenRewardDetail: loadable(() => import('./transaction/rewardDetail')),
  swapConfirm: loadable(() => import('./swap/confirm')),
  swapFeeTypeCheck: loadable(() => import('./swap/feeTypeCheckModal')),
  popUp: loadable(() => import('./popup/Popup')),
  centerOneBtnModal: loadable(() => import('./centerModal/OneBtnModal')),
  centerConfirmModal: loadable(() => import('./centerModal/ConfirmModal')),
  centerTwoBtnModal: loadable(() => import('./centerModal/TwoBtnModal')),
  pointSwapSelectModal: loadable(() => import('./pointSwapSelectModal/PointSwapSelectModal')),
  integrationAgreeModal: loadable(() => import('./agree/IntegrationAgreeModal')), // 계정연동시 약관 동의 모달
};

export default function Modals() {
  const openedModals = useContext(ModalsStateContext);
  const { close } = useContext(ModalsDispatchContext);

  return openedModals.map((modal, index) => {
    const { Component, props } = modal;
    const { onSubmit, ...restProps } = props;
    const onClose = () => {
      close(Component);
    };
    const handleSubmit = async (value) => {
      if (typeof onSubmit === 'function') {
        await onSubmit(value);
      }
      onClose();
    };

    return <Component {...restProps} key={index} onClose={onClose} onSubmit={handleSubmit} />;
  });
}
