import styled, { css } from 'styled-components';
import _ from 'lodash';

export default function TabHeader(props) {
  const { tabs, active, onClickTabButton, className, children } = props;

  return (
    <Container>
      <TabButtons className={className}>
        {_.map(tabs, (item) =>
          children ? (
            <li key={item.key}>{children(item)}</li>
          ) : (
            <TabHeaderButton
              key={item.key}
              type="link"
              isActive={active === item.key}
              onClick={() => onClickTabButton(item.key)}
            >
              {item.title}
            </TabHeaderButton>
          ),
        )}
      </TabButtons>
    </Container>
  );
}

const Container = styled.div``;

const TabButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -10px;
  padding-bottom: 10px;
`;

const TabHeaderButton = styled.button`
  position: relative;
  height: 20px;
  font-size: 17px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.3);
  margin: 0 10px;
  padding: 0;
  border: 0;
  background: none;
  ${({ isActive }) =>
    isActive &&
    css`
      color: rgba(255, 255, 255, 1);
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        height: 5px;
        background: #523ee8;
      }
    `};
`;
