import React, { useTransition } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { Images } from '@/assets/images';
import { BG_COLOR, B_COLOR, PRI_COLOR } from '@/constants';
import CustomCalendar from './CustomCalendar';
import FormButton from '@/views/shared/forms/FormButton';
import { GET_ATTENDANCE, SET_ATTENDANCE } from '@/graphql/Event';
import useModals from '@/hooks/useModal';
import { modals } from '@/views/shared/modal/Modals';
import { useTranslate } from '@/hooks/useTranslate';

// TODO: 언어팩
const CheckIn = () => {
  const { openModal } = useModals();
  const { t } = useTranslate();

  const [setAttendance] = useMutation(SET_ATTENDANCE, {
    onCompleted: ({ setAttendance }) => {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: setAttendance.message,
      });
    },
    refetchQueries: [{ query: GET_ATTENDANCE }],
  });

  return (
    <Container>
      <Header>
        <div className="title">
          <div>출석체크 하고</div>
          <strong>(티모 포인트)</strong>
          <div>받으세요.</div>
        </div>
        <img src={Images.checkinBg} alt="" />
      </Header>
      <CustomCalendar />

      <Info>
        <BtnWrapper>
          <FormButton onClick={setAttendance} text="출석체크" color="black" flex={1} rounded />
        </BtnWrapper>
        <div className="title">유의사항</div>
        <div>1. 이벤트 기간 동안 티모 월렛 1일 1회 출석</div>
        <div>2. 매일 출석할 때마다 100포인트 적립</div>
        <div className="mb-50">3. 적립된 포인트는 다음 달 일괄 지급 됩니다.</div>
        <div className="title">참여방법</div>
        <div>﹒출석 체크는 하루 1회 가능합니다.</div>
        <div>﹒‘출첵하기' 버튼을 눌러야 출석 포인트가 지급됩니다.</div>
        <div>﹒적립된 포인트는 티모월렛 모든 서비스에 가능합니다.</div>
        <div>
          ﹒비정상적인 방법으로 이벤트에 참여한 경우, 적립된 포인트는 반환되며, 서비스 이용이 제한될
          수 있습니다.
        </div>
        <div>﹒이벤트는 사전 안내 없이 변경 혹은 중단될 수 있습니다.</div>
        <div>﹒이벤트로 적립된 포인트의 사용 기한은 적립일부터 2개월입니다.</div>
      </Info>
    </Container>
  );
};

export default CheckIn;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 40px;
  background-color: ${PRI_COLOR};
  overflow-x: hidden;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 24px;

  .title {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    padding-top: 12px;
    min-width: 180px;

    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 24px;
    font-weight: 500;
  }

  img {
    position: relative;
    bottom: -15px;
    z-index: 4;
    height: 100%; /* Header의 높이와 같게 설정 */
  }
`;

const Info = styled.div`
  padding: 30px 24px;
  flex: 1;
  background-color: ${BG_COLOR};

  color: #7f7f7f;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px; /* 153.846% */

  .mb-50 {
    margin-bottom: 50px;
  }

  .title {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-weight: 400;
    line-height: 23px; /* 143.75% */

    margin-bottom: 10px;
  }
`;

const BtnWrapper = styled.div`
  margin-bottom: 64px;
  padding: 0 24px;
`;
