import styled from 'styled-components';
import { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Link as LinkIcon, MinusSquare } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import { useTranslate } from '@/hooks/useTranslate';

export default function QrScanner({ state }) {
  const [result, setResult] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslate();

  const handleScanPage = () => {
    navigate(`/wallet/token/${state.coinUnit}/send`, {
      state: {
        address: { result },
        ...state,
      },
    });
  };

  const handleScan = (data) => {
    if (data) {
      setResult(data);
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <Container>
      <QrReader
        onResult={(result, error) => {
          if (result) {
            handleScan(result?.text);
          }
          if (error) {
            handleError(error);
          }
        }}
        scanDelay={2000}
        containerStyle={{ width: '100%', height: '100%' }}
        videoContainerStyle={{
          width: '100%',
          height: '100%',
          paddingTop: '0',
          overflow: 'visible',
        }}
        videoStyle={{ objectFit: 'cover' }}
        constraints={{ facingMode: 'environment' }}
      />
      <div className="cover">
        <div className="upper" />
        <div className="lensWrap">
          <div className="lensLeft" />
          <div className="lens" />
          <div className="lensRight" />
        </div>
        <div className="lower">
          <div className="msg">
            {t('token.scan.address')}
            <div className="scan">
              <MinusSquare size={18} />
              {t('token.scan.scanQrCode')}
            </div>
          </div>
          {result && (
            <div className="info">
              <div className="tit">
                <LinkIcon size={18} />
                {t('token.scan.address')}
              </div>
              <div className="address">
                <div className="code">{result}</div>
                <button type="button" onClick={handleScanPage}>
                  {t('common.confirm')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  color: #fff;
  .cover {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    .upper,
    .lower {
      width: 100%;
      flex: 1;
      background: rgba(0, 0, 0, 0.75);
      padding: 30px;
      text-align: center;
    }
    .upper {
      margin-top: -150px;
    }
    .lower {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .msg {
        font-size: 13px;
        font-weight: 500;
        color: #e0e0e2;
        .scan {
          display: flex;
          font-size: 20px;
          color: #fff;
          margin-top: 10px;
          justify-content: center;
          align-items: center;
          svg {
            color: #b2e5f5;
            margin-right: 12px;
          }
        }
      }
      .info {
        background: #0a043c;
        border-radius: 25px;
        font-size: 15px;
        padding: 30px;
        .tit {
          display: flex;
          text-align: left;
          color: #84819d;
          align-items: center;
          svg {
            color: #523ee8;
            margin-right: 5px;
          }
        }
        .address {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .code {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          button {
            border-radius: 5px;
            background: #0f084b;
            border: 1px solid #84819d80;
            color: #fff;
            width: 80px;
            height: 35px;
            flex-shrink: 0;
          }
        }
      }
    }
    .lensWrap {
      display: flex;
      width: 100%;
      .lens {
        width: 310px;
        height: 310px;
        background: transparent;
        border: 2px solid #523ee8;
      }
      .lensLeft,
      .lensRight {
        width: 100%;
        flex: 1;
        background: rgba(0, 0, 0, 0.75);
      }
    }
  }
`;
