import React, { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import isEmpty from 'validator/es/lib/isEmpty';
import { modals } from '@views/shared/modal/Modals';
import { Icons } from '@assets/images';
import FormButton from '@views/shared/forms/FormButton';
import CodeInputItem from '@views/shared/input/CodeInputItem';
import Spinner from '@/views/shared/spinner/Spinner';
import { pagePaths } from '@/navigation/Pages';
import useModals from '@/hooks/useModal';
import { CHECK_SWAP_VERIFY, DO_SWAP, VERIFY_SWAP_EMAIL } from '@/graphql/Swap';
import { useTranslate } from '@/hooks/useTranslate';
import useCountDown from '@/hooks/useCountDown';
import { formatTime } from '@/utils/time';
import { parseGqlErrorMsg } from '@/utils';
import { B_COLOR, LIGHT_COLOR, TEXT_GRAY } from '@/constants';

// 스왑 이메일 인증번호 입력 폼
export default function verifyInputForm() {
  const { state } = useLocation();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { openModal } = useModals();

  const swap = useSelector((state) => state.swap);
  const codeInputList = useMemo(() => ['1st', '2nd', '3rd', '4th'], []);
  const { remainingTime, startTimer, resetTimer } = useCountDown(3 * 60);
  const { handleSubmit, setValue, setFocus, register } = useForm();

  // 인증번호 검증
  const [checkSwapVerify, { loading }] = useMutation(CHECK_SWAP_VERIFY);
  // 스왑
  const [doSwap, { loading: loadingB }] = useMutation(DO_SWAP);
  // 인증 메일 재전송
  const [sendVerifyEmail, { loading: loadingv }] = useMutation(VERIFY_SWAP_EMAIL);

  const clearInput = () => {
    codeInputList.forEach((name) => {
      setValue(name, '');
    });
  };

  const sendData = (nums) => {
    const certiNums = Object.values(nums).join('');

    if (isEmpty(certiNums) || String(certiNums).length !== 4) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('swap.certification.popupNoCode'),
      });
      return false;
    }
    // 이메일 인증번호 확인
    checkSwapVerify({
      variables: {
        certification_number: certiNums,
      },
      onCompleted: (data) => {
        if (data.checkSwapVerify.status === 'fail') {
          clearInput();
          openModal(modals.dialog, {
            title: t('common.info'),
            message: data.checkSwapVerify.message,
          });
          // 화면의 시간이 0분 0초일 때 스왑화면으로 이동
          if (remainingTime === 0) navigate(pagePaths.SWAP);
          return false;
        }
        // 스왑 성공 페이지로 이동
        // navigate(pagePaths.SWAP_SUCESS);
      },
      // checkSwapVerify onError 처리
      onError: (error) => {
        openModal(modals.dialog, {
          title: t('common.info'),
          message: error.message,
        });
        // 에러떨어지면 스왑페이지로 이동?
        navigate(pagePaths.SWAP);
      },
    }).then((data) => {
      if (data.data.checkSwapVerify.status === 'success') {
        // alert(`checkSwapVerify if success::: ${window.hasPrivateKey} \n window.AppPrivateKey :: ${window.AppPrivateKey} \n localStorage privateKey : ${localStorage.getItem('privateKey')}`);
        // 스왑 하기
        doSwap({
          variables: {
            id: parseInt(swap.selectedSwapInfo.id, 10),
            amount: parseInt(swap.exportAmount, 10),
            private_key: localStorage.getItem('privateKey'),
            isSwapWithEth: state.isSwapWithEth,
          },
          onCompleted: ({ swapAmount }) => {
            navigate(pagePaths.SWAP_SUCCESS, { state: { swapInfo: swapAmount } });
          },
          onError: (error) => {
            navigate(pagePaths.ERROR, { state: { error: error.message } });
          },
        });
      }
    });
  };

  const resendEmail = () => {
    sendVerifyEmail({
      onCompleted: (data) => {
        openModal(modals.dialog, {
          title: t('common.info'),
          message: data.sendSwapVerifyEmail.message,
        });
        resetTimer();
        clearInput();
      },
      onError: (error) => {
        openModal(modals.dialog, {
          title: t('common.error'),
          message: error.message,
        });
      },
    });
  };

  useEffect(() => {
    if (remainingTime === 0) navigate(pagePaths.SWAP_FORM);
  }, [navigate, remainingTime]);

  useEffect(() => {
    startTimer();
  }, [startTimer]);

  if (loading || loadingB || loadingv)
    return <Spinner isLoading={loading || loadingB || loadingv} />;

  return (
    <Container>
      <img src={Icons.emailSend} alt="" />
      <Title>{t('swap.certification.title')}</Title>
      <Desc>{t('swap.certification.text')}</Desc>

      <Form onSubmit={handleSubmit(sendData)}>
        <InputWrapper>
          {codeInputList.map((name) => (
            <CodeInputItem
              id={name}
              list={codeInputList}
              setValue={setValue}
              setFocus={setFocus}
              register={register}
            />
          ))}
        </InputWrapper>
        <div className="counter">{formatTime(remainingTime)}</div>

        <FormButton type="submit" text={t('common.confirm')} color="black" />
      </Form>
      <Nomail>
        {t('swap.certification.noMail')}{' '}
        <button type="button" onClick={resendEmail}>
          {t('swap.certification.reMail')}
        </button>
      </Nomail>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding: 52px;

  img {
    width: 79.44px;
    height: 81.72px;

    margin-bottom: 40px;
  }

  input[type='text'] {
    display: flex;
    width: 50px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;

    border-radius: 15px;
    background: ${LIGHT_COLOR};
    border: none;

    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 17px;
    font-weight: 700;
  }
`;

export const Title = styled.div`
  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 24px;
  font-weight: 400;
  line-height: 30px; /* 125% */

  margin-bottom: 20px;
`;

export const Desc = styled.div`
  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;
  line-height: 23px; /* 164.286% */

  margin-bottom: 64px;
`;

export const InputWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  text-align: center;
`;

export const Nomail = styled.div`
  margin-top: 24px;

  color: ${TEXT_GRAY};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;

  button {
    color: ${B_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
  }
`;
