import { gql } from '@apollo/client';

const NOTICE_BY_ID = gql`
  query notice($id: ID) {
    notice(id: $id) {
      id
      locale
      subject
      content
      created_at
      images {
        id
        url
        thumb_large_url
      }
    }
  }
`;

const NOTICE_LIST = gql`
  query ($locale: LocaleStatus!) {
    notices(locale: $locale, orderBy: [{ column: CREATED_AT, order: DESC }], first: 1, page: 1) {
      data {
        id
        locale
        subject
        type
        content
        created_at
        images {
          id
          url
          thumb_large_url
        }
      }
    }
  }
`;

const NOTICE_SEARCH = gql`
  query noticeSearch($locale: LocaleStatus!, $keyword: Mixed, $page: Int!, $type: NoticeType) {
    noticeSearch(
      type: $type
      locale: $locale
      is_active: true
      where: {
        AND: [
          {
            OR: [
              { column: SUBJECT, operator: LIKE, value: $keyword }
              { column: CONTENT, operator: LIKE, value: $keyword }
            ]
          }
        ]
      }
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: 20
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
      }
      data {
        id
        type
        locale
        subject
        content
        created_at
        is_active
        started_at
        ended_at
        images {
          id
          url
        }
        thumbnail {
          id
          url
        }
      }
    }
  }
`;

const FAQ_SEARCH = gql`
  query ($locale: LocaleStatus!, $keyword: Mixed, $page: Int!) {
    faqSearch(
      locale: $locale
      where: {
        AND: [
          {
            OR: [
              { column: SUBJECT, operator: LIKE, value: $keyword }
              { column: CONTENT, operator: LIKE, value: $keyword }
            ]
          }
        ]
      }
      orderBy: [{ column: CREATED_AT, order: DESC }]
      first: 10
      page: $page
    ) {
      paginatorInfo {
        total
        perPage
        lastPage
        hasMorePages
      }
      data {
        id
        locale
        subject
        content
        images {
          id
          url
          thumb_small_url
        }
      }
    }
  }
`;

const BANNER_BY_ROUTE = gql`
  query bannersByRoute($locale: LocaleStatus!) {
    bannersByRoute(locale: $locale) {
      route
      banners {
        id
        description
        link
        image {
          url
        }
      }
    }
  }
`;

export { NOTICE_LIST, NOTICE_SEARCH, FAQ_SEARCH, NOTICE_BY_ID, BANNER_BY_ROUTE };
