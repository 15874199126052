import styled from 'styled-components';

import formatAmount from '@/utils/formatAmount';
import { useTranslate } from '@/hooks/useTranslate';
import { shortenAddress } from '@/utils';
import { B_COLOR, TEXT_GRAY } from '@/constants';

export default function Result({ target, token, amount, fee }) {
  const { t } = useTranslate();

  return (
    <Container>
      <Row>
        <dt>{t('token.send.resultSend')}</dt>
        <dd>{shortenAddress(target)}</dd>
      </Row>
      <Row>
        <dt>{t('token.send.resultToken')}</dt>
        <dd>{token}</dd>
      </Row>
      <Row>
        <dt>{t('token.send.resultAmount')}</dt>
        <dd>{formatAmount(amount)}</dd>
      </Row>
      <Row>
        <dt>{t('token.send.resultFee')}</dt>
        <dd>{fee}</dd>
      </Row>
    </Container>
  );
}

const Container = styled.dl`
  margin-top: 20px;
  padding: 20px 0;
  border-top: 1px dashed #84919d;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;

  font-family: 'Spoqa Han Sans Neo';
  font-size: 16px;
  font-weight: 400;

  dt {
    color: ${TEXT_GRAY};
  }

  dd {
    color: ${B_COLOR};
  }
`;
