import { useDispatch } from 'react-redux';
import scanSlice from '@/redux/reducer/scanSlice';
import { extractAddressFromEthereumString } from './utils';

export function deviceType() {
  let currentOS;
  const mobile = /iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase());

  if (mobile) {
    // 유저에이전트를 불러와서 OS를 구분합니다.
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.search('android') > -1) currentOS = 'android';
    else if (
      userAgent.search('iphone') > -1 ||
      userAgent.search('ipod') > -1 ||
      userAgent.search('ipad') > -1
    )
      currentOS = 'ios';
    else currentOS = 'else';
  } else {
    // 모바일이 아닐 때
    currentOS = 'nomobile';
  }
  return currentOS;
}
// qr코드 촬영, 모바일 기기에서만 사용가능
function getQrCode() {
  if (deviceType() === 'android') {
    // eslint-disable-next-line no-undef
    TMO_Wallet_AOS.getQrCode();
  } else {
    const obj = { name: 'getQrCode' };
    // eslint-disable-next-line no-undef
    webkit.messageHandlers.tmo_wallet.postMessage(JSON.stringify(obj));
  }
}

// qr 코드 촬영 리턴 함수
function RgetQrCode(data) {
  document.getElementById('receiverAddress').value = extractAddressFromEthereumString(data);
}

// 안드로이드 공유하기 기능
function stringShare(data) {
  if (deviceType() === 'android') {
    // eslint-disable-next-line no-undef
    TMO_Wallet_AOS.stringShare(data);
  }
}

// 1.앱 호출(푸시키)
function getPushToken() {
  if (deviceType() === 'android') {
    // eslint-disable-next-line no-undef
    TMO_Wallet_AOS.getPushToken();
  } else {
    const obj = { name: 'getPushToken' };
    // eslint-disable-next-line no-undef
    webkit.messageHandlers.tmo_wallet.postMessage(JSON.stringify(obj));
  }
}

// 두개값 전달받은값 디비에 저장해주세요.(안드로이드, 아이폰꼭 구분해서 저장해주셔야 나중에 푸시보낼떄 구분할수있습니다.)
function RgetPushToken(obj, obj2) {
  // eslint-disable-next-line no-alert
  // alert(obj, 'obj1'); // devicetoken
  // eslint-disable-next-line no-alert
  // alert(obj2, 'obj2'); // device
}

window.deviceType = deviceType;
window.getQrCode = getQrCode;
window.RgetQrCode = RgetQrCode;
window.stringShare = stringShare;
window.getPushToken = getPushToken;
window.RgetPushToken = RgetPushToken;
