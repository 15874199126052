import moment from 'moment';
import 'moment/locale/en-au';
import 'moment/locale/ko';
import { OPTIONS } from '@/constants/options';

export function checkLocale(_locale) {
  const localeList = OPTIONS.LOCALE.map((el) => el.value);

  if (localeList.includes(_locale)) {
    return true;
  }
  return false;
}

export function getLocale() {
  return localStorage.getItem('locale');
}

export function setLocale(value) {
  const localeList = OPTIONS.LOCALE.map((el) => el.value);
  if (localeList.includes(value)) {
    localStorage.setItem('locale', value);
  }
}

// 언어 수동 설정 값이 우선순위, 그 이후엔 브라우저 언어 값으로 설정
export function initializeLocale() {
  let locale;
  const currentLocale = getLocale();

  if (currentLocale && checkLocale(currentLocale)) {
    locale = currentLocale;
  } else if (navigator.language.includes('ko')) {
    locale = 'KOREAN';
  } else if (navigator.language.includes('en')) {
    locale = 'ENGLISH';
  } else {
    locale = 'ENGLISH';
  }

  return locale;
}

function convertDateTimeFormatKO(_startTime, _endTime) {
  moment.locale('ko');
  // 입력 형식을 정의
  const inputFormat = 'YYYY-MM-DD HH:mm:ss';

  // dateTime 객체로 변환합니다.
  const startDateTime = moment(_startTime, inputFormat);
  const endDateTime = moment(_endTime, inputFormat);

  // 일, 요일, 시간을 추출합니다.
  const startMonthDay = startDateTime.format('M월 D일');
  const startDayOfWeek = startDateTime.format('(ddd)');
  const startTimeStr = startDateTime.format('HH:mm');
  const endTimeStr = endDateTime.format('HH:mm');

  // 출력 형태로 변환된 문자열을 반환합니다.
  const convertedString = `${startMonthDay}${startDayOfWeek} ${startTimeStr} ~ ${endTimeStr}`;
  return convertedString;
}

function convertDateTimeFormatEN(_startTime, _endTime) {
  moment.locale('en-us');
  const inputFormat = 'YYYY-MM-DD HH:mm:ss';

  const startDateTime = moment(_startTime, inputFormat);
  const endDateTime = moment(_endTime, inputFormat);

  // 일, 요일, 시간을 추출합니다.
  const startMonthDay = startDateTime.format('MMMM DD');
  const startDayOfWeek = startDateTime.format('(ddd)');
  const startTimeStr = startDateTime.format('HH:mm');
  const endTimeStr = endDateTime.format('HH:mm');

  // 출력 형태로 변환된 문자열을 반환합니다.
  const convertedString = `${startMonthDay}${startDayOfWeek} ${startTimeStr} ~ ${endTimeStr}`;
  return convertedString;
}

export function convertDateTimeFormat(startTime, endTime, locale) {
  if (locale === 'KOREAN') {
    return convertDateTimeFormatKO(startTime, endTime);
  }
  if (locale === 'ENGLISH') {
    return convertDateTimeFormatEN(startTime, endTime);
  }
}
