import styled from 'styled-components';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import NoResult from '@views/shared/noResult';
import { useObserver } from '@/hooks/UseObserverType';
import { useTranslate } from '@/hooks/useTranslate';
import { Observer } from '@/views/shared';
import { Icons } from '@/assets/images';
import PartialLoading from '@/views/shared/spinner/PartialLoading';

export default function FaqList({ data, fetchMore, initLoading }) {
  const { t } = useTranslate();
  const bottom = useRef(null);
  const navigate = useNavigate();

  let currentPages = 1;
  const handleFetch = () => {
    currentPages += 1;
    fetchMore(currentPages);
  };

  const onIntersect = ([entry]) => {
    if (entry.isIntersecting) {
      handleFetch();
    }
  };

  const onNavigateClick = (_item) => {
    navigate(`${_item.id}`, {
      state: {
        ..._item,
      },
    });
  };

  useObserver({
    target: bottom,
    onIntersect,
    root: null,
    rootMargin: '20px',
  });

  if (initLoading) {
    return <PartialLoading />;
  }

  return (
    <Container className="article">
      {data.faqSearch.data.length ? (
        data?.faqSearch.data.map((item) => (
          <Item key={item.id}>
            <div>{item.subject}</div>
            <img src={Icons.detailRight} alt="" onClick={() => onNavigateClick(item)} />
          </Item>
        ))
      ) : (
        <NoResultWrapper>
          <NoResult title={t('common.noArticle')} text={t('common.noSearchResult')} />
        </NoResultWrapper>
      )}

      <Observer ref={bottom} />
    </Container>
  );
}

const Container = styled.dl`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 36px;
`;

const NoResultWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
