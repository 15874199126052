import styled from 'styled-components';
import { useState } from 'react';
import QRCode from 'qrcode.react';
import { Copy } from 'react-feather';

import FormButton from '@views/shared/forms/FormButton';
import { modals } from '@views/shared/modal/Modals';
import useModals from '@/hooks/useModal';
import { useTranslate } from '@/hooks/useTranslate';
import { Icons } from '@/assets/images';
import { B_COLOR } from '@/constants';

export default function QrGenerator({ address, acronym }) {
  const { openModal } = useModals();
  const { t } = useTranslate();

  // const downloadQR = () => {
  // 	const canvas = document.getElementById('myQr');
  // 	const pngUrl = canvas
  // 		.toDataURL('image/png')
  // 		.replace('image/png', 'image/octet-stream');
  // 	const downloadLink = document.createElement('a');
  // 	downloadLink.href = pngUrl;
  // 	downloadLink.download = 'myqr.png';
  // 	document.body.appendChild(downloadLink);
  // 	downloadLink.click();
  // 	document.body.removeChild(downloadLink);
  // };

  const copyCode = () => {
    const textarea = document.createElement('textarea');
    textarea.value = address;
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    openModal(modals.dialog, {
      title: t('common.info'),
      message: t('common.copyClipboard'),
    });
  };

  const shareCode = () => {
    if (navigator.share) {
      navigator
        .share({
          title: t('token.receive.myQRCode'),
          text: address,
        })
        .then(() => console.log('Success'))
        .catch((error) => console.log('Error', error));
    } else if (window.deviceType() === 'android') {
      window.stringShare(address);
    } else {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('common.shareNotSupport'),
      });
    }
  };

  return (
    <>
      <ContentWrapper>
        <Title>{t('token.receive.myQRCode')}</Title>
        <SubTitle>
          <span className="bold">{acronym} </span>
          {t('token.receive.myQRCodeInfo1')}
        </SubTitle>
        <SubTitle>{t('token.receive.myQRCodeInfo2')}</SubTitle>
        {address ? (
          <Content>
            <QrWrapper>
              <QRCode id="myQr" value={address} size={120} />
            </QrWrapper>

            <div className="address">{address}</div>
          </Content>
        ) : (
          <Content>{t('token.receive.noQRCode')}</Content>
        )}
      </ContentWrapper>
      {address && (
        <BtnWrapper>
          <FormButton text={t('common.copy')} color="black" onClick={copyCode} flex={1} />
          <img src={Icons.share} alt="" onClick={shareCode} />
        </BtnWrapper>
      )}
    </>
  );
}

const ContentWrapper = styled.div`
  flex: 3;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BtnWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  gap: 12px;
  margin-top: 15%;
`;

const Title = styled.div`
  color: ${B_COLOR};
  text-align: center;
  font-family: 'Spoqa Han Sans Neo';
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 11px;
`;

const SubTitle = styled.div`
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;

  .bold {
    font-weight: 700;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-top: 40px;

  .address {
    width: 85%;
    word-wrap: break-word;
    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 14px;
    font-weight: 500;
    line-height: 23px; /* 164.286% */
    letter-spacing: 0.7px;
  }
`;

const QrWrapper = styled.div`
  border: 20px solid black;
  border-radius: 40px;
  padding: 24px;
`;
