import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import Calendar from 'react-calendar';
import { useQuery } from '@apollo/client';
import { B_COLOR, PRI_COLOR, W_COLOR } from '@/constants';
import { Icons } from '@/assets/images';
import { GET_ATTENDANCE } from '@/graphql/Event';

const CustomCalendar = () => {
  const [checkedList, setCheckedList] = useState([]);
  const { locale } = useSelector((state) => state.user);

  useQuery(GET_ATTENDANCE, {
    onCompleted: ({ getAttendance }) => {
      setCheckedList(getAttendance.data);
    },
  });

  const formatMonthYear = (locale, date) => {
    const month = date.toLocaleDateString(locale, { month: 'short' });
    const day = date.getDate();
    return `${month} ${day + 1}`;
  };

  function formatTodayDate() {
    const today = new Date();
    const options = { month: 'long', day: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
    const [month, day] = formattedDate.split(' ');
    return { month, day };
  }

  const formatDay = (locale, date) => {
    const isToday = date.getDate() === new Date().getDate();

    if (checkedList.includes(date.getDate())) {
      return null;
    }

    if (isToday) {
      return null;
    }

    return date.getDate().toString();
  };

  const tileContent = ({ date }) => {
    const isToday = date.getDate() === new Date().getDate();

    if (checkedList.includes(date.getDate())) {
      return <img src={Icons.cash} alt="" />;
    }

    if (isToday) {
      return (
        <>
          <img
            alt=""
            src={Icons.cashTrans}
            data-tooltip-id="today"
            data-tooltip-html="출첵하기"
            data-tooltip-place="bottom"
          />
          <Tooltip id="today" />
        </>
      );
    }

    return null;
  };

  return (
    <CalendarWrapper>
      <Title>
        {formatTodayDate().month} <span className="day"> {formatTodayDate().day}</span>
      </Title>
      <Calendar
        next2Label={null}
        nextLabel={null}
        prev2Label={null}
        prevLabel={null}
        calendarType="gregory"
        formatMonthYear={formatMonthYear}
        minDetail="month"
        maxDetail="month"
        defaultView="month"
        showNavigation={false}
        formatDay={formatDay}
        tileContent={tileContent}
        showNeighboringMonth={false}
        locale={locale === 'KOREAN' ? 'ko' : 'en'}
      />
    </CalendarWrapper>
  );
};

export default CustomCalendar;

const CalendarWrapper = styled.div`
  z-index: 10;
  padding: 36px 24px 28px;
  border-radius: 12% 12% 0px 0px;
  background: ${W_COLOR};
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

  .react-calendar__month-view__weekdays {
    text-align: center;

    color: #7f7f7f;
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-weight: 400;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__month-view__days__day {
    min-height: 30px;
    margin: 10px 0;

    color: ${B_COLOR};
    text-align: center;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-weight: 400;
  }
`;

const Title = styled.div`
  text-align: left;
  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 24px;
  font-weight: 500;
  line-height: 23px;

  margin-left: 12px;
  margin-bottom: 24px;

  .day {
    color: ${PRI_COLOR};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;

    margin-left: 2px;
  }
`;
