import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import PinInput from '@views/shared/forms/PinInput';
import { modals } from '@views/shared/modal/Modals';
import { useSelector } from 'react-redux';
import Spinner from '@/views/shared/spinner/Spinner';
import useModals from '@/hooks/useModal';
import { CHANGE_PIN, CHECK_PIN, FORGOT_PIN } from '@/graphql/Users';
import { useTranslate } from '@/hooks/useTranslate';
import { pagePaths } from '@/navigation/Pages';
import { parseGqlErrorMsg } from '@/utils';

export default function ChangePIN() {
  const navigate = useNavigate();
  const { openModal } = useModals();
  const { t } = useTranslate();
  const { hasWallet } = useSelector((state) => state.user);

  const [myOldPin, setMyOldPin] = useState(null);
  const [myPin, setMyPin] = useState(null);
  const [showPin, setShowPin] = useState(true);
  const [showNewPin, setShowNewPin] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [checkOldPin, setCheckOldPin] = useState(false);

  const [checkPIN] = useMutation(CHECK_PIN);
  const showPinRef = useRef();

  const resetPin = () => {
    setMyOldPin(null);
    setMyPin(null);
    setShowPin(true);
    setShowNewPin(false);
    setShowConfirm(false);
    setCheckOldPin(false);
  };

  const [changePin, { loading: changeLoading }] = useMutation(CHANGE_PIN, {
    onCompleted: (data) => {
      openModal(modals.dialog, {
        title: t('settings.security.changePin.alertSuccess'),
        message: data.changePin.message,
        callback: () => {
          navigate(-1);
        },
      });
    },
    onError: (error) => {
      openModal(modals.dialog, {
        title: t('settings.security.changePin.alertFail'),
        message: parseGqlErrorMsg(error),
      });
      resetPin();
    },
  });

  const [forgotPin] = useMutation(FORGOT_PIN, {
    onCompleted: (data) => {
      openModal(modals.dialog, {
        title: t('settings.security.findPin.alertPopupSendMail'),
        message: data.forgotPin.message,
        callback: () => navigate(-1),
      });
    },
  });

  const handleOldPin = (value) => {
    checkPIN({
      variables: { pin: value },
      onCompleted: (data) => {
        if (data.checkPin.status === 'success') {
          setMyOldPin(value);
          setShowPin(false);
          setShowNewPin(true);
        } else {
          openModal(modals.dialog, {
            title: t('common.info'),
            message: data.checkPin.message,
          });
          showPinRef.current.clearPin();
          resetPin();
        }
      },
    });
  };

  const handleNewPin = (value) => {
    setMyPin(value);
    setShowNewPin(false);
    setShowConfirm(true);
  };

  const handleSubmit = (value) => {
    changePin({
      variables: {
        old_pin: myOldPin,
        pin: myPin,
        pin_confirmation: value,
      },
    });
  };

  const resetPasswordBySyntax = () => {
    navigate(pagePaths.SETTINGS_RESET_PIN_BY_MNEMONIC);
  };

  const resetPasswordByEmail = () => {
    forgotPin();
  };

  useEffect(() => {
    if (checkOldPin) {
      setShowPin(false);
      setShowNewPin(true);
    }
  }, [checkOldPin]);

  if (changeLoading) return <Spinner isLoading={changeLoading} />;

  return (
    <Container>
      {showPin && (
        <PinInput
          ref={showPinRef}
          length={6}
          isPassword
          placeholder="••••••"
          onComplete={handleOldPin}
          additionalBtnName={
            hasWallet
              ? t('settings.security.changePin.reset.syntax')
              : t('settings.security.changePin.reset.email')
          }
          additionalHandler={hasWallet ? resetPasswordBySyntax : resetPasswordByEmail}
        >
          <div className="txt_box">
            <div className="tit">{t('settings.security.changePin.title')}</div>
            <div className="txt">{t('settings.security.changePin.text')}</div>
          </div>
        </PinInput>
      )}
      {showNewPin && (
        <PinInput length={6} isPassword placeholder="••••••" onComplete={handleNewPin}>
          <div className="txt_box">
            <div className="tit">{t('settings.security.changePin.new.title')}</div>
            <div className="txt">{t('settings.security.changePin.new.text')}</div>
          </div>
        </PinInput>
      )}
      {showConfirm && (
        <PinInput length={6} isPassword placeholder="••••••" onComplete={handleSubmit}>
          <div className="txt_box">
            <div className="tit">{t('settings.security.changePin.confirm.title')}</div>
            <div className="txt">{t('settings.security.changePin.confirm.text')}</div>
          </div>
        </PinInput>
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  padding: 45px 15px;
`;
