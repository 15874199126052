import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';

import ModalsProvider from '@views/shared/modal/ModalsProvider';

import client from '@services/index';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import ReactGA from 'react-ga4';
import store from '@/redux/store';

import App from './App';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/ko';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.REACT_APP_GA) {
  ReactGA.initialize(process.env.REACT_APP_GA);
}

root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ModalsProvider>
            <App />
          </ModalsProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </ApolloProvider>,
);
