import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

import NoResult from '@views/shared/noResult';
import { useInView } from 'react-intersection-observer';
import { MY_COIN_REWARD_LIST } from '@/graphql/Swap';
import { useTranslate } from '@/hooks/useTranslate';
import SwapListSelectBox from '../swapHistory/SwapListSelectBox';
import { useFetchMoreData } from '@/hooks/useFetchMoreData';
import { COIN_REWARD_IDS } from '@/graphql/Coins';
import RewardListItem from './RewardListItem';
import Spinner from '@/views/shared/spinner/Spinner';
import { Observer } from '@/views/shared';

function RewardList() {
  const perPage = 10;
  const { t } = useTranslate();
  const [ref, inView] = useInView();
  const [rewardId, setRewardId] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(true);

  // selectBox option list 불러오는 쿼리
  const { data: options, loading: optionLoading } = useQuery(COIN_REWARD_IDS);

  // 나의 rewardList 불러오는 쿼리
  const {
    fetchMore,
    data,
    loading: rewardLoading,
  } = useQuery(MY_COIN_REWARD_LIST, {
    variables: { coin_reward_id: rewardId, page: 1, perPage },
    onCompleted: ({ myCoinRewards }) => {
      setHasMorePages(myCoinRewards.paginatorInfo.hasMorePages);
    },
  });

  const handleSelectBox = (e) => {
    const id = Number(e.target.value);
    setRewardId(id);
  };

  // 스크롤 하단위치 && 다음페이지 존재한다면 && 기존데이터 존재시 rewardList fetchMore
  const fetchMoreData = useFetchMoreData(fetchMore, 'myCoinRewards');
  useEffect(() => {
    if (inView && hasMorePages && data) {
      const nextPage = Math.ceil(data.myCoinRewards.data.length / perPage) + 1;
      fetchMoreData(nextPage);
    }
    // data의존성 추가시 중복호출에 의한 eslint 주석처리
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, hasMorePages]);

  if (rewardLoading || optionLoading) return <Spinner isLoading={rewardLoading || optionLoading} />;

  return (
    <Container>
      <div className="select_wrap">
        <SwapListSelectBox
          handleSelectBox={handleSelectBox}
          optionList={options?.coinRewards.data}
          type="rewardList"
        />
      </div>
      <ul>
        {data.myCoinRewards.data.length > 0 ? (
          data.myCoinRewards.data.map((item, index) => (
            <RewardListItem key={`${item.id}-${index}`} item={item} />
          ))
        ) : (
          <NoResult type="swap" title={t('common.noReward')} text={t('common.noRewardText')} />
        )}
      </ul>
      <Observer ref={ref} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
  padding: 30px;
  color: #fff;
  background: #0e043c;

  .select_wrap {
    text-align: center;
  }

  ul {
    margin-top: 55px;
    li {
      margin-bottom: 25px;
    }
  }
`;

export default RewardList;
