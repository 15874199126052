import { useState, useEffect } from 'react';

// initialTime은 초 기준
const useCountDown = (initialTime) => {
  const [remainingTime, setRemainingTime] = useState(initialTime);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let timerId;

    if (isActive && remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else if (remainingTime === 0) {
      setIsActive(false);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [isActive, remainingTime]);

  const startTimer = () => {
    setIsActive(true);
  };

  const resetTimer = () => {
    setRemainingTime(initialTime);
    setIsActive(false);
  };

  return {
    remainingTime,
    startTimer,
    resetTimer,
  };
};

export default useCountDown;
