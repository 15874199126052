import { useQuery } from '@apollo/client';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { NOTICE_LIST } from '@/graphql/Board';
import { parseGqlErrorMsg } from '@/utils';
import { pagePaths } from '@/navigation/Pages';
import { B_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';
import { useTranslate } from '@/hooks/useTranslate';

const Notice = () => {
  const { locale } = useSelector((state) => state.user);
  const { t } = useTranslate();
  const navigate = useNavigate();
  const { data: noticeData } = useQuery(NOTICE_LIST, {
    variables: { locale },
    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: parseGqlErrorMsg(error) } });
    },
  });

  if (!noticeData || noticeData.notices.data.length === 0) {
    return (
      <NoticeWrapper>
        <span className="icon">{t('board.type.all')}</span>
        <div className="text">{t('common.noArticle')}</div>
      </NoticeWrapper>
    );
  }

  return (
    <NoticeWrapper>
      <span className="icon">{noticeData.notices.data[0].type}</span>
      <Link
        className="content"
        to={`/board/${noticeData.notices.data[0].id}`}
        state={{
          createdAt: noticeData.notices.data[0].created_at,
          subject: noticeData.notices.data[0].subject,
          content: noticeData.notices.data[0].content,
          images: noticeData.notices.data[0].images,
        }}
      >
        <div className="text">{noticeData?.notices.data[0].subject}</div>
        <div className="date">
          {moment(noticeData?.notices.data[0].created_at).format('YY.MM.DD')}
        </div>
      </Link>
    </NoticeWrapper>
  );
};

export default Notice;

const NoticeWrapper = styled.div`
  margin: 10px 0;
  padding: 18px 8px;
  display: flex;

  & > .icon {
    border-radius: 10px;
    width: 54px;
    height: 24px;
    background: ${B_COLOR};
    color: ${W_COLOR};
    font-family: Spoqa Han Sans Neo;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
  }

  & > .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    & .date {
      color: ${TEXT_GRAY};
      font-size: 14px;
      font-weight: 400;
    }
  }

  & .text {
    margin-left: 8px;
    color: ${B_COLOR};
    font-family: Spoqa Han Sans Neo;
    font-size: 14px;
    font-weight: 400;
  }
`;
