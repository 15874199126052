import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import { B_COLOR, LIGHT_COLOR } from '@/constants';
import { Icons } from '@/assets/images';

export default function Personal() {
  const user = useSelector((state) => state.user);
  const { t } = useTranslate();

  return (
    <Container>
      <ul>
        <ListItem>{user.email}</ListItem>
        <ListItem>
          <Link to={pagePaths.SETTINGS_CHANGE_PASSWORD}>
            <span>{t('settings.personalData.changePassword')}</span>
            <img src={Icons.detail} alt="" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to={pagePaths.SETTINGS_CONNECT_LIST}>
            <span>{t('settings.personalData.connectList')}</span>
            <img src={Icons.detail} alt="" />
          </Link>
        </ListItem>
        <ListItem>
          <Link to={pagePaths.SETTINGS_DELETE_ACCOUNT}>
            <span>{t('settings.personalData.deleteAccount')}</span>
            <img src={Icons.detail} alt="" />
          </Link>
        </ListItem>
      </ul>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 44px;

  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 18px;
  font-weight: 400;
`;

export const ListItem = styled.li`
  padding: 0 15px;
  border-bottom: 1px solid ${LIGHT_COLOR};
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
