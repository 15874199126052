import styled from 'styled-components';
import QrScanner from '@views/pages/token/components/QrScanner';
import { useLocation } from 'react-router-dom';

export default function ScanQRCode() {
  const { state } = useLocation();

  return (
    <Container>
      <QrScanner state={state} />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;
