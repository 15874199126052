import styled from 'styled-components';

export default function FormButtonWrap({ children }) {
  return <Container className="button_wrap">{children}</Container>;
}

const Container = styled.div`
  display: flex;
  margin: 0 -4px;
  margin-top: auto;
  padding-bottom: 30px;
`;
