import { gql } from '@apollo/client';

const CHECK_EMAIL_FOR_CREATE_USER = gql`
  mutation ($email: String!, $locale: LocaleStatus!) {
    checkEmailForCreateUser(email: $email, locale: $locale) {
      status
      message
    }
  }
`;

const MY_PERSONAL_INFO = gql`
  query {
    currentUser {
      email
      locale
      userCoinWallets {
        coin_id
        wallet_address
      }
    }
  }
`;

const REWARD_AVAIL_AMOUNT = gql`
  query getRewardAbleAmount {
    getRewardAbleAmount {
      amount
      monthly_amount
    }
  }
`;

const RECEIVE_REWARD = gql`
  mutation receiveAllReward {
    receiveAllReward {
      status
      message
    }
  }
`;

const LOGIN_USER = gql`
  mutation ($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      access_token
      refresh_token
      expires_in

      user {
        id
        email
        locale
        private_key_decryption_syntax
        pin
        userCoinWallets {
          wallet_address
        }
      }
    }
  }
`;

const CREATE_USER = gql`
  mutation (
    $email: String!
    $password: String!
    $password_confirmation: String!
    $locale: LocaleStatus!
  ) {
    createUser(
      input: {
        email: $email
        password: $password
        password_confirmation: $password_confirmation
        locale: $locale
      }
    ) {
      status
      message
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation refreshToken($refresh_token: String!) {
    refreshToken(refresh_token: $refresh_token) {
      access_token
      expires_in
      token_type
    }
  }
`;

const CREATE_PIN = gql`
  mutation createPin($pin: String!, $pin_confirmation: String!, $syntax: String) {
    createPin(
      input: {
        pin: $pin
        pin_confirmation: $pin_confirmation
        private_key_decryption_syntax: $syntax
      }
    ) {
      status
      message
      data {
        pin
      }
    }
  }
`;

const CHECK_PIN = gql`
  mutation checkPin($pin: String!) {
    checkPin(input: { pin: $pin }) {
      status
      message
    }
  }
`;

const CHANGE_PIN = gql`
  mutation changePin($old_pin: String!, $pin: String!, $pin_confirmation: String!) {
    changePin(input: { old_pin: $old_pin, pin: $pin, pin_confirmation: $pin_confirmation }) {
      status
      message
    }
  }
`;

const RESET_PIN = gql`
  mutation resetPin($pin: String!, $pin_confirmation: String!, $syntax: String!) {
    resetPin(
      input: {
        pin: $pin
        pin_confirmation: $pin_confirmation
        private_key_decryption_syntax: $syntax
      }
    ) {
      status
      message
    }
  }
`;

const FORGOT_PIN = gql`
  mutation {
    forgotPin {
      status
      message
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ($old_password: String!, $password: String!, $password_confirmation: String!) {
    changePassword(
      input: {
        old_password: $old_password
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`;

const CHECK_PASSWORD = gql`
  mutation ($password: String!) {
    checkPassword(password: $password) {
      status
      message
    }
  }
`;

const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      status
      message
    }
  }
`;

const UPDATE_FORGOTTEN_PASSWORD = gql`
  mutation ($email: String!, $token: String!, $password: String!, $password_confirmation: String!) {
    updateForgottenPassword(
      input: {
        email: $email
        token: $token
        password: $password
        password_confirmation: $password_confirmation
      }
    ) {
      status
      message
    }
  }
`;

const CREATE_PRIVATE_KEY_ENCRYPTION_SYNTAX = gql`
  mutation ($private_key: String!) {
    createPrivateKeyEncryptionSyntax(input: { private_key: $private_key }) {
      status
      message
      data {
        EthereumPrivateKey
        privateKeyDecryptionSyntax
      }
    }
  }
`;

const CHECK_PRIVATE_KEY_DECRYPTION_SYNTAX = gql`
  mutation ($syntax: String!) {
    checkPrivateKeyDecryptionSyntax(input: { private_key_decryption_syntax: $syntax }) {
      data {
        EthereumPrivateKey
      }
      status
      message
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation ($token: String!) {
    verifyEmail(input: { token: $token }) {
      status
      message
    }
  }
`;

const RESEND_VERIFY_EMAIL = gql`
  mutation ($email: String!) {
    resendVerifyEmail(input: { email: $email }) {
      status
      message
    }
  }
`;

const CHECK_WALLET = gql`
  query checkEthereumUserCoinWalletExists {
    checkEthereumUserCoinWalletExists {
      status
      message
    }
  }
`;

const REISSUE_WALLET = gql`
  mutation createEthereumUserCoinWallet($syntax: String!, $pin: String!) {
    createEthereumUserCoinWallet(input: { private_key_decryption_syntax: $syntax, pin: $pin }) {
      data {
        EthereumPrivateKey
        privateKeyDecryptionSyntax
      }
      status
      message
    }
  }
`;

const VERIFY_REISSUE_WALLET_EMAIL = gql`
  mutation {
    sendEthereumWalletGenerateVerifyEmail {
      status
      message
    }
  }
`;

const CHECK_REISSUE_WALLET_VERIFY = gql`
  mutation ($certification_number: String!) {
    checkEthereumWalletGenerateVerifyEmail(input: { certification_number: $certification_number }) {
      status
      message
    }
  }
`;

const DELETE_USER = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id) {
      status
      message
    }
  }
`;

export {
  CHECK_EMAIL_FOR_CREATE_USER,
  MY_PERSONAL_INFO,
  REWARD_AVAIL_AMOUNT,
  LOGIN_USER,
  CREATE_USER,
  REFRESH_TOKEN,
  CHECK_PIN,
  CHANGE_PIN,
  RESET_PIN,
  CREATE_PIN,
  FORGOT_PIN,
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  UPDATE_FORGOTTEN_PASSWORD,
  CREATE_PRIVATE_KEY_ENCRYPTION_SYNTAX,
  CHECK_PRIVATE_KEY_DECRYPTION_SYNTAX,
  VERIFY_EMAIL,
  RESEND_VERIFY_EMAIL,
  CHECK_WALLET,
  REISSUE_WALLET,
  VERIFY_REISSUE_WALLET_EMAIL,
  CHECK_REISSUE_WALLET_VERIFY,
  CHECK_PASSWORD,
  DELETE_USER,
  RECEIVE_REWARD,
};
