import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslate } from '@/hooks/useTranslate';
import FormatAmount from '@/utils/formatAmount';
import { G_COLOR, TEXT_GRAY } from '@/constants';

function RewardItem({ item }) {
  const { locale } = useSelector((state) => state.user);
  const { t } = useTranslate();

  const getStatus = (_item) => {
    if (_item.status === 'COMPLETED') {
      return t('swap.info.reward');
    }

    return '';
  };

  return (
    <ListItemContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        className="circle"
      >
        <circle cx="5.75391" cy="5.7041" r="5.5" fill={G_COLOR} />
      </svg>
      <ContentWrapper>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="type">
            {item.coinReward.coin[locale === 'KOREAN' ? 'name' : 'name_en']}
          </div>
          <div className="amount">
            + {FormatAmount(item.amount)} {item.coinReward.coin.unit.toUpperCase()}
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="date">
            {getStatus(item)}﹒{moment(item.created_at).format('MM.DD HH:mm')}
          </div>
        </div>
      </ContentWrapper>
    </ListItemContainer>
  );
}

export default RewardItem;

const ListItemContainer = styled('li')`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
  padding-left: 12px;

  &::before {
    content: '';
    border: 1px dashed ${G_COLOR};
    height: calc(100% + 20px);
    position: absolute;
    top: 50%;
    left: 17px;
  }

  &:last-child::before {
    display: none; /* 마지막 자식에 대해 선을 숨깁니다. */
  }

  &:last-child {
    margin-bottom: 20px;
  }

  .type {
    color: #000;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .amount {
    color: #000;
    text-align: right;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .date {
    color: ${TEXT_GRAY};
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .current-amount {
    color: ${TEXT_GRAY};
    text-align: right;
    font-family: 'Spoqa Han Sans Neo';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`;

// const showSwapDetail = (data) => {
//   openModal(modals.tokenSwapDetail, {
//     data,
//   });
// };
