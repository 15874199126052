import styled from 'styled-components';
import { Images } from '@assets/images';

export default function Spinner({ isLoading }) {
  return (
    <Container>
      {isLoading && (
        <div className="wrap">
          <img src={Images.loading} alt="" />
        </div>
      )}
    </Container>
  );
}

const Container = styled.div`
  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 99999999999;
    img {
      height: 135px;
    }
  }
`;
