import { onError } from '@apollo/client/link/error';
import axios from 'axios';

import { removeAccessToken, removeExpiredAt, removeTokenInfos } from './HandleToken';
import store from '@/redux/store';
import { resetUser } from '@/redux/reducer/userSlice';
import { resetSwapSlice } from '@/redux/reducer/swapSlice';
import { resetTrancSlice } from '@/redux/reducer/trancSlice';
import { resetWalletSlice } from '@/redux/reducer/walletSlice';
import { pagePaths } from '@/navigation/Pages';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors?.[0].message === 'This action is unauthorized.') {
    removeTokenInfos();

    store.dispatch(resetUser());
    store.dispatch(resetSwapSlice());
    store.dispatch(resetTrancSlice());
    store.dispatch(resetWalletSlice());
    window.location.href = pagePaths.WELCOME;
    // window.history.pushState({}, '', pagePaths.WELCOME); // 페이지 이동
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ debugMessage, message, locations, path }) =>
      console.info(
        `[GraphQL error]: DebugMessage: ${debugMessage}, Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      ),
    );
  }

  // if (networkError) {
  //   console.info(`[Network error]: ${networkError}`);
  //   // 네트워크 에러시 점검페이지 체크를 위한 리로드
  //   axios.get(process.env.REACT_APP_API_URL).catch((err) => {
  //     if (err.message === 'Network Error' || err.response?.status === 503) {
  //       window.location.reload();
  //     }
  //   });
  // }
});

export default errorLink;
