import {gql} from '@apollo/client';

const SET_LOCALE = gql`
mutation($locale: LocaleStatus!) {
  setLocale(input: { locale: $locale }) {
    status
    message
  }
}
`;

const CHANGE_LOCALE = gql`
mutation($locale: LocaleStatus!) {
  changeLocale(input: { locale: $locale }) {
    status
    message
  }
}
`;

export {
	SET_LOCALE,
	CHANGE_LOCALE,
};