import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import dompurify from 'dompurify';
import { NOTICE_BY_ID } from '@/graphql/Board';
import Spinner from '@/views/shared/spinner/Spinner';
import { pagePaths } from '@/navigation/Pages';
import { B_COLOR, LIGHT_COLOR, PRI_COLOR, TEXT_GRAY, W_COLOR } from '@/constants';
import { Images } from '@/assets/images';

export default function BoardDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname.split('/board/')[1];
  const [boardData, setBoardData] = useState(location.state);
  const [getBoard] = useLazyQuery(NOTICE_BY_ID, {
    variables: { id },
    onCompleted: ({ notice }) => {
      // id 에 해당하는 데이터 없는경우 공지사항 목록으로 이동
      if (notice) {
        setBoardData({
          createdAt: notice.createdAt,
          subject: notice.subject,
          content: notice.content,
          images: notice.images,
        });
      } else {
        navigate(pagePaths.BOARD);
      }
    },
    skip: location.state,
    // 외부링크통한 유입시 매번 새로운 요청으로 작업
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (location.state) {
      setBoardData(location.state);
    } else {
      getBoard();
    }
  }, [getBoard, location.state]);

  if (!boardData) return <Spinner isLoading />;

  return (
    <Container>
      <Header>
        <img src={boardData.thumbnail?.url || Images.sampleImg1} alt="" />
        <HeaderContent>
          <div className="desc">
            <span className="type">{boardData.type}</span>
            <div className="date">{moment(boardData.createdAt).format('YYYY-MM-DD')}</div>
          </div>
          <div className="title">{boardData.subject}</div>
        </HeaderContent>
      </Header>
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: dompurify.sanitize(boardData.content) }}
      />
      {/* 이미지가 content 내의 img 태그통해서 화면에 보여주는 걸로 대체 기존 이미지파일 자체는 섬네일을 위해서 데이터에 담김(추후 관리자 수정이후 삭제예정) */}
      {/* {boardData.images.length > 0 &&
        boardData.images.map((image) => <img src={image.url} alt="" key={image.id} />)} */}
    </Container>
  );
}

const Container = styled.div`
  .content {
    padding: 30px 15px;
  }
`;

const Header = styled.div`
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
`;

const HeaderContent = styled.div`
  margin: 24px 15px 0;

  .title {
    color: ${B_COLOR};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; /* 145.833% */
  }

  .desc {
    display: flex;
    align-items: center;

    .type {
      color: ${PRI_COLOR};
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: 400;
      margin-right: 6px;
    }

    .date {
      color: ${TEXT_GRAY};
      font-family: 'Spoqa Han Sans Neo';
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
