import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { modals } from '@views/shared/modal/Modals';
import FormButton from '@views/shared/forms/FormButton';
import PageInfo from '@views/shared/pageInfo';
import Spinner from '@/views/shared/spinner/Spinner';
import { CHECK_PRIVATE_KEY_DECRYPTION_SYNTAX } from '@/graphql/Users';
import useModals from '@/hooks/useModal';
import { pagePaths } from '@/navigation/Pages';
import { useTranslate } from '@/hooks/useTranslate';
import MnemonicFiled from '@/views/shared/forms/MnemonicFiled';
import { Icons } from '@/assets/images';
import { RED_COLOR } from '@/constants';

export default function RestoreEncryptSyntax() {
  const [privateKey, setPrivateKey] = useState('');
  const backupCode = useRef();
  const { openModal } = useModals();
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [checkSyntax, { loading, error }] = useMutation(CHECK_PRIVATE_KEY_DECRYPTION_SYNTAX, {
    onCompleted: (data) => {
      const key = data.checkPrivateKeyDecryptionSyntax.data.EthereumPrivateKey;
      if (key) {
        setPrivateKey(key);
      } else {
        openModal(modals.dialog, {
          title: t('settings.security.findPrivateKey.decryptionFailed'),
          message: data.checkPrivateKeyDecryptionSyntax.message,
        });
      }
    },
  });

  const onSubmit = (values) => {
    const inputs = document.querySelectorAll('.recover');
    if ([...inputs].some((input) => !input.value.trim())) {
      openModal(modals.dialog, {
        title: t('common.info'),
        message: t('settings.security.findPrivateKey.decryptionFailed'),
      });
      return;
    }
    const syntaxArr = Object.values(values).join(' ');
    checkSyntax({
      variables: {
        syntax: syntaxArr,
      },
    });
  };

  const copyCode = () => {
    const code = backupCode.current.value;
    const textarea = document.createElement('textarea');
    textarea.value = code;
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.position = 'fixed';

    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    openModal(modals.dialog, {
      title: t('common.info'),
      message: t('common.copyClipboard'),
    });
  };

  const handleConfirm = () => {
    navigate(-1);
  };

  const infoTexts = {
    title: t('settings.security.findPrivateKey.title'),
    subTxt: t('settings.security.findPrivateKey.subText'),
    subTxtComplete: t('settings.security.findPrivateKey.subTextComplete'),
  };

  if (loading) return <Spinner isLoading={loading} />;
  if (error) {
    navigate(pagePaths.ERROR, { state: { error: error.message } });
    return null;
  }

  return (
    <Container>
      {!privateKey ? (
        <>
          <PageInfo title={infoTexts.title} subTxt={infoTexts.subTxt} />
          <MnemonicFiled onSubmit={onSubmit} />
        </>
      ) : (
        <>
          <PageInfo title="개인 키 확인" subTxt={infoTexts.subTxtComplete} />
          <div className="content">
            <Warning>
              <img src={Icons.warning} alt="" />
              <div>{t('settings.security.keyWarning')}</div>
            </Warning>
            <textarea ref={backupCode} readOnly value={privateKey} />
            <BtnWrapper>
              <FormButton
                onClick={handleConfirm}
                text={t('common.confirm')}
                color="black"
                flex={1}
              />
              <img src={Icons.copyBtn} alt="" onClick={copyCode} />
            </BtnWrapper>
          </div>
        </>
      )}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 45px 15px;

  .content {
    padding: 0 35px;
    margin-top: 28px;
  }

  textarea {
    border-radius: 15px;
    background: #fff;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.08);

    margin-top: 50px;
    margin-bottom: 30px;
  }
`;

const Warning = styled.div`
  display: flex;
  gap: 8px;
  position: relative;

  color: ${RED_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  img {
    position: absolute;
    top: -2px;
  }

  div {
    margin-left: 42px;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 12px;
`;
