import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { defaults, resolvers } from '@services/HandleToken';
import httpLink from './httpLink';
import errorLink from './errorLink';
import authLink from './authLink';

const client = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: 'include',
  clientState: {
    defaults,
    resolvers,
  },
});

export default client;
