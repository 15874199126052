import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import Banner from '@views/shared/banner/Banner';
import { COINS_LIST_ALL } from '@/graphql/Swap';
import { pagePaths } from '@/navigation/Pages';
import formatAmount from '@/utils/formatAmount';
import swapSlice, { setDefaultCoinInfo } from '@/redux/reducer/swapSlice';
import { B_COLOR } from '@/constants';
import PointFilter from './components/PointFilter';
import { Icons } from '@/assets/images';
import { useTranslate } from '@/hooks/useTranslate';
import { GET_DEFAULT_COIN_INFO } from '@/graphql/Coins';

export default function SwapPoint() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const swap = useSelector((state) => state.swap);
  const { t } = useTranslate();

  useQuery(GET_DEFAULT_COIN_INFO, {
    onCompleted: ({ getBalanceByDefaultCoin }) => {
      dispatch(setDefaultCoinInfo(getBalanceByDefaultCoin));
    },
  });

  useQuery(COINS_LIST_ALL, {
    onCompleted: (data) => {
      dispatch(swapSlice.actions.whenPageInit(data));
    },
    onError: (error) => {
      navigate(pagePaths.ERROR, { state: { error: error.message } });
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container>
      <MyPoint>
        <img src={swap.TMOP_INFO?.coin?.image?.url || swap.default_img} alt="" />
        <div>
          <div className="tit">{swap.TMOP_INFO?.coin?.name_en}</div>
          <div className="point">
            {formatAmount(swap.TMOP ?? '0')}
            {swap.TMOP_INFO?.coin?.unit ?? 'P'}
          </div>
        </div>
        <HistoryBtn
          onClick={() => {
            navigate(pagePaths.SWAP_HISTORY);
          }}
        >
          <div className="row">
            <img src={Icons.calendar} alt="" />
            <div>{t('swap.tab.history')}</div>
          </div>
        </HistoryBtn>
      </MyPoint>

      <PointFilter />
      <div className="banner-wrapper">
        <Banner />
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 40px 15px;
  overflow-y: auto;

  & .banner-wrapper {
    margin-top: 20px;
  }
`;

const MyPoint = styled.div`
  display: flex;
  gap: 0.7em;
  margin-bottom: 16px;

  & img {
    width: 50px;
    height: 50px;
  }

  & .tit {
    color: ${B_COLOR};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Spoqa Han Sans Neo;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    margin-bottom: 2px;
  }

  & .point {
    color: ${B_COLOR};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Spoqa Han Sans Neo;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const HistoryBtn = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  color: ${B_COLOR};
  font-family: 'Spoqa Han Sans Neo';
  font-size: 13px;
  font-weight: 400;

  .row {
    display: flex;
    align-items: center;
    gap: 4px;

    img {
      width: 14px;
      height: 14px;
    }
  }
`;
